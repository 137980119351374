import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Divider,
    Popover
} from 'antd';

import {EditOutlined} from '@ant-design/icons'; // Import the edit icon
import Icon from "@ant-design/icons";
import axiosInstance from "services/axios";
import ChartToolModal from "../ChartTool/ChartToolModal";
import ReactJson from "react-json-view";
import {FaBullseye, FaCarCrash, FaCog, FaFile, FaList, FaSearch} from "react-icons/fa";
import HistoryModal from "../DetailsCard/HistoryModal";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import TagValues from "../TagValues/TagValues";
import DescriptionEdit from "./DescriptionEdit";
import DescriptionSelectEdit from "./DescriptionSelectEdit";
import MatchingModal from "./MatchingModal";
import IncidentModal from "./IncidentModal";
import InspectionModal from "./InspectionModal";
// import EmailButton from "./EmailButton";

const {Title} = Typography;
const {Text} = Typography;


const AnomalyFeedbackCard = (props) => {

    const UAID = props.UAID
    const [data, setData] = useState(null);
    const [historyModalOpen, setHistoryModalOpen] = useState(false);
    const [historyOpenCount, setHistoryOpenCount] = useState(0)
    const [matchingModalOpen, setMatchingModalOpen] = useState(false);
    const [matchingOpenCount, setMatchingOpenCount] = useState(0)
    const [incidentModalOpen, setIncidentModalOpen] = useState(false);
    const [incidentOpenCount, setIncidentOpenCount] = useState(0)
    const [inspectionModalOpen, setInspectionModalOpen] = useState(false);
    const [inspectionOpenCount, setInspectionOpenCount] = useState(0)

    const getAnomalyData = () => {
        axiosInstance.post(
            '/api/anomaly_tool/feedback',
            {
                UAID: UAID,
            })
            .then(res => {
                props.setAddAnomaly(!res.data.exists_matching_tool)
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getAnomalyData();
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    // const importUAID = (extra) => {
    //     let payload = {}
    //     if (extra) {
    //         payload = {
    //             'uaid': props.UAID,
    //             'extra': extra
    //         }
    //     } else {
    //         payload = {
    //             'uaid': props.UAID
    //         }
    //     }
    //
    //     axiosInstance.post('/api/matching_tool/uaid', payload)
    //         .then(response => {
    //             notification['success']({
    //                     message: 'Importing ' + props.UAID,
    //                     duration: 2.5,
    //                     maxCount: 15
    //                 }
    //             );
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             notification['error']({message: 'Error importing ' + props.UAID, description: error.message});
    //         })
    // }


    const showHistoryModal = () => {
        setHistoryOpenCount(historyOpenCount + 1)
        setHistoryModalOpen(true);
    }

    const showMatchingModal = () => {
        setMatchingOpenCount(matchingOpenCount + 1)
        setMatchingModalOpen(true);
    }

    const showIncidentModal = () => {
        setIncidentOpenCount(incidentOpenCount + 1)
        setIncidentModalOpen(true);
    }

    const showInspectionModal = () => {
        setInspectionOpenCount(inspectionOpenCount + 1)
        setInspectionModalOpen(true);
    }

    const backgroundColor = data?.exists_matching_tool ? 'rgba(0, 128, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)';

    const handleConfirm = (key) => {

        const payload = {
            UAID: UAID,
            field: key,
        }

        axiosInstance.post('/api/anomaly_tool/feedback/confirm', payload)
            .then(res => {
                const confirmKey = key + '_confirmed'
                const confirmKeyDate = key + '_confirmed_date'
                setData({
                    ...data,
                    [confirmKey]: true,
                    [confirmKeyDate]: new Date().toJSON()
                });
                notification['success']({
                        message: 'Confirmed ' + key,
                        duration: 1.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error confirming for ' + props.UAID,
                    description: error.message
                });
            })
    };

    const handleEditSave = (key, editedData) => {

        const payload = {
            UAID: UAID,
            field: key,
            value: editedData
        }

        axiosInstance.post('/api/anomaly_tool/feedback/edit', payload)
            .then(res => {
                const confirmKey = key + '_confirmed'
                setData({
                    ...data,
                    [key]: editedData,
                    [confirmKey]: false,
                    // Update other data properties as needed
                });
                notification['success']({
                        message: 'Updated content for ' + key,
                        duration: 2.5,
                        maxCount: 15
                    }
                );
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error updating content for ' + props.UAID,
                    description: error.message
                });
            })
    };

    return (
        <>
            <HistoryModal
                showModal={() => setHistoryModalOpen(true)}
                hideModal={() => setHistoryModalOpen(false)}
                open={historyModalOpen}
                id={UAID}
                count={historyOpenCount}
            />
            <MatchingModal
                showModal={() => setMatchingModalOpen(true)}
                hideModal={() => setMatchingModalOpen(false)}
                open={matchingModalOpen}
                id={UAID}
                data={data}
                count={matchingOpenCount}
                handleEditSave={handleEditSave}
                setReload={props.setReload}
            />
            <IncidentModal
                showModal={() => setIncidentModalOpen(true)}
                hideModal={() => setIncidentModalOpen(false)}
                open={incidentModalOpen}
                id={UAID}
                data={data}
                count={incidentOpenCount}
                setReload={props.setReload}
            />
            <InspectionModal
                customer={props.customer}
                showModal={() => setInspectionModalOpen(true)}
                hideModal={() => setInspectionModalOpen(false)}
                open={inspectionModalOpen}
                id={UAID}
                data={data}
                count={inspectionOpenCount}
                setReload={props.setReload}
            />
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={'Feedback'}
                bodyStyle={{padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}>
                {/*<Row style={{marginTop: 8}}>*/}
                {/*    <Text style={{*/}
                {/*        marginTop: '4px',*/}
                {/*        marginLeft: '8px',*/}
                {/*        padding: '2px 8px',*/}
                {/*        backgroundColor: backgroundColor*/}
                {/*    }}>*/}
                {/*        in Matching Tool*/}
                {/*    </Text>*/}
                {/*</Row>*/}
                <Row style={{}}>
                    {(data?.exists_matching_tool === true) && (
                        <Button onClick={showHistoryModal} icon={<FaList/>}>
                            {'\xa0'} History
                        </Button>
                    )}
                    {(data?.exists_matching_tool === false) && (
                        // <Button onClick={() => {
                        //     importUAID()
                        // }} style={{backgroundColor: backgroundColor}}>
                        //     Import to Tool
                        // </Button>
                        <Text style={{marginLeft: 8, marginRight: 8}}>
                            Load UAID to event tool to add feedback
                        </Text>
                    )}
                    {(data?.exists_matching_tool === true) && (
                        <>
                            <Button onClick={showMatchingModal} icon={<FaBullseye/>}>
                                {'\xa0'}Match Event
                            </Button>
                            {props.customer === 'mobility' && data?.anomaly_type === 'proactive' &&
                                <Button onClick={showIncidentModal} icon={<FaCarCrash/>}>
                                    {'\xa0'}Match Incident
                                </Button>
                            }
                            {data?.anomaly_type === 'proactive' &&
                                <Button onClick={showInspectionModal} icon={<FaSearch/>}>
                                    {'\xa0'}Match Inspection
                                </Button>
                            }
                        </>
                    )}
                    {/*{data?.anomaly_type === 'matched' &&*/}
                    {/*    <EmailButton eventData={props.anomalyInfo}/>*/}
                    {/*}*/}
                </Row>
                {/*<Divider style={{marginTop: '8px', marginBottom: '0px'}}></Divider>*/}
                {(data?.exists_matching_tool === true) &&
                    <div>
                        <Row style={{marginTop: 8}}>
                            <Descriptions bordered size={'middle'}>
                                <Descriptions.Item label="Situation Tags">
                                    <TagRenderer
                                        columnValues={TagValues.distinctSituationValues}
                                        value={data?.situation_tags} // Pass the current tag as a prop
                                        setValue={data?.situation_tags} // Pass a function to update the current tag
                                        onSave={handleEditSave}
                                        onSaveActive={true}
                                        field={'situation_tag'}
                                        UAID={UAID}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <Row>
                            <Descriptions bordered size={'middle'}>
                                <Descriptions.Item label="Damage Tags">
                                    {/*{data?.damage_tags}*/}
                                    <TagRenderer
                                        columnValues={TagValues.distinctDamageValues}
                                        value={data?.damage_tags} // Pass the current tag as a prop
                                        setValue={data?.damage_tags} // Pass a function to update the current tag
                                        onSave={handleEditSave}
                                        onSaveActive={true}
                                        field={'damage_tag'}
                                        UAID={UAID}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <Row>
                            <Descriptions bordered size={'middle'}>
                                <Descriptions.Item label="Pattern Tags">
                                    <TagRenderer
                                        columnValues={TagValues.distinctPatternValues}
                                        value={data?.pattern_tags} // Pass the current tag as a prop
                                        setValue={data?.pattern_tags} // Pass a function to update the current tag
                                        onSave={handleEditSave}
                                        onSaveActive={true}
                                        field={'pattern'}
                                        UAID={UAID}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                        <DescriptionEdit
                            onSave={handleEditSave}
                            field={"comment"}
                            label={"Comment"}
                            data={data?.comment}
                        />
                        <DescriptionSelectEdit
                            width={140}
                            onSave={handleEditSave}
                            field={"matching_confidence"}
                            label={"Matching Confidence"}
                            data={data?.matching_confidence}
                            showConfirm={true}
                            confirm={data?.matching_confidence_confirmed}
                            confirmDate={data?.matching_confidence_confirmed_date}
                            onConfirm={handleConfirm}
                            fixedValues={[
                                {value: 4, label: ": sure TP"},
                                {value: 3, label: ": plausible TP"},
                                {value: 2, label: ": possible TP"},
                                {value: 1, label: ": unlikely TP"},  // Add your new value here
                                {value: 0, label: ": unsure"},
                                {value: -1, label: ": unlikely FP"},
                                {value: -2, label: ": possible FP"},
                                {value: -3, label: ": plausible FP"},
                                {value: -4, label: ": sure FP"}
                            ]}
                        />
                        <DescriptionSelectEdit
                            width={110}
                            onSave={handleEditSave}
                            field={"event_severity"}
                            label={"Event Severity"}
                            data={data?.event_severity}
                            fixedValues={[
                                {value: 'impact_without_damage', label: ""},
                                {value: 'low', label: ""},
                                {value: 'medium', label: ""},
                                {value: 'high', label: ""}]}
                        />
                    </div>
                }
            </Card>
        </>
    );
}

export default AnomalyFeedbackCard;
