import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Form,
    Input,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Tag, Checkbox, DatePicker
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled,
    PictureOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {FaFile, FaInfo} from "react-icons/fa";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import axiosInstance from "../../services/axios";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const InspectionInfo = (props) => {
    const inspectionData = props.inspectionData
    const labelTarget = props.labelTarget

    const onFinish = (values) => {
        console.log('Sending request with:', values);
        notification['info']({message: 'Reactive event is being created ...'});
        axiosInstance.post('/api/image_tool/create_event', values)
            .then(res => {
                console.log('Success:', res);
                notification['success']({
                    message: 'Reactive event was created successfully',
                    duration: 10,
                    maxCount: 1
                })
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                paddingBottom: 10,
                minHeight: 370,
                maxHeight: 370
            }}
            styles={{
                body: {
                    padding: '16px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
            title={'Inspection'}
        >
            {inspectionData &&
                <div style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minHeight: 100,
                    maxHeight: '286px',
                }}>
                    <Row>
                        <Alert
                            message={'Reference - ' + (labelTarget ? (
                                labelTarget.length > 36 ?
                                    labelTarget.substring(0, 36) + '...' :
                                    labelTarget) : 'None')
                            }
                            type="info"
                            style={{height: 25}}/>
                    </Row>
                    <Row>
                        <Popover
                            title={'Create Reactive Event'}
                            trigger="click"
                            placement="right"
                            content={
                                // <Card style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <Form
                                    name="basic"
                                    labelCol={{span: 5}}
                                    wrapperCol={{span: 48}}
                                    // style={{ width: 600, height: 500}}
                                    style={{width: 600}}
                                    initialValues={{remember: true}}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Date"
                                        name="observed_form"
                                        // initialValue={dateObj.toISOString()}
                                        initialValue={inspectionData?.inspection_datetime}
                                        style={{marginBottom: "4px"}}
                                    >
                                        <Input
                                            disabled={true}
                                        />
                                    </Form.Item>
                                    {/*<Form.Item*/}
                                    {/*    label="Type"*/}
                                    {/*    name="observation_type"*/}
                                    {/*    initialValue={props.data.gemeldetVon}*/}
                                    {/*    style={{marginBottom: "4px"}}*/}
                                    {/*>*/}
                                    {/*    <Radio.Group>*/}
                                    {/*        <Radio.Button value="Arbeitsgruppe">Arbeitsgruppe</Radio.Button>*/}
                                    {/*        <Radio.Button value="Nachfolger">Nachfolger</Radio.Button>*/}
                                    {/*        <Radio.Button value="Sensorbox">Sensorbox</Radio.Button>*/}
                                    {/*        <Radio.Button value="Verursacher">Verursacher</Radio.Button>*/}
                                    {/*    </Radio.Group>*/}
                                    {/*</Form.Item>*/}
                                    {/*<Form.Item*/}
                                    {/*    label="Inspectio Result"*/}
                                    {/*    name="inspection_result"*/}
                                    {/*    initialValue={inspectionData?.inspection_result}*/}
                                    {/*    style={{marginBottom: "4px"}}*/}
                                    {/*>*/}
                                    {/*    <Input.TextArea rows={2}/>*/}
                                    {/*</Form.Item>*/}
                                    <Form.Item
                                        label="Last No Damage"
                                        name="last_no_damage"
                                        style={{marginBottom: "4px"}}
                                    >
                                        <DatePicker
                                            showTime={true}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Details"
                                        name="inspection_result"
                                        initialValue={inspectionData?.inspection_result}
                                        style={{marginBottom: "4px"}}
                                    >
                                        <Input.TextArea rows={2}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Comment"
                                        name="event_info_text"
                                        initialValue={''}
                                        style={{marginBottom: "4px"}}
                                    >
                                        <Input.TextArea rows={3}/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Location"
                                        name="event_location"
                                        initialValue={''}
                                        style={{marginBottom: "4px"}}
                                    >
                                        <Input.TextArea rows={1}/>
                                    </Form.Item>
                                    <Divider style={{marginBottom: "16px", marginTop: "16px"}}/>
                                    <Form.Item label="Ticket" style={{marginBottom: "4px"}}>
                                        <Form.Item
                                            name="ticket_form"
                                            valuePropName="checked"
                                            initialValue={false}
                                            style={{marginBottom: "4px", display: 'inline-block'}}
                                        >
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                        <Form.Item
                                            label="Priority"
                                            name="ticket_priority"
                                            valuePropName="checked"
                                            initialValue={'Medium'}
                                            style={{marginLeft: "10px", marginBottom: "4px", display: 'inline-block'}}
                                        >
                                            <Select
                                                defaultValue={'Medium'}
                                                optionFilterProp="children"
                                                options={[
                                                    {
                                                        label: 'High',
                                                        value: 'High',
                                                    },
                                                    {
                                                        label: 'Medium',
                                                        value: 'Medium',
                                                    },
                                                    {
                                                        label: 'Low',
                                                        value: 'Low',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Form.Item>
                                    {props.alertData.length > 0 &&
                                        <Form.Item
                                            name="alert"
                                            valuePropName="checked"
                                            rules={[{
                                                required: true,
                                                message: 'Please confirm to create event'
                                            }]}
                                            initialValue={null}
                                        >
                                            <Text style={{color: 'red', marginLeft: "50px"}}>
                                                Create event even though possible matching proactive alert exists
                                            </Text>
                                        </Form.Item>
                                    }
                                    <Divider style={{marginBottom: "4px", marginTop: "4px"}}/>
                                    <Form.Item wrapperCol={{offset: 8, span: 16}}
                                               style={{marginBottom: "4px", marginTop: "4px"}}>
                                        <Button
                                            style={{marginBottom: "4px", marginTop: "16px"}}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Create event in carvaloo Webapp
                                        </Button>
                                    </Form.Item>
                                </Form>
                                // </Card>
                            }
                        >
                            <Button style={{marginTop: 8}}>
                                Create Reactive Event
                            </Button>
                        </Popover>
                        {props.alertData.length > 0 &&
                            <Popover
                                title={'Proactive Alerts (last 30 days)'}
                                trigger="click"
                                placement="right"
                                content={
                                    <div>
                                        {props.alertData.map(({UAID, alert_datetime}) => (
                                            <div key={UAID}>
                                                <span>{alert_datetime.slice(0, 10)} -</span>{' '}
                                                <a href={`https://matching.carvaloo.com/anomaly_tool/${UAID}`}
                                                   target="_blank" rel="noopener noreferrer">
                                                    {UAID}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <Tag color={'red'} style={{marginLeft: 8, width: 40, marginTop: 8, paddingTop: 4}}>
                                    <WarningOutlined/> {props.alertData.length}
                                </Tag>
                            </Popover>
                        }
                    </Row>
                    {/*<Row style={{marginTop: 16}}>*/}
                    {/*    {inspectionData.inspection_datetime?.replace('T', ' - ').slice(0, 21)}*/}
                    {/*</Row>*/}
                    {/*<Row style={{marginTop: 20}}>*/}
                    {/*    <Text style={{fontWeight: 500, marginLeft: 0}}>*/}
                    {/*        UUID: {inspectionData.inspection_uuid}*/}
                    {/*    </Text>*/}
                    {/*</Row>*/}
                    <Divider style={{marginTop: 16, marginBottom: 16}}></Divider>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Reservation ID: {inspectionData.reservation_id || 'Not available'}
                        </Text>
                    </Row>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Car Part: {inspectionData.carpart}
                        </Text>
                    </Row>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Damage: {inspectionData.damage_type}
                        </Text>
                    </Row>
                    <Row style={{paddingRight: 24, marginTop: 4}}>
                        {inspectionData?.inspection_result?.length > 150 ?
                            <Tooltip
                                title={inspectionData?.inspection_result}
                                mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                mouseLeaveDelay={0}    // Hide immediately>
                            >
                                <Text>
                                    {'Comment: ' + (inspectionData?.inspection_result ? (
                                        inspectionData?.inspection_result.length > 150 ?
                                            inspectionData?.inspection_result.substring(0, 150) + '...' :
                                            inspectionData?.inspection_result) : 'None')}
                                </Text>
                            </Tooltip>
                            :
                            <Text>
                                {'Comment: ' + inspectionData?.inspection_result}
                            </Text>
                        }
                    </Row>
                    <Row style={{paddingLeft: 100, paddingRight: 100, marginTop: 16}}>
                        <CarPartRenderer value={inspectionData?.carPart_id}/>
                    </Row>
                </div>
            }
            {!inspectionData === 0 &&
                <Row style={{
                    marginTop: 100,
                    marginBottom: 196,
                    marginLeft: 150
                }}>
                    No data available
                </Row>
            }
        </Card>
    )
};

export default InspectionInfo;