export const carPartArray = [
    "antenna",
    "bonnet",
    "exhaust",
    "front_bumper",
    "front_bumper_left_arch_moulding",
    "front_bumper_left_moulding",
    "front_bumper_moulding",
    "front_bumper_right_arch_moulding",
    "front_bumper_right_moulding",
    "front_emblem",
    "front_left_door",
    "front_left_door_arch_moulding",
    "front_left_door_handle",
    "front_left_door_moulding",
    "front_left_fog_light",
    "front_left_fuel_flap",
    "front_left_light_cap",
    "front_left_rim",
    "front_left_towing_cap",
    "front_left_tyre",
    "front_left_window",
    "front_left_wing",
    "front_left_wing_arch_moulding",
    "front_mirror",
    "front_plate",
    "front_right_door",
    "front_right_door_arch_moulding",
    "front_right_door_handle",
    "front_right_door_moulding",
    "front_right_fog_light",
    "front_right_fuel_flap",
    "front_right_light_cap",
    "front_right_rim",
    "front_right_towing_cap",
    "front_right_tyre",
    "front_right_window",
    "front_right_wing",
    "front_right_wing_arch_moulding",
    "front_sensor",
    "front_spoiler",
    "front_spoiler_moulding",
    "grille",
    "headlamp_left_washer_cap",
    "headlamp_right_washer_cap",
    "inferior_grille",
    "left_exhaust",
    "left_headlamp",
    "left_inferior_grille",
    "left_mirror",
    "left_sill_panel",
    "lower_center_front_bumper",
    "lower_center_rear_bumper",
    "pillar",
    "rear_bumper",
    "rear_bumper_left_arch_moulding",
    "rear_bumper_left_moulding",
    "rear_bumper_moulding",
    "rear_bumper_right_arch_moulding",
    "rear_bumper_right_moulding",
    "rear_emblem",
    "rear_fog_light",
    "rear_left_door",
    "rear_left_door_arch_moulding",
    "rear_left_door_handle",
    "rear_left_door_moulding",
    "rear_left_fog_light",
    "rear_left_fuel_flap",
    "rear_left_light_cap",
    "rear_left_rim",
    "rear_left_towing_cap",
    "rear_left_tyre",
    "rear_left_wing",
    "rear_left_wing_arch_moulding",
    "rear_left_window",
    "rear_mirror",
    "rear_plate",
    "rear_right_door",
    "rear_right_door_arch_moulding",
    "rear_right_door_handle",
    "rear_right_door_moulding",
    "rear_right_fog_light",
    "rear_right_fuel_flap",
    "rear_right_light_cap",
    "rear_right_rim",
    "rear_right_towing_cap",
    "rear_right_tyre",
    "rear_right_wing",
    "rear_right_wing_arch_moulding",
    "rear_right_window",
    "rear_screen",
    "rear_spoiler",
    "rear_spoiler_moulding",
    "right_exhaust",
    "right_headlamp",
    "right_inferior_grille",
    "right_mirror",
    "right_sill_panel",
    "roof",
    "trunk",
    "upper_center_front_bumper",
    "upper_center_rear_bumper",
    "upper_left_front_bumper",
    "upper_left_rear_bumper",
    "upper_right_front_bumper",
    "upper_right_rear_bumper",
    "windscreen",
    "front_left_underbody",
    "front_right_underbody",
    "center_left_underbody",
    "center_right_underbody",
    "rear_left_underbody",
    "rear_right_underbody",
    "left_tail_lamp",
    "right_tail_lamp"
];