import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid'; // Import uuid for generating unique IDs
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal
} from 'antd';
import axiosInstance from "../../services/axios";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import TagValues from "../TagValues/TagValues";
import CommentTags from "./CommentTags";
import {EditFilled} from "@ant-design/icons";
import {useAuth} from "react-oidc-context";
import {carPartArray} from "../../utility/car-parts-bdeo"
import {BDEO_TO_CP} from "../../utility/car-parts-bdeo-cv"
import {CP_TO_TYPOLOGY} from "../../utility/car-parts-typlogy"
import GroupImages from "./GroupImages";
import {FaImage} from "react-icons/fa";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const CarPartLabels = (props) => {

    const [labelsExist, setLabelsExits] = useState(false)
    const [damages, setDamages] = useState(null);
    const [groupIds, setGroupIds] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [newDamage, setNewDamage] = useState({
        doc_id: null,
        carPart_id: null,
        typology: null,
    });
    const [carPart, setCarPart] = useState(null);
    const [labelData, setLabelData] = useState({
        doc_id: null,
        car_part: null,
        severity: null,
        typology: null,
        image_quality: null,
        assignable: null,
        state: null,
        surface: null,
        new_damage: null,
        feedback_uuid: null,
        comment: null,
        group_uuid: null
    });
    const auth = useAuth();

    useEffect(() => {
        if (props.data) {

            if (carPart) {
                const carPartExists = props.data.some(item => item.carPart_id === carPart.carPart_id);
                if (!carPartExists) {
                    setCarPart(null)
                }
            }
            setDamages(props.data)

        }
    }, [props.data]);

    useEffect(() => {
        if (props.data && props.groupData) {

            // Extract group_uuid values from props.data
            const dataGroupIds = [...new Set(props.data?.map((damage) => damage.group_uuid).filter(Boolean))];
            const groupDataIds = [...new Set(props.groupData?.map((group) => group.group_uuid).filter(Boolean))];
            const combinedGroupIds = [
                ...dataGroupIds, // Add group_ids from props.data first
                ...groupDataIds.filter((id) => !dataGroupIds.includes(id)), // Add group_ids from props.groupData, excluding duplicates
            ];
            setGroupIds(combinedGroupIds);

        }
    }, [props.data, props.groupData]);

    useEffect(() => {
        if (carPart && props.labelData && Array.isArray(props.labelData)) {
            const matchedLabel = props.labelData.find(
                label => label.car_part === carPart.carPart_id
                    && label.typology === carPart.typology
                    && label.feedback_uuid === carPart.feedback_uuid
                    && label.doc_id.toString() === carPart.doc_id
            );

            if (matchedLabel) {
                setLabelsExits(true);
                setLabelData({
                    id: matchedLabel.id,
                    doc_id: matchedLabel.doc_id,
                    car_part: matchedLabel.car_part,
                    severity: matchedLabel.severity,
                    typology: matchedLabel.typology,
                    image_quality: matchedLabel.image_quality,
                    assignable: matchedLabel.assignable,
                    state: matchedLabel.state,
                    surface: matchedLabel.surface,
                    new_damage: matchedLabel.new_damage,
                    visible: matchedLabel.visible,
                    matching: matchedLabel.matching,
                    comment: matchedLabel.comment,
                    feedback_uuid: matchedLabel?.feedback_uuid,
                    position: matchedLabel?.position,
                    group_uuid: matchedLabel?.group_uuid
                });
            } else {

                setLabelsExits(false)

                if (carPart) {
                    // console.log(carPart)
                    setLabelData(prevState => ({
                        ...prevState,
                        doc_id: carPart.doc_id,
                        car_part: carPart.carPart_id,
                        severity: carPart?.severity,
                        typology: carPart?.typology,
                        surface: carPart?.surface,
                        new_damage: carPart?.new_damage,
                        image_quality: null,
                        assignable: true,
                        visible: true,
                        state: carPart?.state,
                        comment: [],
                        feedback_uuid: carPart?.userfeedback_uuid,
                        matching: null,
                        position: [],
                        group_uuid: carPart?.group_uuid
                    }));
                }
            }
        }
    }, [props.labelData, carPart, props.labelTarget]);

    const docIDOptions = props.imageData?.map(str => ({
        value: str,    // Assign the string to the value property
        label: str     // Assign the string to the label property
    }));

    const zoomDropdown = [
        {
            'value': 'both',
            'label': 'both',
        },
        {
            'value': 'distance',
            'label': 'distance',
        },
        {
            'value': 'close-up',
            'label': 'close-up',
        }
    ]

    const severityDropdown = [
        {
            'value': 'superficial',
            'label': 'superficial',
        },
        {
            'value': 'low',
            'label': 'low',
        },
        {
            'value': 'mid',
            'label': 'mid',
        },
        {
            'value': 'high',
            'label': 'high',
        }
    ]

    const typologyDropdown = [
        {
            'value': 'break_absence',
            'label': 'break_absence',
        },
        {
            'value': 'blowout',
            'label': 'blowout',
        },
        {
            'value': 'crack',
            'label': 'crack',
        },
        {
            'value': 'cutting_tear',
            'label': 'cutting_tear',
        },
        {
            'value': 'detached',
            'label': 'detached',
        },
        {
            'value': 'deformation',
            'label': 'deformation',
        },
        {
            'value': 'hail',
            'label': 'hail',
        },
        {
            'value': 'puncture',
            'label': 'puncture',
        },
        {
            'value': 'scratch',
            'label': 'scratch',
        },
    ]

    const qualityDropdown = [
        {
            'value': 'poor',
            'label': 'poor',
        },
        {
            'value': 'ok',
            'label': 'ok',
        },
        {
            'value': 'ideal',
            'label': 'ideal',
        }
    ]

    const stateDropdown = [
        {
            'value': 'tp',
            'label': 'TP',
        },
        {
            'value': 'fp',
            'label': 'FP',
        },
        {
            'value': 'fn',
            'label': 'FN',
        }
    ]

    const carPartPositionsRequired = ['front_bumper', 'rear_bumper', 'pillar', 'rear_right_wing', 'rear_left_wing', 'left_sill_panel', 'right_sill_panel']

    const carPartPositions = {
        'front_bumper': ['left', 'center', 'right'],
        'rear_bumper': ['left', 'center', 'right'],
        'pillar': ['front_left', 'center_left', 'rear_left', 'front_right', 'center_right', 'rear_right'],
        'rear_right_wing': ['center', 'rear'],
        'rear_left_wing': ['center', 'rear'],
        'left_sill_panel': ['front', 'rear'],
        'right_sill_panel': ['front', 'rear'],
        'front_spoiler': ['left', 'center', 'right'],
        'front_spoiler_moulding': ['left', 'center', 'right'],
        'rear_spoiler': ['left', 'center', 'right'],
        'rear_spoiler_moulding': ['left', 'center', 'right'],
        'roof': ['front_left', 'center_left', 'rear_left', 'front_right', 'center_right', 'rear_right'],
        'tyre': ['front_left', 'rear_left', 'front_right', 'rear_right'],
        'rim': ['front_left', 'rear_left', 'front_right', 'rear_right'],
    };

    const handleSelectCarPart = (value) => {
        setCarPart(damages?.find(item => item.key === value))
    };

    const handleAddDamage = (value, key) => {
        setNewDamage(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const handleUpdate = (inputValue, key) => {

        let value = inputValue
        if (inputValue === 'new-group') {
            value = uuidv4();
        }

        setLabelData(prevState => {
            // If the key already exists, remove it to "deselect"
            if (prevState[key] === value) {
                const {[key]: _, ...rest} = prevState;  // Remove the key-value pair
                console.log(rest)
                return rest;
            }

            if (key === 'position') {

                const currentLength = prevState[key]?.length || 0
                if (value.length <= 1 || value.length < currentLength) {
                    return {
                        ...prevState,
                        [key]: value,
                    };
                } else {
                    notification.warning({
                        message: 'Please select only a single position',
                        duration: 3,
                    });
                    return {...prevState};
                }
            }

            // Otherwise, update the key with the new value
            return {
                ...prevState,
                [key]: value,
            };
        });
    };

    const onUpdateComment = (newComment) => {
        setLabelData(prevState => ({
            ...prevState,
            comment: newComment,
        }));
    };

    const onAddDamage = () => {
        // console.log(newDamage)

        const requiredKeys = ["doc_id", "carPart_id", "typology"];

        // Check if any required key is missing in newDamage
        const missingKeys = requiredKeys.filter(key => !Object.hasOwn(newDamage, key));

        if (missingKeys.length > 0) {
            // Show notification if any required key is missing
            notification.warning({
                message: 'Missing Attributes',
                description: `The following attributes are missing: ${missingKeys.join(', ')}`,
                duration: 3,
            });
            return; // Exit the function if keys are missing
        }

        setLabelsExits(false)
        setDamages(prevItems => [...prevItems, {
            doc_id: newDamage.doc_id,
            carPart_id: newDamage.carPart_id,
            typology: newDamage.typology,
            id: 99999999
        }]);
        setCarPart({
            doc_id: newDamage.doc_id,
            carPart_id: newDamage.carPart_id,
            typology: newDamage.typology,
            added: true,
            state: 'fn',
            new_damage: true
        })
    };


    function getDefaultForKey(key) {
        const defaults = {
            severity: 'mid',
            typology: 'scratch',
            image_quality: 'ok',
            assignable: 'False',
            surface: 0,
            new_damage: false,
            visible: false,
        };
        return defaults[key] || null; // Fallback to null if no default specified
    }

    const onConfirmLabels = () => {
        console.log(labelData)

        const nullableKeys = ['feedback_uuid', 'matching', 'position', 'group_uuid'];

        if (labelData.state !== 'fp') {
            if (carPartPositionsRequired.includes(labelData.car_part) && labelData.position && labelData.position.length === 0) {
                notification['warning']({
                    message: 'Position is required for certain carparts',
                });
                return
            }

            if (!labelData.group_uuid) {
                notification['warning']({
                    message: 'Group UUID is required for all FN and FP labels',
                });
                return
            }
        } else {
            Object.keys(labelData).forEach(key => {
                if (!nullableKeys.includes(key) && (labelData[key] === null || labelData[key] === undefined)) {
                    labelData[key] = getDefaultForKey(key); // Assign default value
                }
            });
        }

        const allNonNull = Object.entries(labelData)
            .filter(([key]) => !nullableKeys.includes(key)) // Exclude nullable keys
            .every(([, value]) => value !== null && value !== undefined);

        if (allNonNull) {
            axiosInstance.post(
                '/api/image_tool/labels/update',
                {
                    update: labelsExist,
                    customer: props.customer,
                    search_value: props.searchValue,
                    label_type: 'carparts',
                    search_type: props.searchType,
                    update_dict: labelData,
                    damage_uuid: carPart?.damage_uuid || null,
                    feedback: props.labelFeedback ? props.labelTarget : null,
                    vehicle_uuid: props.vehicleUUID
                })
                .then(res => {
                    if (res.data.push_label.success) {
                        notification['success']({
                            message: 'CarPart Labels updated',
                        });
                        props.setUpdateCounter(prevCounter => prevCounter + 1);
                    } else {
                        notification['error']({
                            message: 'Error pushing CarPart Labels',
                            description: res.data.push_label.message,
                        });
                        console.log(res.data.push_label)
                    }
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updateLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            notification['warning']({
                message: 'Not all required label fields have a value',
            });
        }
    };

    const onClickDelete = () => {
        setShowDelete(true)
    }

    const handleCancel = () => {
        setShowDelete(false)
    };

    const onDeleteLabels = () => {
        console.log(labelData)

        axiosInstance.post(
            '/api/image_tool/labels/delete',
            {
                update: labelsExist,
                customer: props.customer,
                search_value: props.labelTarget,
                label_type: 'carparts',
                update_dict: labelData,
                search_type: props.searchType,
                damage_uuid: carPart?.damage_uuid || null,
            })
            .then(res => {
                notification['success']({
                    message: 'CarPart Labels deleted',
                });
                props.setUpdateCounter(prevCounter => prevCounter + 1);
                setCarPart(null)
                setShowDelete(false)
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in updateLabels',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    };

    const predefinedSizes = [
        {label: '2€ Coin (0.05 dm²)', value: 0.05},
        {label: 'Creditcard (0.5 dm²)', value: 0.5},
        {label: 'Hand (1.5 dm²)', value: 1.5},
        {label: 'A4 Paper (6 dm²)', value: 6},
        {label: 'A2 Poster (25 dm²)', value: 25},
    ];

    // console.log(carPart)
    // console.log(damages)

    const getAllowedTypologies = (carPartId) => {
        if (!carPartId) return []; // If carPart_id is null, return an empty array

        // Map BDEO to CV
        const cvMapping = BDEO_TO_CP[carPartId];
        if (!cvMapping) return []; // If no mapping, return empty

        // Handle comma-separated mappings and replace "right" with "left"
        const cvPart = cvMapping.includes(',')
            ? cvMapping.split(',')[0].replace(/right/g, 'left')
            : cvMapping.replace(/right/g, 'left');

        // Get typologies from CP_TO_TYPOLOGY
        return CP_TO_TYPOLOGY[cvPart] ? Object.keys(CP_TO_TYPOLOGY[cvPart]) : [];
    };

    const allowedTypologies = getAllowedTypologies(newDamage.carPart_id);

    return (
        <div>
            <Modal
                title="Delete Labels"
                open={showDelete}
                onCancel={handleCancel}
                width={350}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 16}}>
                    <p>Are you sure you want to delete label for the carpart?</p>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={onDeleteLabels} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Row style={{marginTop: -8, marginBottom: 8}}>
                <Select
                    onChange={(value) => handleSelectCarPart(value)}
                    style={{width: 318, marginRight: 10}}
                    value={carPart ? `${carPart?.doc_id} - ${carPart?.carPart_id} - ${carPart?.typology}` : 'Select damaged car part'}
                >
                    {damages
                        ?.filter(option => option.doc_id !== null) // Filter out options where doc_id is null
                        .map(option => (
                            <Option key={`select-${option.id}-${option.id_label}`} value={option.key}>
                                {option.doc_id} - {option.carPart_id} - {option.typology}
                            </Option>
                        ))
                    }
                </Select>
                <Select
                    onChange={(value) => handleUpdate(value, 'group_uuid')}
                    style={{width: 220}}
                    placeholder="Select a group UUID"
                    value={labelData?.group_uuid}
                    disabled={!carPart}
                >
                    {groupIds?.map((uuid) => (
                        <Option key={`uuid-${uuid}`} value={uuid}>
                            <div>
                                <Text>
                                    {uuid ? uuid.slice(0, 8) : null}
                                </Text>
                                <Tooltip placement="right"
                                         title={<GroupImages groupData={props.groupData} group_uuid={uuid}/>}>
                                    <Button size={'small'} icon={<FaImage/>} style={{marginLeft: 32, marginTop: 4}}/>
                                </Tooltip>
                            </div>

                        </Option>
                    ))}
                    <Option key="new-group" value="new-group">
                        + Create New Group
                    </Option>
                </Select>
                <Popover
                    title={'New Damage Details'}
                    trigger="click"
                    content={
                        <div style={{width: 500}}>
                            <Select
                                defaultValue={'Image'}
                                onChange={(value) => handleAddDamage(value, 'doc_id')}
                                style={{width: 90}}
                            >
                                {docIDOptions?.map(option => (
                                    <Option key={`option-image-${option.value}`} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                defaultValue={'Car Part'}
                                onChange={(value) => handleAddDamage(value, 'carPart_id')}
                                style={{width: 210}}
                                showSearch
                                disabled={newDamage.doc_id === null}
                                placeholder="Select a car part"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {carPartArray?.map(option => {
                                    return (
                                        <Option key={`option-carpart-${option}`} value={option}>
                                            {option}
                                        </Option>
                                    );
                                })}
                            </Select>
                            <Select
                                defaultValue={'Typology'}
                                onChange={(value) => handleAddDamage(value, 'typology')}
                                style={{width: 130}}
                                disabled={newDamage.carPart_id === null}
                            >
                                {typologyDropdown
                                    .filter(option => allowedTypologies.includes(option.value)) // Filter dropdown
                                    .map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                            </Select>
                            <Button onClick={onAddDamage} type={'primary'}>Add</Button>
                        </div>
                    }
                    placement={'left'}
                >
                    <Button icon={<EditFilled/>}
                            style={{marginLeft: 10}}>
                        Add Damage
                    </Button>
                </Popover>
            </Row>
            {/*<Divider style={{marginTop: 4, marginBottom: 4}}/>*/}
            <Row style={{marginTop: 4}}>
                <Descriptions bordered size={'small'} column={2}>
                    {/*<Descriptions.Item label="Car Part" style={{height: '10px', padding: 4, paddingLeft: 16}}>*/}
                    {/*    {zoomDropdown.map(option => (*/}
                    {/*        <Radio.Group key={option.value} optionType="button" size={'small'}>*/}
                    {/*            <Radio value={option.value}>{option.label}</Radio>*/}
                    {/*        </Radio.Group>*/}
                    {/*    ))}*/}
                    {/*</Descriptions.Item>*/}
                    <Descriptions.Item label="Image Quality" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {qualityDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'} value={labelData?.image_quality}
                                disabled={!carPart}
                                onChange={(e) => handleUpdate(e.target.value, 'image_quality')}
                            >
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Severity" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {severityDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'}
                                value={labelData?.severity} disabled={!carPart}
                                onChange={(e) => handleUpdate(e.target.value, 'severity')}
                            >
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Typology" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Select
                            disabled={labelData?.typology ? true : false}
                            size={'small'}
                            value={labelData?.typology}
                            onChange={(value) => handleUpdate(value, 'typology')}
                            style={{width: 130}}
                        >
                            {typologyDropdown.map(option => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="Area" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Select
                            style={{width: 200}}
                            value={labelData?.surface}
                            onChange={(value) => handleUpdate(value, 'surface')}
                            disabled={!carPart}
                            size={'small'}
                            dropdownRender={(menu) => (
                                <>
                                    {menu}
                                    <div style={{display: 'flex', alignItems: 'center', padding: 8}}>
                                        <InputNumber
                                            min={0}
                                            max={300}
                                            step={0.1}
                                            style={{width: '100%'}}
                                            placeholder="(dm²)"
                                            onChange={(value) => handleUpdate(value, 'surface')}
                                        />
                                    </div>
                                </>
                            )}
                        >
                            {predefinedSizes.map(size => (
                                <Option key={size.value} value={size.value}>
                                    {size.label}
                                </Option>
                            ))}
                        </Select>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered size={'small'} column={3} style={{marginBottom: 0, marginTop: 4}}>
                    <Descriptions.Item label="New"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'} value={labelData?.new_damage}
                                     disabled={!carPart} onChange={(e) => handleUpdate(e.target.value, 'new_damage')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Assignable"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'}
                                     value={labelData?.assignable}
                                     disabled={!carPart}
                                     onChange={(e) => handleUpdate(e.target.value, 'assignable')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Visible"
                                       style={{height: '10px', padding: 4, paddingLeft: 16, paddingRight: 16}}>
                        <Radio.Group optionType="button" size={'small'}
                                     value={labelData?.visible}
                                     disabled={!carPart}
                                     onChange={(e) => handleUpdate(e.target.value, 'visible')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Matching" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group
                            value={labelData?.matching}
                            optionType="button"
                            size="small"
                            disabled={!carPart}
                        >
                            {[
                                {value: true, label: 'True'},
                                {value: false, label: 'False'}
                            ].map(option => (
                                <Radio
                                    key={option.value}
                                    value={option.value}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleUpdate(option.value, 'matching');
                                    }}
                                >
                                    {option.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="State" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {stateDropdown.map(option => (
                            <Radio.Group key={option.value} value={labelData?.state}
                                         optionType="button" size={'small'}
                                         disabled={!carPart || (option.value === 'fn' && carPart.state !== 'fn') || (option.value !== 'fn' && carPart.state === 'fn')}
                                         onChange={(e) => handleUpdate(e.target.value, 'state')}>
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))
                        }
                    </Descriptions.Item>
                    {
                        labelData && carPartPositions[labelData.car_part] && (
                            <Descriptions.Item label="Position" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                                <Select
                                    mode="multiple"
                                    value={labelData?.position || []}
                                    size="small"
                                    style={{width: 200}}  // Increased width to accommodate multiple selections
                                    onChange={(values) => handleUpdate(values, 'position')}
                                    placeholder="Select"
                                    maxTagCount={3}  // Shows first 2 tags, then "+X"
                                    maxTagTextLength={8}  // Truncates long position names in tags
                                    disabled={!carPart}
                                >
                                    {carPartPositions[labelData.car_part].map(pos => (
                                        <Select.Option key={pos} value={pos}>
                                            {pos}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Descriptions.Item>
                        )
                    }
                </Descriptions>
                {/*<Descriptions.Item label="New Damage Prediction"*/}
                {/*                   style={{height: '10px', padding: 4, paddingLeft: 16}}>*/}
                {/*    <Radio.Group optionType="button" size={'small'} disabled={!carPart}>*/}
                {/*        <Radio value={true}>True</Radio>*/}
                {/*        <Radio value={false}>False</Radio>*/}
                {/*    </Radio.Group>*/}
                {/*</Descriptions.Item>*/}
            </Row>
            <Row style={{marginTop: 12}}>
                <Button onClick={onConfirmLabels} type={'primary'}>
                    {labelsExist ? 'Update' : 'Submit'}
                </Button>
                <Button onClick={onConfirmLabels} type={'primary'} disabled={true}>
                    Confirm
                </Button>
                {carPart &&
                    <Button onClick={onClickDelete}
                            style={{color: carPart?.AI_model !== null ? null : 'red'}}
                            disabled={carPart?.AI_model !== null && !['ba0d8c42-c5b8-451f-aa81-3958df81db3b', '8dc9b7b8-e9bd-49f4-82e1-21c632b63539', '8791cdd3-cc28-4f28-8dff-be9e99254dec'].includes(auth?.user.profile.sub)}>
                        Delete
                    </Button>
                }
                {labelsExist ? (
                    <div style={{marginLeft: 16}}>
                        <CommentTags
                            data={labelData?.comment}
                            id={labelData?.id}
                            customer={props.customer}
                            labelType={'carparts'}
                            labelTarget={props.labelTarget}
                            onUpdateComment={onUpdateComment}
                        />
                    </div>

                ) : (
                    <Tooltip title="Submit a label to comment">
                        <div style={{opacity: 0.5, width: 150, pointerEvents: 'none', marginLeft: 16}}>
                            <CommentTags/>
                        </div>
                    </Tooltip>
                )}
            </Row>
        </div>
    );
};

export default CarPartLabels;