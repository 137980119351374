export const BDEO_TO_CP = {
    'roof': 'top__roof',
    'front_bumper': 'front__bumper',
    'rear_bumper': 'rear__bumper',
    'bumper_front': 'front__bumper',
    'bumper_back': 'rear__bumper',
    'right_headlamp': 'front_right__bumper',
    'left_headlamp': 'front_left__bumper',
    'right_tail_lamp': 'rear_right__bumper',
    'left_tail_lamp': 'rear_left__bumper',
    'front_plate': 'front__bumper',
    'rear_plate': 'rear__bumper',
    'windscreen': 'front__windscreen',
    'rear_screen': 'rear__screen',
    'front_right_window': 'right_front__window',
    'front_left_window': 'left_front__window',
    'rear_right_window': 'right_rear__window',
    'rear_left_window': 'left_rear__window',
    'front_right_door': 'right_front__door',
    'front_left_door': 'left_front__door',
    'door_front_right': 'right_front__door',
    'door_front_left': 'left_front__door',
    'rear_right_door': 'right_rear__door',
    'rear_left_door': 'left_rear__door',
    'door_back_right': 'right_rear__door',
    'door_back_left': 'left_rear__door',
    'front_right_wing': 'right_front__wing',
    'front_left_wing': 'left_front__wing',
    'rear_right_wing': 'right_rear__wing',
    'rear_left_wing': 'left_rear__wing',
    'front_bumper_right_moulding': 'front_right__bumper',
    'front_bumper_left_moulding': 'front_left__bumper',
    'front_bumper_moulding': 'front__bumper',
    'rear_bumper_right_moulding': 'rear_right__bumper',
    'rear_bumper_left_moulding': 'rear_left__bumper',
    'rear_bumper_moulding': 'rear__bumper',
    'front_right_door_moulding': 'right_front__door',
    'front_left_door_moulding': 'left_front__door',
    'rear_right_door_moulding': 'right_rear__door',
    'rear_left_door_moulding': 'left_rear__door',
    'front_right_light_cap': 'front_right__bumper',
    'front_left_light_cap': 'front_left__bumper',
    'rear_right_light_cap': 'rear_right__bumper',
    'rear_left_light_cap': 'rear_left__bumper',
    'front_emblem': 'front__bumper',
    'rear_emblem': 'rear__bumper',
    'right_sill_panel': 'right_front__sill_panel,right_rear__sill_panel',
    'left_sill_panel': 'left_front__sill_panel,left_rear__sill_panel',
    'rocker_panel_left': 'left_front__sill_panel,left_rear__sill_panel',
    'rocker_panel_right': 'right_front__sill_panel,right_rear__sill_panel',
    'grille': 'front__bumper',
    'grill_radiator': 'front__bumper',
    'front_right_rim': 'right_front__rim',
    'front_left_rim': 'left_front__rim',
    'rear_right_rim': 'right_rear__rim',
    'rear_left_rim': 'left_rear__rim',
    'front_right_door_handle': 'right_front__door',
    'front_left_door_handle': 'left_front__door',
    'rear_right_door_handle': 'right_rear__door',
    'rear_left_door_handle': 'left_rear__door',
    'right_inferior_grille': 'front_right__bumper',
    'left_inferior_grille': 'front_left__bumper',
    'inferior_grille': 'front__bumper',
    'right_mirror': 'right__mirror',
    'left_mirror': 'left__mirror',
    'mirror_support': 'left__mirror,right__mirror',
    'rear_spoiler': 'rear__bumper',
    'pillar': 'left_front__pillar,right_front__pillar,left_rear__pillar,right_rear__pillar',
    'front_left_fog_light': 'front_left__bumper',
    'front_right_fog_light': 'front_right__bumper',
    'rear_right_fog_light': 'rear_right__bumper',
    'rear_left_fog_light': 'rear_left__bumper',
    'rear_fog_light': 'rear__bumper',
    'front_right_tyre': 'right_front__tyre',
    'front_left_tyre': 'left_front__tyre',
    'rear_right_tyre': 'right_rear__tyre',
    'rear_left_tyre': 'left_rear__tyre',
    'bonnet': 'top__bonnet',
    'hood': 'top__bonnet',
    'logo': 'front_bumper',
    'trunk': 'rear__trunk',
    'front_sensor': 'front__bumper',
    'right_exhaust': 'rear_right__bumper',
    'left_exhaust': 'rear_left__bumper',
    'exhaust': 'rear__bumper',
    'front_right_fuel_flap': 'right_front__wing',
    'front_left_fuel_flap': 'left_front__wing',
    'front_right_wing_arch_moulding': 'right_front__wing',
    'front_left_wing_arch_moulding': 'left_front__wing',
    "fender_front_left": "left_front__wing",
    "fender_front_right": "right_front__wing",
    "fender_back_left": "left_rear__wing",
    "fender_back_right": "right_rear__wing",
    'front_bumper_right_arch_moulding': 'front_right__bumper',
    'front_bumper_left_arch_moulding': 'front_left__bumper',
    'front_right_door_arch_moulding': 'right_front__door',
    'front_left_door_arch_moulding': 'left_front__door',
    'rear_right_fuel_flap': 'right_rear__wing',
    'rear_left_fuel_flap': 'left_rear__wing',
    'rear_right_wing_arch_moulding': 'right_rear__wing',
    'rear_left_wing_arch_moulding': 'left_rear__wing',
    'rear_bumper_right_arch_moulding': 'rear_right__bumper',
    'rear_bumper_left_arch_moulding': 'rear_left__bumper',
    'rear_right_door_arch_moulding': 'right_rear__door',
    'rear_left_door_arch_moulding': 'left_rear__door',
    'upper_center_front_bumper': 'front__bumper',
    'lower_center_front_bumper': 'front__bumper',
    'upper_left_front_bumper': 'front_left__bumper',
    'upper_right_front_bumper': 'front_right__bumper',
    'front_right_towing_cap': 'front_right__bumper',
    'front_left_towing_cap': 'front_left__bumper',
    'headlamp_right_washer_cap': 'front_right__bumper',
    'headlamp_left_washer_cap': 'front_left__bumper',
    'front_spoiler': 'front__bumper',
    'front_spoiler_moulding': 'front__bumper',
    'upper_center_rear_bumper': 'rear__bumper',
    'lower_center_rear_bumper': 'rear__bumper',
    'upper_left_rear_bumper': 'rear_left__bumper',
    'upper_right_rear_bumper': 'rear_right__bumper',
    'rear_right_towing_cap': 'rear_right__bumper',
    'rear_left_towing_cap': 'rear_left__bumper',
    'rear_spoiler_moulding': 'rear__bumper',
    'damaged_car_inside': 'interior',
    'antenna': 'top__roof',
    'tyre': 'left_front__tyre,right_front__tyre,left_rear__tyre,right_rear__tyre',
    'front_bumper__left': 'front_left__bumper',
    'front_bumper__right': 'front_right__bumper',
    'front_bumper__center': 'front__bumper',
    'rear_bumper__left': 'rear_left__bumper',
    'rear_bumper__right': 'rear_right__bumper',
    'rear_bumper__center': 'rear__bumper',
    'pillar__front_left': 'left_front__pillar',
    'pillar__center_left': 'left_rear__door',
    'pillar__rear_left': 'left_rear__pillar',
    'pillar__front_right': 'right_front__pillar',
    'pillar__center_right': 'right_rear__door',
    'pillar__rear_right': 'right_rear__pillar',
    'rear_right_wing__center': 'right_front__wing',
    'rear_right_wing__rear': 'right_rear__wing',
    'rear_left_wing__center': 'left_front__wing',
    'rear_left_wing__rear': 'left_rear__wing',
    'right_sill_panel__front': 'right_front__sill_panel',
    'right_sill_panel__rear': 'right_rear__sill_panel',
    'left_sill_panel__front': 'left_front__sill_panel',
    'left_sill_panel__rear': 'left_rear__sill_panel',
    'front_left_underbody': 'underbody__front_left',
    'front_right_underbody': 'underbody__front_right',
    'center_left_underbody': 'underbody__left',
    'center_right_underbody': 'underbody__right',
    'rear_left_underbody': 'underbody__rear_left',
    'rear_right_underbody': 'underbody__rear_right',
 }