import React from 'react';

import VanVector from "../renderVehicleVector/Vectors/VanVector"

const RenderSVGVan = ({damageParts, onPartCLicked, previousDamageColor, customer_mainColor, withOverlay, carvalooVersion, userType, setHoveredPart}) => {

    if (Object.keys(damageParts).length > 0 ){
       return (
          <svg  viewBox="0 0 444 444" fill={"none"} xmlns={"http://www.w3.org/2000/svg"}>
              <VanVector
                  customer_mainColor={customer_mainColor}
                  previousDamageColor={previousDamageColor}
                  colorDamages={!withOverlay}
                  damageParts={damageParts}
                  hasOverlay={withOverlay}
                  carvalooVersion={carvalooVersion}
                  userType={userType}
                  setHoveredPart={setHoveredPart}
              />
          </svg>
       )
    }
}

export default RenderSVGVan