import {Flex, Image} from "antd";
import React from "react";
import HistoryInfo from "./HistoryInfo";


const GroupImages = (props) => {

    const group_uuid = props.group_uuid
    let images = props.images || [];

    if (props.groupData && !props.images) {
        images = props.groupData
            .filter(item => item.group_uuid === group_uuid && item.uri_sas) // Filter relevant items
            .map(item => item.uri_sas); // Extract image URLs
        images = [...new Set(images)];
    }

    // Determine tooltip content (carousel of images or fallback message)
    return (images.length > 1
        ? (
            <Image.PreviewGroup>
                <Flex
                    gap="small"
                    wrap="wrap"
                    justify="center"
                    style={{
                        maxWidth: '450px', // Adjusted for 3 columns
                        maxHeight: '450px', // Adjusted for 3 rows
                        overflow: 'auto'
                    }}
                >
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            src={image}
                            alt={`Image ${index + 1}`}
                            width={200}
                            height={200}
                            style={{
                                objectFit: 'cover',
                                cursor: 'pointer',
                                border: '1px solid #d9d9d9',
                                borderRadius: '4px',
                                margin: '2px',
                                flex: '0 0 calc(33.333% - 4px)' // Ensures 3 columns
                            }}
                            preview={{
                                mask: `View Image ${index + 1}`,
                                tooltipTitle: `Image ${index + 1}`,
                            }}
                        />
                    ))}
                </Flex>
            </Image.PreviewGroup>
        )
        : images.length === 1
            ? (
                <Image
                    src={images[0]}
                    alt="Single Image"
                    width={230}
                    style={{maxHeight: 250, objectFit: 'cover'}}
                />
            )
            : "Images not available"
    )
}

export default GroupImages;