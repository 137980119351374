import React, {useEffect, useState} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import {Layout, Menu, Image, Form, Switch, ConfigProvider, Button} from 'antd';

import Icon from '@ant-design/icons';
import {
    CarOutlined,
    TeamOutlined,
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import {ReactComponent as User} from "../../assets/icons/carvaloo-user.svg";
import {ReactComponent as Settings} from "../../assets/icons/carvaloo-settings.svg";

import {FaChalkboard, FaImage, FaSearch, FaWrench} from 'react-icons/fa';
import {FaCar} from 'react-icons/fa';
import {FaShapes} from 'react-icons/fa';
import {FaChartBar} from 'react-icons/fa';
import {FaInfoCircle} from 'react-icons/fa';
import {FaGlobe} from 'react-icons/fa';
import {FaTags} from 'react-icons/fa';

import {useAuth} from "react-oidc-context";

import CVSidebar from "./../../common_components/Webapp/CVSidebar";
import LoggingInfoModal from "../LoggingInfo/LoggingInfoModal";
import Carvaloo from "../../assets/logos/carvaloo-logo-webapp.png";

const Sidebar = (props) => {

    const [selectedKey, setSelectedKey] = useState(null);
    const [loggingModalOpen, setLoggingModalOpen] = useState(false);
    const [loggingOpenCount, setLoggingOpenCount] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const {pathname} = location;

    useEffect(() => {
        const initialUrl = window.location
        if (initialUrl.pathname.includes('monitoring_dashboard')) {
            setSelectedKey('monitoring_dashboard')
        } else if (initialUrl.pathname.includes('business')) {
            setSelectedKey('business_dashboard')
        } else if (initialUrl.pathname.includes('anomaly_tool')) {
            setSelectedKey('anomaly_tool')
        } else if (initialUrl.pathname.includes('matching_tool')) {
            setSelectedKey('matching_tool')
        } else if (initialUrl.pathname.includes('damage_tool')) {
            setSelectedKey('damage_tool')
        } else if (initialUrl.pathname.includes('map_tool')) {
            setSelectedKey('map_tool')
        } else if (initialUrl.pathname.includes('similarity_tool')) {
            setSelectedKey('similarity_tool')
        } else if (initialUrl.pathname.includes('label_tool')) {
            setSelectedKey('label_tool')
        } else if (initialUrl.pathname.includes('config_tool')) {
            setSelectedKey('config_tool')
        } else {
            setSelectedKey('none')
        }
    }, []);

    const roleOrder = ["business", "monitoring", "anomaly", "matching", "damage", "map", "similarity"]
    const userRoles = (auth?.user?.profile.resource_access.matching_frontend.roles) || []

    const menuItemsByRole = {
        business: [
            {
                label: (<Link to="/business_dashboard">Business</Link>),
                icon: (<Icon component={FaChalkboard} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'business_dashboard'
            },
        ],
        monitoring: [
            {
                label: (<Link to="/monitoring_dashboard">Monitoring</Link>),
                icon: (<Icon component={FaChartBar} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'monitoring_dashboard'
            },
            // {
            //     label: (<Link to="/config_tool">Config</Link>),
            //     icon: (<Icon component={FaWrench} style={{
            //         fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
            //     style: {marginBottom: 8},
            //     key: 'config_tool'
            // },
        ],
        anomaly: [
            {
                label: (<Link to="/anomaly_tool">Anomaly</Link>),
                icon: (
                    <Icon component={FaInfoCircle} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'anomaly_tool'
            },
        ],
        matching: [
            {
                label: (<Link to="/matching_tool">Matching</Link>),
                icon: (<Icon component={FaSearch} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'matching_tool'
            },
        ],
        damage: [
            {
                label: (<Link to="/damage_tool">Damage</Link>),
                icon: (<Icon component={FaCar} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'damage_tool'
            },
        ],
        map: [
            {
                label: (<Link to="/map_tool">Map</Link>),
                icon: (<Icon component={FaGlobe} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'map_tool'
            },
        ],
        similarity: [
            {
                label: (<Link to="/similarity_tool">Similarity</Link>),
                icon: (<Icon component={FaShapes} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'similarity_tool'
            },
            {
                label: (<Link to="/label_tool">Label</Link>),
                icon: (<Icon component={FaTags} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'label_tool'
            },
            {
                label: (<Link to="/image_tool">Image</Link>),
                icon: (<Icon component={FaImage} style={{
                    fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}/>),
                style: {marginBottom: 8},
                key: 'image_tool'
            },
        ]
    };

    // Filter and sort the user roles based on the fixed order
    const sortedRoles = userRoles.filter(
        role => roleOrder.includes(role)).sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b));

    const showLoggingModal = () => {
        setLoggingOpenCount(loggingOpenCount + 1)
        setLoggingModalOpen(true);
    }

    const menuItems = sortedRoles.flatMap(role => menuItemsByRole[role] || []);

    const settings = (
        <Button onClick={showLoggingModal}>Show Logs</Button>
        // <div onClick={(e) => e?.stopPropagation()}>
        //     <Form
        //         layout="horizontal"
        //         style={{maxWidth: 600}}
        //     >
        //         <Form.Item label="Timezone" valuePropName="checked">
        //             <Switch disabled={true} checkedChildren="UTC" unCheckedChildren="Local"
        //                     onClick={(checked, event) => event.stopPropagation()}/>
        //         </Form.Item>
        //     </Form>
        // </div>
    );

    const handleUserMenuClick = (item) => {
        if (item.key === 'signout') {
            navigate("/sign_out");
        }
    }

    useEffect(() => {
        let timeout;

        if (loggingModalOpen) {
            timeout = setTimeout(() => {
                setLoggingOpenCount(prevCount => prevCount + 1);
            }, 30000);
        }

        return () => {
            clearTimeout(timeout); // Clear the timeout when the component is unmounted or when loggingModalOpen changes
        };
    }, [loggingModalOpen, loggingOpenCount]);

    const settingsItems = [
        {
            label: "Settings",
            icon: <Icon component={Settings} style={{
                fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}
            />,
            key: 'settings_menu',
            theme: 'light',
            children: [
                {
                    label: (settings),
                    key: 'settings',
                },
            ]
        },
        {
            label: "User",
            icon: <Icon component={User} style={{
                fontSize: '24px', verticalAlign: 'middle', marginTop: '-4px', marginLeft: '-4px'}}
            />,
            key: 'user',
            theme: 'light',
            children: [
                {
                    label: (auth.user?.profile.name),
                    icon: <Icon component={UserOutlined}
                                style={{
                                    fontSize: '24px',
                                    pointerEvents: 'none',
                                    cursor: 'default'
                                }}/>,
                    key: 'user_name',
                    disabled: true,
                },
                {
                    label: "Sign out",
                    icon: <LogoutOutlined/>,
                    key: 'signout',
                }
            ],
        },
    ]

    return (<>
            <LoggingInfoModal
                showModal={() => setLoggingModalOpen(true)}
                hideModal={() => setLoggingModalOpen(false)}
                open={loggingModalOpen}
                loggingOpenCount={loggingOpenCount}
            />
            <CVSidebar
                color={'#088ed7'}
                logo={Carvaloo}
                selectedKey={selectedKey}
                menuItems={menuItems}
                settingsItems={settingsItems}
                onClickSettings={handleUserMenuClick}
            />
        </>
    );
    //
    // return (<>
    //
    //         <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme={'dark'}>
    //             <Link to="/">
    //                 <div style={{
    //                     width: "80px",
    //                     height: "80px",
    //                     marginLeft: "auto",
    //                     marginRight: "auto",
    //                     marginBottom: '60px'
    //                 }}>
    //                     <div style={{padding: "5px"}}>
    //                         <Image src={CVLogo} preview={false}/>
    //                     </div>
    //                 </div>
    //             </Link>
    //             <div style={{display: 'flex', flexDirection: 'column', height: "calc(100% - 140px)"}}>
    //                 <Menu theme="dark" defaultSelectedKeys={[]}
    //                       size={"large"}
    //                       selectedKeys={[pathname]}
    //                       mode="inline"
    //                     // itemHeight={100}
    //                       items={menuItems}
    //                 />
    //                 <Menu theme="dark" defaultSelectedKeys={[]}
    //                       size={"large"}
    //                       style={{marginTop: 'auto'}}
    //                       onClick={handleUserMenuClick}
    //                       mode="vertical"
    //                       items={}
    //                 />
    //             </div>
    //         </Sider>
    //     </>
    // );
}

export default Sidebar
;