import React, {useState} from "react";
import {Image, Button, Typography, Col, Row, Tooltip} from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

const {Text} = Typography;

const MultiImageRenderer = (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    const plotData = props.data || [];
    const secondaryData = props.secondaryData || [];
    const maxImagesToShow = props.maxImages || 10;
    const height = props.height || 300;
    const minHeight = props.minHeight || 300;
    const columnWidth = props.columnWidth || 100;
    const maxImageHeight = props.maxImageHeight || 180;

    const secondaryDataValid = secondaryData.length === plotData.length;

    // Combine plotData and secondaryData into one array for previewing
    const allImages = plotData.reduce((arr, image, index) => {
        arr.push(image); // Add primary image
        if (secondaryDataValid && secondaryData[index]) {
            arr.push(secondaryData[index]); // Add secondary image after the primary
        }
        return arr;
    }, []);

    const totalImages = plotData.length;
    const totalPages = Math.ceil(totalImages / maxImagesToShow);
    const startIndex = currentPage * maxImagesToShow;
    const endIndex = Math.min(startIndex + maxImagesToShow, totalImages);

    const nextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentIndex(index);
        props.onSelect(props.labels[index]);
    };

    return (
        <>
            <div style={{height: height, minHeight: minHeight, width: '100%'}}>
                {totalImages === 0 ? (
                    <div style={{marginTop: 60, marginLeft: 150}}>No images available</div>
                ) : (
                    <Row>
                        <Button
                            onClick={prevPage}
                            disabled={currentPage === 0}
                            icon={<ArrowLeftOutlined/>}
                            style={{
                                borderRadius: 50,
                                marginLeft: 12,
                                marginRight: 12,
                                marginTop: props.maxImageHeight ? props.maxImageHeight / 2 : 70
                            }}
                        />
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(${maxImagesToShow}, ${columnWidth}px)`,
                            columnGap: '8px',
                            width: 'calc(100% - 115px)',
                        }}>
                            <Image.PreviewGroup current={currentIndex}>
                                {plotData.map((src, index) => (
                                    index >= startIndex && index < endIndex ? (
                                        <Col key={`col-${index}`}>
                                            {props.labels && (
                                                <Text style={{marginLeft: 35, fontWeight: 500}}>
                                                    {props.labels[index]}
                                                </Text>
                                            )}
                                            <Tooltip
                                                title={
                                                    (() => {
                                                        // Find the matching object based on `src` or another unique identifier
                                                        const matchingItem = props.tooltip.find(item => item.uri_sas === src);
                                                        const imageAttributes = matchingItem?.image_attributes || {};
                                                        const predictedAttributes = matchingItem?.predicted_image_attributes || {};

                                                        // Combine and render attributes
                                                        return (
                                                            <>
                                                                <div><strong>Image Attributes:</strong></div>
                                                                {Object.entries(imageAttributes).length > 0 ? (
                                                                    Object.entries(imageAttributes).map(([key, value]) => (
                                                                        <div key={`image-${key}`}>
                                                                            <strong>{key}:</strong> {value || "N/A"}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No image attributes available</div>
                                                                )}
                                                                <div style={{marginTop: '8px'}}><strong>Predicted Image
                                                                    Attributes:</strong></div>
                                                                {Object.entries(predictedAttributes).length > 0 ? (
                                                                    Object.entries(predictedAttributes).map(([key, value]) => (
                                                                        <div key={`predicted-${key}`}>
                                                                            <strong>{key}:</strong> {value || "N/A"}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <div>No predicted attributes available</div>
                                                                )}
                                                            </>
                                                        );
                                                    })()
                                                }
                                                mouseEnterDelay={0.5}
                                            >
                                                <Image
                                                    key={`primary-visible-${index}`}  // Clearly unique for visible primary images
                                                    // width="100%"
                                                    // height="auto"
                                                    src={src}
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: maxImageHeight,
                                                        borderColor: secondaryDataValid && secondaryData[index] ? 'red' : 'transparent',
                                                        border: secondaryDataValid && secondaryData[index] ? '4px solid rgb(8,142,215)' : 'none',
                                                    }}
                                                    onClick={() => handleImageClick(index)}
                                                />
                                            </Tooltip>
                                            {secondaryData[index] && (
                                                <Image
                                                    key={`secondary-visible-${index}`}  // Clearly unique for visible secondary images
                                                    src={secondaryData[index]}
                                                    style={{display: 'none'}}
                                                />
                                            )}
                                        </Col>
                                    ) : <div key={`div-${index}`} style={{height: 0, width: 0}}>
                                        <Image
                                            key={`primary-hidden-${index}`}  // Clearly unique for hidden primary images
                                            src={src}
                                            style={{display: 'none'}}
                                        />
                                        {secondaryData[index] && (
                                            <Image
                                                key={`secondary-hidden-${index}`}  // Clearly unique for hidden secondary images
                                                src={secondaryData[index]}
                                                style={{display: 'none'}}
                                            />
                                        )}
                                    </div>
                                ))}
                            </Image.PreviewGroup>
                        </div>
                        <Button
                            onClick={nextPage}
                            disabled={currentPage === totalPages - 1}
                            icon={<ArrowRightOutlined/>}
                            style={{
                                borderRadius: 50,
                                marginLeft: 12,
                                marginRight: 12,
                                marginTop: props.maxImageHeight ? props.maxImageHeight / 2 : 70
                            }}
                        />
                    </Row>
                )}
            </div>
        </>
    );
};

export default MultiImageRenderer;
