export const carParts = {
  FRONT_HEADLIGHT_LEFT: {
    codes: ["145"],
    size: 1,
    ormName: "front_left__bumper"
  },
  FRONT_HEADLIGHT_RIGHT: {
    codes: ["135"],
    size: 1,
    ormName: "front_right__bumper"
  },
  FRONT_APRON: {
    codes: ["115"],
    size: 1,
    ormName:"front__bumper",
  },
  // DOOR_OR_WINDOW_REAR_LEFT: {
  //   codes: ["425", "428"],
  //   size: 1,
  //   ormName: "left_rear__door,left_rear__window"
  // },
  // DOOR_OR_WINDOW_REAR_RIGHT: {
  //   codes: ["325", "328"],
  //   size: 1,
  //   ormName:"right_rear__door,right_rear__window"
  // },
  ROOF: {
    codes: ["005"],
    size: 1,
    ormName:"top__roof"
  },
  ENGINE_HOOD: {
    codes: ["015"],
    size: 1,
    ormName:"top__bonnet"
  },
  REAR_RIGHT_WHEEL: {
    codes : ["322", "323"],
    isParent: true,
    ormName: "right_rear__tyre,right_rear__rim"
  },
  TYRE_RIGHT_REAR: {
    codes: ["322"],
    size: 1,
    ormName:"right_rear__tyre"
  },
  RIM_RIGHT_REAR: {
    codes: ["323"],
    size: 1,
    ormName:"right_rear__rim"
  },
  REAR_LEFT_WHEEL: {
    codes : ["422", "423"],
    isParent: true,
    ormName:"left_rear__tyre,left_rear__rim"
  },
  TYRE_LEFT_REAR: {
    codes: ["422"],
    size: 1,
    ormName:"left_rear__tyre"
  },
  RIM_LEFT_REAR: {
    codes: ["423"],
    size: 1,
    ormName:"left_rear__rim"
  },
  FRONT_RIGHT_WHEEL: {
    codes : ["312", "313"],
    isParent: true,
    ormName:"right_front__tyre,right_front__rim"
  },
  RIM_RIGHT_FRONT: {
    codes: ["313"],
    size: 1,
    ormName:"right_front__tyre"
  },
  TYRE_RIGHT_FRONT: {
    codes: ["312"],
    size: 1,
    ormName:"right_front__rim"
  },
  FRONT_LEFT_WHEEL: {
    codes : ["412", "413"],
    isParent: true,
    ormName:"left_front__tyre,left_front__rim"
  },
  RIM_LEFT_FRONT: {
    codes: ["413"],
    size: 1,
    ormName:"left_front__tyre"
  },
  TYRE_LEFT_FRONT: {
    codes: ["412"],
    size: 1,
    ormName: "left_front__rim"
  },

  DOOR_LEFT_REAR: {
    codes: ["425"],
    size: 1,
    ormName:"left_rear__door"
  },
  DOOR_RIGHT_REAR: {
    codes: ["325"],
    size: 1,
    ormName:"right_rear__door"
  },
  DOOR_LEFT_FRONT: {
    codes: ["415"],
    size: 1,
    ormName:"left_front__door"
  },
  DOOR_RIGHT_FRONT: {
    codes: ["315"],
    size: 1,
    ormName:"right_front__door"
  },
  // DOOR_OR_WINDOW_FRONT_LEFT: {
  //   codes: ["415", "418"],
  //   size: 1,
  //   ormName: "left_front__door,left_front__window"
  // },
  // DOOR_OR_WINDOW_FRONT_RIGHT: {
  //   codes: ["315", "318"],
  //   size: 1,
  //   ormName:"right_front__door,right_front__window"
  // },
  DOOR_WINDOW_LEFT_REAR: {
    codes: ["428"],
    size: 1,
    ormName:"left_rear__window"
  },
  DOOR_WINDOW_RIGHT_REAR: {
    codes: ["328"],
    size: 1,
    ormName:"right_rear__window"
  },
  DOOR_WINDOW_LEFT_FRONT: {
    codes: ["418"],
    size: 1,
    ormName:"left_front__window"
  },
  DOOR_WINDOW_RIGHT_FRONT: {
    codes: ["318"],
    size: 1,
    ormName:"right_front__window"
  },
  SIDE_MIRROR_LEFT: {
    codes: ["999"],
    size: 1,
    ormName:"left__mirror"
  },
  SIDE_MIRROR_RIGHT: {
    codes: ["998"],
    size: 1,
    ormName:"right__mirror"
  },
  // REAR_TIER_OR_WHEEL_LEFT: {
  //   codes: ["426", "422", "423"],
  //   size: 1,
  //   ormName: "left_rear__wing,left_rear__tyre,left_rear__rim"
  // },
  // REAR_TIER_OR_WHELL_RIGHT: {
  //   codes: ["326", "322", "323"],
  //   size: 1,
  //   ormName: "right_rear__wing,right_rear__tyre,right_rear__rim"
  // },
  // FRONT_TIER_OR_WHEEL_LEFT: {
  //   codes: ["416", "412", "413"],
  //   size: 1,
  //   ormName:"left_front__wing,left_front__tyre,left_front__rim"
  // },
  // FRONT_TIER_OR_WHEEL_RIGHT: {
  //   codes: ["316", "312", "313"],
  //   size: 1,
  //   ormName:"right_front__wing,right_front__tyre,right_front__rim"
  // },
  WINDSCREEN: {
    codes: ["018"],
    size: 1,
    ormName:"front__windscreen"
  },
  REAR_APRON_LEFT: {
    codes: ["245"],
    size: 1,
    ormName:"rear_left__bumper"
  },
  REAR_APRON_RIGHT: {
    codes: ["235"],
    size: 1,
    ormName:"rear_right__bumper"
  },
  REAR_APRON_MID: {
    codes: ["225"],
    size: 1,
    ormName:"rear__bumper"
  },
  TRUNK_INC_REAR_WINDSCREEN: {
    codes: ["025", "028"],  //["028"],
    isParent: true,
    ormName:"rear__trunk,rear__screen"
  },

  TRUNK : {
    codes: ["025"],
    size: 1,
    ormName:"rear__trunk"
  },

  REAR_WINDSCREEN : {
    codes: ["028"],
    size: 1,
    ormName:"rear__screen"
  },

  UNDERBODY_FRONT_LEFT: {
    codes: ["014"],
    size: 1,
    ormName:"underbody__front_left"
  },
  UNDERBODY_REAR_LEFT: {
    codes: ["024"],
    size: 1,
    ormName:"underbody__rear_left"
  },
  UNDERBODY_MIDDLE_LEFT: {
    codes: ["034"],
    size: 1,
    ormName:"underbody__left"
  },

  UNDERBODY_FRONT_RIGHT: {
    codes: ["013"],
    size: 1,
    ormName:"underbody__front_right"
  },
  UNDERBODY_REAR_RIGHT: {
    codes: ["023"],
    size: 1,
    ormName:"underbody__rear_right"
  },
  UNDERBODY_MIDDLE_RIGHT: {
    codes: ["033"],
    size: 1,
    ormName:"underbody__right"
  },

  // UNDERBODY_CENTER_LEFT: {
  //   codes: ["400"],
  //   size: 1,
  // },
  FRONT_SILL_LEFT: {
    codes: ["401"],
    size: 1,
    ormName:"left_front__sill_panel"
  },

  REAR_SILL_LEFT: {
    codes: ["402"],
    size: 1,
    ormName:"left_rear__sill_panel"
  },

  // UNDERBODY_CENTER_RIGHT: {
  //   codes: ["300"],
  //   size: 1,
  // },
  FRONT_SILL_RIGHT: {
    codes: ["301"],
    size: 1,
    ormName:"right_front__sill_panel"
  },

  REAR_SILL_RIGHT: {
    codes: ["302"],
    size: 1,
    ormName:"right_rear__sill_panel"
  },

  FIXED_WINDOW_RIGHT_REAR: {
    codes: ["329"],
    size: 1,
    ormName:"right_rear__fixed__window"
  },

  FIXED_WINDOW_LEFT_REAR: {
    codes: ["429"],
    size: 1,
    ormName:"left_rear__fixed_window"
  },

  FRONT_PILLAR_RIGHT: {
    codes: ["314"],
    size: 1,
    ormName:"right_front__pillar"
  },

  FRONT_PILLAR_LEFT: {
    codes: ["414"],
    size: 1,
    ormName:"left_front__pillar"
  },

  REAR_PILLAR_RIGHT: {
    codes: ["324"],
    size: 1,
    ormName:"right_rear__pillar"
  },

  REAR_PILLAR_LEFT: {
    codes: ["424"],
    size: 1,
    ormName:"left_rear__pillar"
  },

  XXX_RIM_RIGHT_FRONT: {
    codes: ["316"],
    size: 1,
    ormName:"right_front__wing"
  },

  XXX_RIM_LEFT_FRONT: {
    codes: ["416"],
    size: 1,
    ormName:"left_front__wing"
  },

  XXX_RIM_RIGHT_REAR: {
    codes: ["326"],
    size: 1,
    ormName:"right_rear__wing"
  },

  XXX_RIM_LEFT_REAR: {
    codes: ["426"],
    size: 1,
    ormName: "left_rear__wing"
  },
};


