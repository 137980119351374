import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Switch,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Tag, Checkbox
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled,
    PictureOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {FaFile, FaInfo} from "react-icons/fa";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import axiosInstance from "../../services/axios";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const FeedbackInfo = (props) => {

    const [showRemoveEvent, setShowRemoveEvent] = useState(false);
    const [inspectionId, setInspectionId] = useState(null);

    const feedbackData = props.feedbackData
    const labelTarget = props.labelTarget
    const onSelectFeedback = props.onSelectFeedback
    const selectedFeedbacks = props.selectedFeedbacks

    const getColorForIntensity = (intensity) => {
        switch (intensity) {
            case 3:
                return 'red';
            case 2:
                return 'orange';
            case 1:
                return 'yellow';
            case 0:
            default:
                return 'gray'; // Also handles None (null/undefined)
        }
    };

    const onSetTarget = () => {
        if (labelTarget === props.searchValue) {
            props.setLabelTarget(selectedFeedbacks[0])
            props.setLabelFeedback(true)
        } else {
            props.setLabelTarget(props.searchValue)
            props.setLabelFeedback(false)
        }
    }

    const onClickClaimsReady = () => {

        const payload = {
            customer: props.customer,
            event_id: props.eventId,
            inspection_id: inspectionId,
        };

        axiosInstance.post('/api/image_tool/remove_mapping', payload)
            .then(response => {
                props.setFeedbacksReload(prevCounter => prevCounter + 1);
                notification['success']({message: 'Success removing event mapping'});
                setShowRemoveEvent(false)
            })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error removing event mapping', description: error.message});
            })
    }

    const handleCancel = () => {
        setShowRemoveEvent(false)
    };

    const onClickRemove = (value) => {
        setInspectionId(value)
        setShowRemoveEvent(true)
    }

    return (
        <>
            <Modal
                title="Remove Event Mapping"
                open={showRemoveEvent}
                onCancel={handleCancel}
                width={450}
                footer={null}
            >
                <Row justify="center" style={{marginBottom: 8}}>
                    <p>Are you sure you want to remove this inspection to event mapping?</p>
                </Row>
                <Row justify="center">
                    <Button type="primary" onClick={onClickClaimsReady} style={{marginRight: 8}}>
                        Yes
                    </Button>
                    <Button onClick={handleCancel}>
                        No
                    </Button>
                </Row>
            </Modal>
            <Card
                style={{
                    width: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    marginBottom: 8,
                    paddingBottom: 10,
                    minHeight: 370,
                    maxHeight: 370
                }}
                styles={{
                    body: {
                        padding: '16px',
                        display: 'flex',
                        flexDirection: "column",
                        flex: "1 1 auto"
                    }
                }}
                title={'Feedbacks'}
            >
                <div style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minHeight: 100,
                    maxHeight: '286px',
                }}>
                    {feedbackData &&
                        <>
                            {/*<Row>*/}
                            {/*    <Text style={{fontWeight: 500, fontSize: 16}}>*/}
                            {/*        Feedbacks*/}
                            {/*    </Text>*/}
                            {/*</Row>*/}
                            <Row>
                                <Alert message={`Reference - ${labelTarget}`} type="info"
                                       style={{height: 25, marginTop: 0}}/>
                                {feedbackData.length === 1 &&
                                    <Switch style={{marginTop: 2, marginLeft: 4}} onChange={onSetTarget}/>
                                }
                            </Row>
                            {feedbackData.length > 0 &&
                                <Timeline style={{marginTop: 20, marginLeft: 20, minHeight: 298}}>
                                    {feedbackData.map((item) => (
                                        <Timeline.Item
                                            key={item.uuid ? item.uuid : item.inspection_id}
                                        >
                                            <Row>
                                                <Col span={14}>
                                                    <Row style={{marginBottom: 4}}>
                                                        <Tooltip title={item.uuid ? item.uuid : item.inspection_id}>
                                                            <Text style={{fontWeight: 500, marginRight: 8}}>
                                                                {item.uuid ? item.uuid.substring(0, 8) : item.inspection_id.substring(0, 8)}
                                                            </Text>
                                                        </Tooltip>
                                                        {item.created_timestamp ?
                                                            item.created_timestamp.replace('T', ' - ').slice(0, 21) :
                                                            item.inspection_datetime.replace('T', ' - ').slice(0, 21)
                                                        }
                                                    </Row>
                                                    <Row>
                                                        {
                                                            item.matching ?
                                                                <Tag color={'red'}>
                                                                    <CheckCircleOutlined/>
                                                                </Tag>

                                                                :
                                                                <Tag color={'gray'}>
                                                                    <CloseCircleOutlined/>
                                                                </Tag>
                                                        }
                                                        <Tag color={getColorForIntensity(item.intensity)}>
                                                            <WarningOutlined/>
                                                        </Tag>
                                                        <Tag color="blue">
                                                            <PictureOutlined/> {item?.doc_ids ? item?.doc_ids.length : 0}
                                                        </Tag>
                                                    </Row>
                                                    <Row style={{paddingRight: 24, marginTop: 4}}>
                                                        <Tooltip
                                                            title={item?.comments}
                                                            mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                                            mouseLeaveDelay={0}    // Hide immediately>
                                                        >
                                                            <Text>
                                                                {'Comment: ' + (item?.comments ? (item?.comments.length > 100 ? item?.comments.substring(0, 100) + '...' : item?.comments) : 'None')}
                                                            </Text>
                                                        </Tooltip>
                                                    </Row>
                                                </Col>
                                                <Col span={6} style={{marginTop: 32}}>
                                                    {item.inspection_id ?
                                                        <Button onClick={() => onClickRemove(item.inspection_id)}>Remove</Button> :
                                                        <CarPartRenderer value={item?.carPart_id}/>
                                                    }
                                                </Col>
                                                <Col span={4} style={{marginTop: item.inspection_id ? 40 : 54}}>
                                                    <Checkbox
                                                        style={{marginLeft: 20, marginRight: 16}}
                                                        onChange={(e) => onSelectFeedback(item.uuid, e.target.checked)} // Pass item.id and checked status
                                                        checked={selectedFeedbacks?.includes(item.uuid)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                        </Timeline.Item>
                                    ))}
                                </Timeline>
                            }
                            {feedbackData.length === 0 &&
                                <Row style={{
                                    marginTop: 100,
                                    marginBottom: 196,
                                    marginLeft: 150
                                }}>
                                    No data available
                                </Row>
                            }
                        </>
                    }
                </div>
            </Card>
        </>
    );
};

export default FeedbackInfo;