import React from 'react';

import LargeSUVVeector from "../renderVehicleVector/Vectors/LargeSUVVector"

const RenderSVGLargeSUV = ({damageParts, onPartCLicked, previousDamageColor, customer_mainColor, withOverlay, carvalooVersion, userType, setHoveredPart}) => {

    // console.log("damagePArts: ", damageParts)

    if (Object.keys(damageParts).length > 0 ){
       return (
          <svg  viewBox="0 0 445 436" fill={"none"} xmlns={"http://www.w3.org/2000/svg"}>
              <LargeSUVVeector
                  customer_mainColor={customer_mainColor}
                  previousDamageColor={previousDamageColor}
                  colorDamages={!withOverlay}
                  damageParts={damageParts}
                  hasOverlay={withOverlay}
                  carvalooVersion={carvalooVersion}
                  userType={userType}
                  setHoveredPart={setHoveredPart}
              />
          </svg>
       )
    }
}

export default RenderSVGLargeSUV