import React, {useEffect, useState} from 'react';

const VanVector = ({
                       customer_mainColor,
                       previousDamageColor,
                       damageParts,
                       colorDamages,
                       hasOverlay,
                       carvalooVersion,
                       userType,
                       setHoveredPart
                   }) => {

    const getColor = (carPart) => {

        // get background part of the SVG part

        let default_color = "#D9D9D9"
        let previously_damaged_color = previousDamageColor ? previousDamageColor : "url(#diagonalHatch)"    //"#ACACAC"
        let carPartNames = carPart.split("&")
        for (var p of carPartNames) {
            if (Object.keys(damageParts).includes(p)) {
                if (damageParts[p].source === "anomaly") {  // case where in backend no damages was found, we then give the predictions form anomaly
                    if (hasOverlay) {  // we keep it gray when overlay
                        default_color = default_color
                    } else {
                        default_color = "#E2BFBF"
                    }
                } else if (damageParts[p].status.includes("new_damage") && damageParts[p].validation === true) {
                    default_color = customer_mainColor  //CV blue for AI validated part / those part won t be selectable neither
                } else if (damageParts[p].status.includes("new_damage") && carvalooVersion === "carvaloo_10" && userType !== "carvaloo") {  // old version
                    // customer
                    if (hasOverlay) {   // we want a grey carPart with only checkbox colored when part selected
                        default_color = default_color
                    } else {
                        default_color = customer_mainColor  // for global overview without checkbox, we want selection in cv blue
                    }
                } else if (damageParts[p].status.includes("new_damage") && damageParts[p].validation === null || damageParts[p].status_cv2 === "CV2_unvalidated") {
                    // case for cv 1.5 and cv user_type where we show both validated and unvalidated carPart. unvalidated one get a lighter blue
                    if (!hasOverlay) {
                        if (damageParts[p].source.includes("webapp")) { // dark blue for schaden also selected via webapp
                            default_color = customer_mainColor
                        } else if (damageParts[p].status.includes("previous_damage")) {
                            default_color = "#ffbc79"
                        } else {  // light blue for schaden only detected via AI
                            default_color = "#088ed780"
                        }
                    } else {
                        if (damageParts[p].status_cv2 === "CV2_unvalidated") {
                            if (damageParts[p].status.includes("previous_damage")) {
                                default_color = "#FFBC79FF"
                            } else {
                                default_color = "#088ed780"
                            }
                        }
                    }
                }
                //if we want to also show the previous damages on the top view:
                else if (damageParts[p].status.includes("previous_damage") && damageParts[p].validation === true) {
                    default_color = "#3d3d3d"
                } else if (damageParts[p].status.includes("previous_damage")) {
                    console.log(damageParts[p])
                    default_color = previously_damaged_color
                }
            } else if (p.startsWith("UNDERBODY")) {
                default_color = "#fff"
            }
        }
        return default_color
    }

    const handleMouseEnter = (partId) => {
        setHoveredPart(partId);
    };

    const handleMouseLeave = () => {
        setHoveredPart(null);
    };

    return (
        <>
            <g id="Van">
                <g id="SURFACES">
                    <g id="RIGHT_VIEW_SURFACES">
                        <path
                            id="SIDE_MIRROR_RIGHT"
                            d="M345.08 123.621C341.935 125.286 340.361 127.146 337.666 131.014C337.232 133.364 337.371 134.912 338.161 137.999C338.986 139.594 339.836 140.267 342.014 141.038C348.555 141.962 350.841 141.936 350.986 140.342C351.506 137.677 351.723 135.507 351.999 130.575C352.321 127.325 352.406 125.627 352.231 123.025C348.817 121.49 347.281 121.021 347.212 122.92C346.01 123.239 345.46 123.403 345.08 123.621Z"
                            fill={getColor('SIDE_MIRROR_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('SIDE_MIRROR_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="XXX_RIM_RIGHT_REAR"
                            d="M344.004 292.565L341.683 405.883C353.978 406.385 360.955 406.056 373.527 404.584C377.405 404.552 378.65 403.12 379.38 398.48L382.861 368.36C376.899 358.262 375.552 351.564 377.229 337.494C380.267 323.68 384.887 318.087 398.73 312.252L399.301 291.237C370.018 294.227 354.777 295.525 344.004 292.565Z"
                            fill={getColor('XXX_RIM_RIGHT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('XXX_RIM_RIGHT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_RIGHT_REAR"
                            d="M348.016 200.942C345.962 235.921 345.155 255.966 344.014 292.095C353.71 295.021 367.802 294.238 399.321 290.777L401.796 201.113C366.096 200.157 351.051 201.096 348.016 200.942Z"
                            fill={getColor('DOOR_RIGHT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_RIGHT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_RIGHT_FRONT"
                            d="M353.691 120.368C350.995 150.867 349.802 168.486 348.034 200.501C354.287 200.332 366.12 200.375 401.812 200.665L403.997 121.559C375.121 118.672 364.099 118.437 353.691 120.368Z"
                            fill={getColor('DOOR_RIGHT_FRONT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_RIGHT_FRONT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="XXX_RIM_RIGHT_FRONT"
                            d="M353.731 119.903C368.094 118.183 379.883 118.766 404.008 121.09L404.159 115.666C389.955 111.701 384.462 107.252 379.246 95.2022C376.213 81.7838 376.696 74.5138 382.826 62.1578C380.898 59.0963 378.762 56.897 372.938 52.052C370.236 56.0841 366.921 58.5533 361.024 62.9557C357.986 69.3545 353.161 89.0315 354.012 95.5653C354.657 95.9476 355.044 96.9186 355.358 98.492L353.731 119.903Z"
                            fill={getColor('XXX_RIM_RIGHT_FRONT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('XXX_RIM_RIGHT_FRONT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="REAR_PILLAR_RIGHT"
                            d="M305.645 378.343L307.596 401.249C320.953 403.745 328.426 404.905 341.681 405.91L341.868 396.956C341.45 398.46 340.8 398.796 339.156 398.804C330.532 397.736 325.996 396.014 318.258 391.613C310.122 386.006 306.949 383.046 305.645 378.343Z"
                            fill={getColor('REAR_PILLAR_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_PILLAR_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_WINDOW_RIGHT_REAR"
                            d="M302.737 199.785C301.66 234.758 301.586 254.201 302.075 288.685L343.569 292.009C344.65 256.253 345.523 236.315 347.568 200.956L302.737 199.785Z"
                            fill={getColor('DOOR_WINDOW_RIGHT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_WINDOW_RIGHT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FIXED_WINDOW_RIGHT_REAR"
                            d="M302.071 289.126C302.556 313.482 302.963 327.16 303.891 351.587L305.997 377.187C306.128 380.216 307.424 382.194 311.483 386.075C319.165 392.34 324.039 395.165 334.757 397.759C340.379 398.942 341.509 398.369 341.454 396.082L343.56 292.454L302.071 289.126Z"
                            fill={getColor('FIXED_WINDOW_RIGHT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FIXED_WINDOW_RIGHT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="DOOR_WINDOW_RIGHT_FRONT" onMouseEnter={() => handleMouseEnter('DOOR_WINDOW_RIGHT_FRONT')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M310.279 151.243C304.864 160.941 303.346 171.97 302.73 199.328L347.58 200.502L351.716 138.868C351.447 140.756 351.142 141.412 350.257 141.691C339.063 142.536 336.292 140.093 337.237 130.822C337.531 130.413 337.814 130.024 338.089 129.655C341.731 124.754 343.726 123.325 346.742 122.914C346.998 121.977 347.175 121.56 347.631 121.288C348.6 121.107 349.703 121.422 352.64 122.711C352.752 123.944 352.753 124.632 352.762 125.929L353.264 119.903L355.024 100.309C354.697 95.4747 353.952 94.3413 349.898 97.5731C341.301 107.264 334.866 115.083 328.831 123.299C326.937 125.878 325.083 128.497 323.213 131.225C319.157 137.143 315.031 143.576 310.279 151.243Z"
                                fill={getColor('DOOR_WINDOW_RIGHT_FRONT')}
                            />
                            <path
                                d="M353.264 119.903L352.762 125.929C352.753 124.632 352.752 123.944 352.64 122.711C349.703 121.422 348.6 121.107 347.631 121.288C347.175 121.56 346.998 121.977 346.742 122.914C343.726 123.325 341.731 124.754 338.089 129.655M353.264 119.903L355.024 100.309C354.697 95.4747 353.952 94.3413 349.898 97.5731C341.301 107.264 334.866 115.083 328.831 123.299M353.264 119.903L328.831 123.299M328.831 123.299C326.937 125.878 325.083 128.497 323.213 131.225M323.213 131.225C319.157 137.143 315.031 143.576 310.279 151.243C304.864 160.941 303.346 171.97 302.73 199.328L347.58 200.502L351.716 138.868C351.447 140.756 351.142 141.412 350.257 141.691C339.063 142.536 336.292 140.093 337.237 130.822C337.531 130.413 337.814 130.024 338.089 129.655M323.213 131.225L338.089 129.655"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                        <path
                            id="REAR_SILL_RIGHT"
                            d="M402.245 201.108L399.18 312.115C404.042 310.723 407.505 310.114 415.028 309.898C415.526 270.438 416.61 244.687 417.872 201.368L402.245 201.108Z"
                            fill={getColor('REAR_SILL_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_SILL_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_SILL_RIGHT"
                            d="M404.614 115.802L402.267 200.655L417.881 200.901L420.412 117.966C413.885 117.656 410.452 117.144 404.614 115.802Z"
                            fill={getColor('FRONT_SILL_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_SILL_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="FRONT_HEADLIGHT_RIGHT" onMouseEnter={() => handleMouseEnter('FRONT_HEADLIGHT_RIGHT')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M373.136 51.6163L383.068 61.7557C393.337 50.4243 402.175 48.1823 421.529 48.9761C422.217 26.7488 422.123 16.4939 418.26 15.2368L403.334 14.5386L403.523 12.7949C399.218 13.5914 396.94 13.6307 393.111 12.9388C391.796 14.1023 391.072 14.8815 389.814 16.5725L386.723 23.7494C380.726 33.2032 377.809 39.425 373.136 51.6163Z"
                                fill={getColor('FRONT_HEADLIGHT_RIGHT')}
                            />
                            <path
                                d="M368.405 30.3563C364.562 40.1047 363.158 47.6331 361.114 62.3261C369.199 56.2395 371.83 53.7956 372.775 51.3475C377.255 40.0501 379.907 33.9381 385.642 24.6187C387.678 21.6648 387.14 21.3625 384.905 22.3731C373.403 26.1357 369.537 27.9155 368.405 30.3563Z"
                                fill={getColor('FRONT_HEADLIGHT_RIGHT')}
                            />
                            <path
                                d="M371.188 19.8236C371.188 19.8236 369.86 26.9893 369.036 28.8242C370.348 27.2387 376.563 25.0416 385.74 21.5782C386.899 21.286 387.281 21.43 387.266 22.4872L389.829 16.5385L392.695 12.7502C392.885 12.4041 392.18 12.9258 390.091 14.5861C389.593 14.3312 389.004 14.4685 387.615 15.0194C387.258 14.299 387.043 13.9203 386.508 13.5128C380.774 13.8253 377.804 14.5423 372.73 16.8799C371.962 17.8006 371.57 18.5381 371.188 19.8236Z"
                                fill={getColor('FRONT_HEADLIGHT_RIGHT')}
                            />
                            <path
                                d="M373.136 51.6163L383.068 61.7557C393.337 50.4243 402.175 48.1823 421.529 48.9761C422.217 26.7488 422.123 16.4939 418.26 15.2368L403.334 14.5386L403.523 12.7949C399.218 13.5914 396.94 13.6307 393.111 12.9388C391.796 14.1023 391.072 14.8815 389.814 16.5725L386.723 23.7494C380.726 33.2032 377.809 39.425 373.136 51.6163Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                            <path
                                d="M368.405 30.3563C364.562 40.1047 363.158 47.6331 361.114 62.3261C369.199 56.2395 371.83 53.7956 372.775 51.3475C377.255 40.0501 379.907 33.9381 385.642 24.6187C387.678 21.6648 387.14 21.3625 384.905 22.3731C373.403 26.1357 369.537 27.9155 368.405 30.3563Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                            <path
                                d="M371.188 19.8236C371.188 19.8236 369.86 26.9893 369.036 28.8242C370.348 27.2387 376.563 25.0416 385.74 21.5782C386.899 21.286 387.281 21.43 387.266 22.4872L389.829 16.5385L392.695 12.7502C392.885 12.4041 392.18 12.9258 390.091 14.5861C389.593 14.3312 389.004 14.4685 387.615 15.0194C387.258 14.299 387.043 13.9203 386.508 13.5128C380.774 13.8253 377.804 14.5423 372.73 16.8799C371.962 17.8006 371.57 18.5381 371.188 19.8236Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                        <g id="RIGHT WHEELS">
                            <g id="REAR">
                                <g id="REAR_RIGHT_WHEEL_SURFACES">
                                    <path
                                        id="REAR_TIRE_RIGHT"
                                        d="M412.015 316.905L412.015 316.905C427.972 316.906 440.91 329.837 440.91 345.792C440.91 361.746 427.972 374.677 412.015 374.677C396.057 374.677 383.119 361.745 383.119 345.792C383.119 329.837 396.059 316.905 412.015 316.905Z"
                                        stroke={getColor('TYRE_RIGHT_REAR')}
                                        strokeWidth="6"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                        onMouseEnter={() => handleMouseEnter('REAR_TIRE_RIGHT')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <path
                                        id="RIM_RIGHT_REAR"
                                        d="M412.015 320.596C398.096 320.596 386.81 331.876 386.81 345.792C386.81 359.706 398.094 370.987 412.015 370.987C425.935 370.987 437.219 359.706 437.219 345.792C437.219 331.876 425.935 320.596 412.015 320.596Z"
                                        fill={getColor('RIM_RIGHT_REAR')}
                                        onMouseEnter={() => handleMouseEnter('RIM_RIGHT_REAR')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </g>
                                <g id="REAR_RIGHT_RIM_STROKES"
                                   onMouseEnter={() => handleMouseEnter('REAR_RIGHT_RIM_STROKES')}
                                   onMouseLeave={handleMouseLeave}>
                                    <path
                                        id="path2190"
                                        d="M411.89 334.271C412.047 334.351 412.233 334.351 412.392 334.271"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2192"
                                        d="M412.392 357.058C412.233 356.978 412.047 356.978 411.89 357.058"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2196"
                                        d="M412.317 352.274C412.672 351.146 412.042 349.945 410.912 349.593C409.785 349.24 408.582 349.869 408.23 350.997"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2198"
                                        d="M410.273 350.124C409.439 350.124 408.762 350.801 408.762 351.636C408.762 352.471 409.439 353.148 410.273 353.148C411.11 353.148 411.787 352.471 411.787 351.636C411.787 350.801 411.11 350.124 410.273 350.124Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2202"
                                        d="M408.23 340.331C408.46 341.061 409.06 341.614 409.805 341.781C410.551 341.949 411.331 341.706 411.849 341.143C412.367 340.58 412.547 339.784 412.317 339.054"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2204"
                                        d="M410.273 338.181C409.439 338.181 408.762 338.858 408.762 339.693C408.762 340.528 409.439 341.204 410.273 341.204C411.11 341.204 411.787 340.528 411.787 339.693C411.787 338.858 411.11 338.181 410.273 338.181Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2208"
                                        d="M418.45 347.645C417.498 346.945 416.159 347.15 415.457 348.102C414.756 349.055 414.961 350.395 415.916 351.095"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2210"
                                        d="M417.184 347.858C416.348 347.858 415.67 348.535 415.67 349.37C415.67 350.205 416.348 350.882 417.184 350.882C418.018 350.882 418.696 350.205 418.696 349.37C418.696 348.535 418.018 347.858 417.184 347.858Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2214"
                                        d="M405.882 347.805C407.066 347.805 408.023 346.847 408.023 345.665C408.023 344.482 407.066 343.524 405.882 343.524"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2216"
                                        d="M405.882 344.153C405.047 344.153 404.37 344.829 404.37 345.665C404.37 346.499 405.047 347.176 405.882 347.176C406.718 347.176 407.396 346.499 407.396 345.665C407.396 344.829 406.718 344.153 405.882 344.153Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2220"
                                        d="M415.916 340.234C415.297 340.687 414.97 341.434 415.054 342.195C415.138 342.954 415.622 343.612 416.323 343.919C417.023 344.226 417.834 344.136 418.45 343.683"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2222"
                                        d="M417.184 340.447C416.348 340.447 415.67 341.123 415.67 341.958C415.67 342.793 416.348 343.47 417.184 343.47C418.018 343.47 418.696 342.794 418.696 341.958C418.696 341.123 418.018 340.447 417.184 340.447Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2224"
                                        d="M412.14 324.311C411.471 324.311 410.801 324.343 410.132 324.405"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2226"
                                        d="M410.133 366.923C410.801 366.986 411.472 367.018 412.14 367.018"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2228"
                                        d="M411.89 357.058C411.674 357.166 411.508 357.353 411.426 357.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2230"
                                        d="M409.003 365.178C408.882 365.561 408.939 365.978 409.157 366.316C409.376 366.653 409.735 366.876 410.133 366.923"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2232"
                                        d="M411.426 357.58C410.562 360.095 409.753 362.628 409.003 365.178"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2234"
                                        d="M401.683 327.045C400.337 327.8 399.078 328.699 397.925 329.725"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2236"
                                        d="M411.426 333.748C411.508 333.976 411.674 334.162 411.89 334.271"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2238"
                                        d="M410.133 324.405C409.735 324.453 409.376 324.676 409.157 325.013C408.939 325.35 408.882 325.767 409.003 326.151"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2240"
                                        d="M409.003 326.151C409.753 328.701 410.562 331.234 411.426 333.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2242"
                                        d="M405.304 365.156C405.229 365.398 405.288 365.663 405.459 365.849C405.632 366.036 405.891 366.116 406.139 366.061C406.384 366.005 406.584 365.822 406.659 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2244"
                                        d="M409.012 358.057C409.128 357.683 408.921 357.284 408.546 357.167C408.171 357.05 407.773 357.259 407.655 357.633"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2246"
                                        d="M409.012 358.057L406.659 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2248"
                                        d="M407.655 357.633L405.304 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2270"
                                        d="M405.943 355.868C406.043 355.488 405.898 355.086 405.577 354.857C405.259 354.629 404.829 354.623 404.504 354.841"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2272"
                                        d="M398.048 359.527C397.725 359.766 397.525 360.135 397.5 360.536C397.477 360.938 397.632 361.329 397.925 361.603"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2274"
                                        d="M404.504 354.841C402.317 356.355 400.167 357.918 398.048 359.527"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2276"
                                        d="M401.683 364.284C402.037 364.472 402.46 364.492 402.831 364.339C403.204 364.187 403.488 363.876 403.608 363.493"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2278"
                                        d="M403.608 363.493C404.443 360.969 405.22 358.427 405.943 355.868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2280"
                                        d="M401.101 350.215C401.405 349.968 401.528 349.558 401.405 349.185C401.283 348.811 400.942 348.553 400.548 348.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2282"
                                        d="M394.702 354.975C396.868 353.436 399.003 351.849 401.101 350.215"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2284"
                                        d="M395.081 357.32C394.877 357.47 394.77 357.719 394.797 357.971C394.824 358.223 394.986 358.441 395.218 358.543C395.45 358.644 395.718 358.615 395.925 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2286"
                                        d="M405.352 351.535C405.668 351.302 405.736 350.858 405.504 350.542C405.272 350.226 404.827 350.158 404.511 350.39"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2288"
                                        d="M405.352 351.535L395.925 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2290"
                                        d="M404.511 350.39L395.081 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2298"
                                        d="M400.548 348.535C397.891 348.461 395.231 348.446 392.574 348.489"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2300"
                                        d="M406.659 325.749C406.543 325.375 406.145 325.166 405.77 325.283C405.395 325.4 405.188 325.798 405.304 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2302"
                                        d="M407.655 333.696C407.732 333.937 407.93 334.121 408.177 334.177C408.425 334.232 408.684 334.151 408.855 333.965C409.028 333.778 409.087 333.514 409.012 333.272"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2304"
                                        d="M409.012 333.272L406.659 325.749"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2306"
                                        d="M407.655 333.696L405.304 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2328"
                                        d="M397.925 361.603C399.078 362.63 400.337 363.528 401.683 364.284"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2330"
                                        d="M404.504 336.487C404.829 336.706 405.259 336.7 405.577 336.471C405.898 336.243 406.043 335.841 405.943 335.461"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2332"
                                        d="M397.925 329.725C397.632 330 397.477 330.391 397.5 330.792C397.525 331.193 397.725 331.563 398.048 331.801"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2334"
                                        d="M398.048 331.801C400.167 333.411 402.317 334.973 404.504 336.487"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2336"
                                        d="M403.608 327.836C403.488 327.452 403.204 327.142 402.831 326.989C402.46 326.836 402.037 326.856 401.683 327.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2338"
                                        d="M405.943 335.461C405.22 332.902 404.443 330.36 403.608 327.836"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2340"
                                        d="M400.548 342.793C400.942 342.776 401.283 342.517 401.405 342.144C401.528 341.771 401.405 341.36 401.101 341.114"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2342"
                                        d="M401.101 341.114C399.003 339.48 396.868 337.893 394.702 336.353"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2344"
                                        d="M395.925 332.864C395.609 332.632 395.163 332.7 394.931 333.016C394.699 333.332 394.765 333.776 395.081 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2346"
                                        d="M404.511 340.938C404.716 341.088 404.984 341.118 405.216 341.016C405.45 340.915 405.609 340.696 405.638 340.444C405.666 340.192 405.557 339.944 405.352 339.794"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2348"
                                        d="M405.352 339.794L395.925 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2350"
                                        d="M404.511 340.938L395.081 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2358"
                                        d="M392.572 342.839C395.231 342.883 397.891 342.868 400.548 342.793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2360"
                                        d="M399.375 346.374C399.766 346.374 400.085 346.057 400.085 345.665C400.085 345.272 399.766 344.954 399.375 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2362"
                                        d="M399.375 346.374L391.489 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2364"
                                        d="M399.375 344.954L391.489 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2366"
                                        d="M414.147 324.405C413.481 324.343 412.81 324.311 412.14 324.311"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2368"
                                        d="M412.14 367.018C412.81 367.018 413.481 366.986 414.147 366.923"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2370"
                                        d="M412.856 357.58C412.774 357.353 412.608 357.166 412.392 357.058"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2372"
                                        d="M414.147 366.923C414.547 366.876 414.904 366.653 415.125 366.316C415.343 365.978 415.4 365.561 415.279 365.178"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2374"
                                        d="M415.279 365.178C414.529 362.628 413.72 360.095 412.856 357.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2376"
                                        d="M426.357 329.725C425.204 328.699 423.943 327.8 422.597 327.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2378"
                                        d="M412.392 334.271C412.608 334.162 412.774 333.976 412.856 333.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2380"
                                        d="M415.279 326.151C415.4 325.767 415.343 325.35 415.125 325.013C414.904 324.676 414.547 324.453 414.147 324.405"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2382"
                                        d="M412.856 333.748C413.72 331.234 414.529 328.701 415.279 326.151"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2384"
                                        d="M417.621 365.58C417.698 365.822 417.896 366.005 418.144 366.061C418.391 366.116 418.65 366.036 418.821 365.849C418.994 365.663 419.053 365.398 418.978 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2386"
                                        d="M415.486 353.99C415.37 353.616 414.972 353.407 414.597 353.524C414.222 353.641 414.015 354.039 414.131 354.413"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2388"
                                        d="M414.131 354.413L417.621 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2390"
                                        d="M415.486 353.99L418.978 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2412"
                                        d="M431.599 354.474C432.236 353.068 432.717 351.598 433.038 350.089"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2414"
                                        d="M419.778 354.841C419.451 354.623 419.023 354.629 418.703 354.857C418.385 355.086 418.239 355.488 418.339 355.868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2416"
                                        d="M426.357 361.603C426.65 361.329 426.805 360.938 426.782 360.536C426.757 360.135 426.557 359.766 426.232 359.527"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2418"
                                        d="M426.232 359.527C424.116 357.918 421.965 356.355 419.778 354.841"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2420"
                                        d="M420.674 363.493C420.794 363.876 421.078 364.187 421.451 364.339C421.822 364.492 422.242 364.472 422.597 364.284"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2422"
                                        d="M418.339 355.868C419.062 358.427 419.839 360.969 420.674 363.493"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2424"
                                        d="M423.733 348.536C423.34 348.553 422.999 348.811 422.876 349.185C422.753 349.558 422.874 349.968 423.181 350.215"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2426"
                                        d="M429.58 354.975C429.905 355.213 430.317 355.295 430.708 355.198C431.099 355.101 431.424 354.836 431.599 354.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2428"
                                        d="M423.181 350.215C425.279 351.849 427.412 353.436 429.58 354.975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2430"
                                        d="M428.357 358.464C428.562 358.615 428.832 358.644 429.064 358.543C429.296 358.441 429.457 358.223 429.485 357.971C429.512 357.719 429.403 357.47 429.198 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2432"
                                        d="M422.847 352.652C422.531 352.419 422.085 352.487 421.853 352.803C421.622 353.119 421.69 353.564 422.006 353.796"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2434"
                                        d="M422.006 353.796L428.357 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2436"
                                        d="M422.847 352.652L429.198 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2458"
                                        d="M433.038 350.089C433.113 349.694 433.006 349.287 432.749 348.977C432.493 348.668 432.111 348.489 431.708 348.489"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2460"
                                        d="M431.708 348.489C429.051 348.446 426.391 348.461 423.734 348.535"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2462"
                                        d="M418.978 326.172C419.094 325.798 418.887 325.4 418.512 325.283C418.137 325.166 417.739 325.375 417.621 325.749"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2464"
                                        d="M414.131 336.915C414.056 337.157 414.115 337.421 414.286 337.608C414.459 337.794 414.718 337.875 414.965 337.82C415.213 337.764 415.411 337.581 415.486 337.339"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2466"
                                        d="M414.131 336.915L417.621 325.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2468"
                                        d="M415.486 337.339L418.978 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2490"
                                        d="M422.597 364.284C423.943 363.529 425.204 362.63 426.357 361.603"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2492"
                                        d="M433.038 341.24C432.717 339.731 432.236 338.26 431.599 336.855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2494"
                                        d="M418.339 335.461C418.239 335.841 418.385 336.243 418.703 336.471C419.023 336.7 419.451 336.706 419.778 336.487"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2496"
                                        d="M426.232 331.801C426.557 331.563 426.757 331.193 426.782 330.792C426.805 330.391 426.65 330 426.357 329.725"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2498"
                                        d="M419.778 336.487C421.965 334.973 424.116 333.411 426.232 331.801"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2500"
                                        d="M422.597 327.045C422.242 326.856 421.822 326.836 421.451 326.989C421.078 327.142 420.794 327.452 420.674 327.836"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2502"
                                        d="M420.674 327.836C419.839 330.36 419.062 332.902 418.339 335.461"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2654"
                                        d="M423.181 341.114C422.874 341.36 422.753 341.77 422.876 342.144C422.999 342.517 423.34 342.776 423.733 342.793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2656"
                                        d="M431.599 336.855C431.424 336.493 431.099 336.228 430.708 336.13C430.317 336.033 429.905 336.115 429.58 336.353"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2658"
                                        d="M429.58 336.353C427.412 337.893 425.279 339.48 423.181 341.114"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2660"
                                        d="M429.199 334.008C429.515 333.776 429.583 333.332 429.351 333.016C429.119 332.7 428.673 332.632 428.357 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2662"
                                        d="M422.006 337.533C421.801 337.683 421.692 337.931 421.719 338.183C421.749 338.435 421.908 338.653 422.14 338.755C422.374 338.857 422.642 338.827 422.847 338.677"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2664"
                                        d="M422.006 337.533L428.357 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2666"
                                        d="M422.847 338.677L429.198 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2688"
                                        d="M431.708 342.839C432.111 342.839 432.492 342.66 432.749 342.351C433.006 342.042 433.113 341.635 433.038 341.24"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2690"
                                        d="M423.734 342.793C426.391 342.868 429.051 342.883 431.708 342.839"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2692"
                                        d="M432.792 346.374C433.183 346.374 433.502 346.057 433.502 345.665C433.502 345.272 433.183 344.954 432.792 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2694"
                                        d="M421.087 344.954C420.835 344.954 420.601 345.09 420.474 345.309C420.346 345.529 420.346 345.8 420.474 346.019C420.601 346.239 420.835 346.374 421.087 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2696"
                                        d="M421.088 346.374L432.792 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2698"
                                        d="M421.088 344.954L432.792 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2722"
                                        d="M392.683 354.474C392.856 354.836 393.183 355.101 393.574 355.198C393.963 355.295 394.377 355.213 394.702 354.975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2728"
                                        d="M394.702 336.354C394.377 336.115 393.963 336.034 393.574 336.13C393.183 336.228 392.856 336.493 392.683 336.855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2734"
                                        d="M391.244 350.089C391.562 351.598 392.047 353.068 392.683 354.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2746"
                                        d="M392.572 348.489C392.169 348.489 391.79 348.668 391.533 348.977C391.276 349.287 391.169 349.694 391.244 350.089"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2748"
                                        d="M392.683 336.855C392.047 338.26 391.562 339.731 391.244 341.24"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2760"
                                        d="M391.244 341.24C391.169 341.635 391.276 342.042 391.533 342.351C391.79 342.66 392.169 342.839 392.572 342.839"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2762"
                                        d="M391.49 344.954C391.238 344.954 391.001 345.09 390.876 345.309C390.749 345.529 390.749 345.8 390.876 346.02C391.001 346.239 391.238 346.374 391.49 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <g id="carvaloo-logo" clipPath="url(#clip0_15_4484)">
                                        <path
                                            id="path9"
                                            d="M410.527 343.785C410.554 343.656 410.579 343.543 410.6 343.442C410.6 343.443 410.6 343.443 410.6 343.443L410.527 343.785L410.527 343.785ZM410.515 343.84L410.46 344.098C410.48 344.006 410.498 343.921 410.515 343.84L410.515 343.84ZM413.842 345.75L413.842 345.75L413.842 345.75L413.842 345.75ZM409.984 346.458L409.984 346.458L409.984 346.458ZM410.115 346.458L410.115 346.458L410.023 346.458L410.023 346.458L410.115 346.458ZM409.997 346.458L410.023 346.458L409.997 346.458ZM409.891 344.399L409.942 344.293L409.891 344.399Z"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g id="FRONT">
                                <g id="FRONT_RIGHT_WHEEL_SURFACES">
                                    <path
                                        id="TYRE_RIGHT_FRONT"
                                        d="M412.015 54.2017L412.015 54.2017C427.972 54.2022 440.91 67.1336 440.91 83.0879C440.91 99.0419 427.972 111.973 412.015 111.973C396.057 111.973 383.119 99.0417 383.119 83.0879C383.119 67.1331 396.059 54.2017 412.015 54.2017Z"
                                        stroke={getColor('TYRE_RIGHT_FRONT')}
                                        strokeWidth="6"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                        onMouseEnter={() => handleMouseEnter('TYRE_RIGHT_FRONT')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <path
                                        id="RIM_RIGHT_FRONT"
                                        d="M412.015 57.892C398.096 57.892 386.81 69.1724 386.81 83.0878C386.81 97.0025 398.094 108.283 412.015 108.283C425.935 108.283 437.22 97.0027 437.22 83.0878C437.22 69.1727 425.935 57.8924 412.015 57.892Z"
                                        fill={getColor('RIM_RIGHT_FRONT')}
                                        onMouseEnter={() => handleMouseEnter('RIM_RIGHT_FRONT')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </g>
                                <g id="FRONT_RIGHT_RIM_STROKES"
                                   onMouseEnter={() => handleMouseEnter('FRONT_RIGHT_RIM_STROKES')}
                                   onMouseLeave={handleMouseLeave}>
                                    <path
                                        id="path2190_2"
                                        d="M411.89 71.5671C412.047 71.6469 412.233 71.6469 412.392 71.5671"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2192_2"
                                        d="M412.392 94.3541C412.233 94.2748 412.047 94.2748 411.89 94.3541"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2196_2"
                                        d="M412.317 89.5707C412.672 88.4422 412.042 87.2414 410.912 86.889C409.785 86.5363 408.582 87.1651 408.23 88.2936"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2198_2"
                                        d="M410.273 87.4205C409.439 87.4205 408.762 88.0972 408.762 88.9321C408.762 89.767 409.439 90.444 410.273 90.444C411.11 90.444 411.787 89.767 411.787 88.9321C411.787 88.0972 411.11 87.4205 410.273 87.4205Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2202_2"
                                        d="M408.23 77.6277C408.46 78.3574 409.06 78.9103 409.805 79.0778C410.551 79.2455 411.331 79.0019 411.849 78.4395C412.367 77.8766 412.547 77.0808 412.317 76.3506"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2204_2"
                                        d="M410.273 75.4773C409.439 75.4773 408.762 76.1542 408.762 76.9891C408.762 77.824 409.439 78.5007 410.273 78.5007C411.11 78.5007 411.787 77.824 411.787 76.9891C411.787 76.1542 411.11 75.4773 410.273 75.4773Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2208_2"
                                        d="M418.451 84.9417C417.498 84.2414 416.159 84.4459 415.457 85.3987C414.757 86.3511 414.961 87.6909 415.916 88.3911"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2210_2"
                                        d="M417.184 85.1544C416.348 85.1544 415.67 85.8318 415.67 86.6667C415.67 87.5012 416.348 88.1783 417.184 88.1783C418.018 88.1783 418.696 87.5012 418.696 86.6667C418.696 85.8318 418.018 85.1544 417.184 85.1544Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2214_2"
                                        d="M405.882 85.1013C407.066 85.1013 408.023 84.1433 408.023 82.9609C408.023 81.7786 407.066 80.8201 405.882 80.8201"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2216_2"
                                        d="M405.882 81.449C405.047 81.449 404.37 82.1257 404.37 82.9609C404.37 83.7955 405.047 84.4725 405.882 84.4725C406.718 84.4725 407.396 83.7955 407.396 82.9609C407.396 82.1257 406.718 81.449 405.882 81.449Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2220_2"
                                        d="M415.916 77.53C415.298 77.9829 414.97 78.7303 415.054 79.4909C415.138 80.2508 415.623 80.9079 416.323 81.2152C417.023 81.5222 417.835 81.4324 418.451 80.9793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2222_2"
                                        d="M417.184 77.7432C416.348 77.7432 415.67 78.4199 415.67 79.2546C415.67 80.0895 416.348 80.7662 417.184 80.7662C418.018 80.7662 418.696 80.0899 418.696 79.2546C418.696 78.4199 418.018 77.7432 417.184 77.7432Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2224_2"
                                        d="M412.14 61.6073C411.472 61.6073 410.801 61.6392 410.133 61.7017"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2226_2"
                                        d="M410.133 104.219C410.801 104.283 411.472 104.314 412.14 104.314"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2228_2"
                                        d="M411.89 94.3542C411.674 94.4625 411.508 94.6496 411.426 94.8766"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2230_2"
                                        d="M409.003 102.474C408.882 102.857 408.939 103.275 409.157 103.612C409.376 103.949 409.735 104.172 410.133 104.219"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2232_2"
                                        d="M411.426 94.8766C410.562 97.391 409.753 99.9241 409.003 102.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2234_2"
                                        d="M401.683 64.3411C400.337 65.0965 399.078 65.9952 397.925 67.0217"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2236_2"
                                        d="M411.426 71.0445C411.508 71.272 411.674 71.4587 411.89 71.5671"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2238_2"
                                        d="M410.133 61.7017C409.735 61.7494 409.376 61.9721 409.157 62.3091C408.939 62.6466 408.882 63.0636 409.003 63.4471"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2240_2"
                                        d="M409.003 63.4471C409.753 65.9972 410.562 68.5301 411.426 71.0445"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2242_2"
                                        d="M405.304 102.453C405.229 102.695 405.289 102.959 405.459 103.146C405.632 103.332 405.891 103.413 406.139 103.357C406.384 103.301 406.584 103.118 406.659 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2244_2"
                                        d="M409.012 95.3531C409.128 94.979 408.921 94.5807 408.546 94.4637C408.171 94.3464 407.773 94.5552 407.655 94.9297"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2246_2"
                                        d="M409.012 95.3531L406.659 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2248_2"
                                        d="M407.655 94.9297L405.304 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2270_2"
                                        d="M405.943 93.1641C406.043 92.7846 405.898 92.3819 405.577 92.1535C405.259 91.9254 404.829 91.919 404.504 92.1376"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2272_2"
                                        d="M398.048 96.8238C397.725 97.0619 397.525 97.4317 397.5 97.8328C397.477 98.2338 397.632 98.6251 397.925 98.8997"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2274_2"
                                        d="M404.504 92.1376C402.317 93.6517 400.167 95.2145 398.048 96.8238"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2276_2"
                                        d="M401.683 101.58C402.038 101.769 402.46 101.789 402.831 101.636C403.204 101.483 403.488 101.173 403.608 100.789"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2278_2"
                                        d="M403.608 100.789C404.443 98.265 405.22 95.7228 405.943 93.164"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2280_2"
                                        d="M401.101 87.5111C401.406 87.2646 401.528 86.8546 401.406 86.481C401.283 86.1077 400.942 85.8488 400.549 85.8318"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2282_2"
                                        d="M394.702 92.2714C396.868 90.7321 399.003 89.1452 401.101 87.5111"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2284_2"
                                        d="M395.081 94.6166C394.877 94.7668 394.77 95.015 394.797 95.2672C394.824 95.5192 394.986 95.7372 395.218 95.8392C395.45 95.9408 395.718 95.9112 395.925 95.7606"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2286_2"
                                        d="M405.352 88.831C405.668 88.5988 405.736 88.154 405.504 87.8386C405.273 87.5223 404.827 87.4546 404.511 87.6868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2288_2"
                                        d="M405.352 88.831L395.925 95.7605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2290_2"
                                        d="M404.511 87.6868L395.081 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2298_2"
                                        d="M400.549 85.8318C397.891 85.7575 395.231 85.7421 392.574 85.7855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2300_2"
                                        d="M406.659 63.045C406.543 62.6709 406.146 62.4621 405.77 62.5793C405.395 62.6964 405.188 63.0943 405.304 63.4687"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2302_2"
                                        d="M407.655 70.992C407.732 71.2338 407.93 71.417 408.178 71.4729C408.425 71.5281 408.685 71.4477 408.855 71.2609C409.028 71.0743 409.087 70.8105 409.012 70.5682"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2304_2"
                                        d="M409.012 70.5682L406.659 63.0449"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2306_2"
                                        d="M407.655 70.992L405.304 63.4687"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2328_2"
                                        d="M397.925 98.8996C399.078 99.9261 400.337 100.825 401.683 101.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2330_2"
                                        d="M404.504 73.7834C404.829 74.0022 405.259 73.9959 405.577 73.7675C405.898 73.5393 406.043 73.1369 405.943 72.7569"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2332_2"
                                        d="M397.925 67.0216C397.632 67.2961 397.477 67.6872 397.5 68.0885C397.525 68.4896 397.725 68.8591 398.048 69.0975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2334_2"
                                        d="M398.048 69.0975C400.167 70.707 402.317 72.2696 404.504 73.7834"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2336_2"
                                        d="M403.608 65.1323C403.488 64.7485 403.204 64.4383 402.831 64.2854C402.46 64.1325 402.038 64.1527 401.683 64.3411"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2338_2"
                                        d="M405.943 72.757C405.22 70.1985 404.443 67.6561 403.608 65.1323"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2340_2"
                                        d="M400.549 80.0895C400.942 80.072 401.283 79.8138 401.406 79.4402C401.528 79.0669 401.406 78.6567 401.101 78.4102"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2342_2"
                                        d="M401.101 78.4102C399.003 76.7765 396.868 75.189 394.702 73.6499"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2344_2"
                                        d="M395.925 70.1605C395.609 69.9285 395.163 69.996 394.931 70.3121C394.699 70.628 394.765 71.072 395.081 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2346_2"
                                        d="M404.511 78.2345C404.716 78.3847 404.984 78.4144 405.216 78.3124C405.45 78.211 405.609 77.9927 405.638 77.7404C405.666 77.4882 405.557 77.2405 405.352 77.0903"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2348_2"
                                        d="M405.352 77.0903L395.925 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2350_2"
                                        d="M404.511 78.2345L395.081 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2358_2"
                                        d="M392.572 80.1358C395.231 80.1793 397.891 80.164 400.549 80.0895"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2360_2"
                                        d="M399.376 83.6705C399.767 83.6705 400.085 83.3528 400.085 82.9609C400.085 82.5684 399.767 82.2505 399.376 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2362_2"
                                        d="M399.375 83.6705L391.49 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2364_2"
                                        d="M399.375 82.2505L391.49 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2366_2"
                                        d="M414.147 61.7017C413.481 61.6392 412.81 61.6073 412.14 61.6073"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2368_2"
                                        d="M412.14 104.314C412.81 104.314 413.481 104.283 414.147 104.219"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2370_2"
                                        d="M412.856 94.8766C412.774 94.6496 412.608 94.4625 412.392 94.3542"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2372_2"
                                        d="M414.147 104.219C414.547 104.172 414.904 103.949 415.125 103.612C415.343 103.275 415.4 102.857 415.279 102.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2374_2"
                                        d="M415.279 102.474C414.529 99.9242 413.72 97.3911 412.856 94.8767"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2376_2"
                                        d="M426.357 67.0217C425.204 65.9952 423.943 65.0965 422.597 64.3411"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2378_2"
                                        d="M412.392 71.5671C412.608 71.4587 412.774 71.272 412.856 71.0445"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2380_2"
                                        d="M415.279 63.4471C415.4 63.0636 415.343 62.6466 415.125 62.3091C414.904 61.9721 414.547 61.7494 414.147 61.7017"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2382_2"
                                        d="M412.856 71.0445C413.72 68.5301 414.529 65.9972 415.279 63.4471"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2384_2"
                                        d="M417.621 102.876C417.698 103.118 417.896 103.301 418.144 103.357C418.391 103.413 418.651 103.332 418.821 103.146C418.994 102.959 419.053 102.695 418.978 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2386_2"
                                        d="M415.486 91.2864C415.37 90.9123 414.972 90.7035 414.597 90.8203C414.222 90.9373 414.015 91.3357 414.131 91.7097"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2388_2"
                                        d="M414.131 91.7097L417.621 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2390_2"
                                        d="M415.486 91.2863L418.978 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2412_2"
                                        d="M431.599 91.7699C432.236 90.3644 432.717 88.8946 433.038 87.3852"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2414_2"
                                        d="M419.778 92.1376C419.451 91.919 419.024 91.9254 418.703 92.1535C418.385 92.3819 418.239 92.7846 418.339 93.1641"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2416_2"
                                        d="M426.357 98.8997C426.65 98.6251 426.805 98.2338 426.782 97.8328C426.757 97.4317 426.557 97.0619 426.232 96.8238"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2418_2"
                                        d="M426.232 96.8238C424.116 95.2145 421.965 93.6517 419.778 92.1376"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2420_2"
                                        d="M420.674 100.789C420.794 101.173 421.079 101.483 421.451 101.636C421.822 101.789 422.242 101.769 422.597 101.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2422_2"
                                        d="M418.339 93.164C419.062 95.7228 419.839 98.265 420.674 100.789"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2424_2"
                                        d="M423.734 85.8318C423.34 85.8488 422.999 86.1077 422.876 86.481C422.754 86.8546 422.874 87.2646 423.181 87.5111"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2426_2"
                                        d="M429.58 92.2715C429.905 92.5098 430.317 92.5914 430.708 92.4944C431.099 92.3973 431.424 92.1322 431.599 91.7699"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2428_2"
                                        d="M423.181 87.5111C425.279 89.1452 427.412 90.7321 429.58 92.2714"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2430_2"
                                        d="M428.357 95.7606C428.562 95.9112 428.832 95.9408 429.064 95.8392C429.296 95.7372 429.457 95.5192 429.485 95.2672C429.512 95.015 429.403 94.7668 429.198 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2432_2"
                                        d="M422.847 89.9479C422.531 89.7157 422.085 89.7834 421.854 90.0993C421.622 90.4151 421.69 90.8599 422.006 91.0921"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2434_2"
                                        d="M422.006 91.092L428.357 95.7605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2436_2"
                                        d="M422.847 89.9479L429.198 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2458_2"
                                        d="M433.038 87.3853C433.113 86.9901 433.006 86.5829 432.749 86.2734C432.493 85.9643 432.111 85.7855 431.708 85.7855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2460_2"
                                        d="M431.708 85.7855C429.051 85.7421 426.391 85.7575 423.734 85.8318"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2462_2"
                                        d="M418.978 63.4687C419.094 63.0943 418.887 62.6964 418.512 62.5793C418.137 62.4621 417.739 62.6709 417.621 63.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2464_2"
                                        d="M414.131 74.2116C414.056 74.4536 414.115 74.7179 414.286 74.9042C414.459 75.0905 414.718 75.1714 414.966 75.116C415.213 75.0603 415.411 74.8769 415.486 74.6349"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2466_2"
                                        d="M414.131 74.2115L417.621 63.0449"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2468_2"
                                        d="M415.486 74.6349L418.978 63.4686"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2490_2"
                                        d="M422.597 101.58C423.943 100.825 425.204 99.9261 426.357 98.8996"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2492_2"
                                        d="M433.038 78.536C432.717 77.0269 432.236 75.5566 431.599 74.1514"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2494_2"
                                        d="M418.339 72.7569C418.239 73.1369 418.385 73.5393 418.703 73.7675C419.024 73.9959 419.451 74.0022 419.778 73.7834"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2496_2"
                                        d="M426.232 69.0975C426.557 68.8591 426.757 68.4896 426.782 68.0885C426.805 67.6872 426.65 67.2961 426.357 67.0216"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2498_2"
                                        d="M419.778 73.7834C421.965 72.2696 424.116 70.707 426.232 69.0975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2500_2"
                                        d="M422.597 64.3411C422.242 64.1527 421.822 64.1325 421.451 64.2854C421.079 64.4383 420.794 64.7485 420.674 65.1323"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2502_2"
                                        d="M420.674 65.1323C419.839 67.6561 419.062 70.1985 418.339 72.757"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2654_2"
                                        d="M423.181 78.4102C422.874 78.6567 422.754 79.0669 422.876 79.4402C422.999 79.8138 423.34 80.072 423.734 80.0895"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2656_2"
                                        d="M431.599 74.1514C431.424 73.7896 431.099 73.5239 430.708 73.4267C430.317 73.3299 429.905 73.4115 429.58 73.6498"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2658_2"
                                        d="M429.58 73.6499C427.412 75.189 425.279 76.7765 423.181 78.4102"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2660_2"
                                        d="M429.199 71.3046C429.515 71.0719 429.583 70.6279 429.351 70.312C429.119 69.9959 428.674 69.9285 428.358 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2662_2"
                                        d="M422.006 74.8292C421.801 74.9794 421.692 75.2273 421.719 75.4798C421.749 75.7316 421.908 75.9497 422.14 76.0515C422.374 76.1533 422.642 76.1236 422.847 75.9733"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2664_2"
                                        d="M422.006 74.8292L428.357 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2666_2"
                                        d="M422.847 75.9734L429.198 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2688_2"
                                        d="M431.708 80.1357C432.111 80.1357 432.493 79.9567 432.749 79.6476C433.006 79.3386 433.113 78.9311 433.038 78.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2690_2"
                                        d="M423.734 80.0894C426.391 80.164 429.051 80.1792 431.708 80.1358"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2692_2"
                                        d="M432.792 83.6705C433.183 83.6705 433.502 83.3528 433.502 82.9609C433.502 82.5684 433.183 82.2505 432.792 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2694_2"
                                        d="M421.088 82.2505C420.835 82.2505 420.601 82.3859 420.474 82.6054C420.346 82.8254 420.346 83.0958 420.474 83.3158C420.601 83.5353 420.835 83.6705 421.088 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2696_2"
                                        d="M421.088 83.6705L432.793 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2698_2"
                                        d="M421.088 82.2505L432.793 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2722_2"
                                        d="M392.683 91.7699C392.856 92.1322 393.183 92.3973 393.574 92.4944C393.963 92.5914 394.377 92.5098 394.702 92.2715"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2728_2"
                                        d="M394.702 73.6498C394.377 73.4115 393.963 73.3299 393.574 73.4267C393.183 73.5239 392.856 73.7891 392.683 74.1514"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2734_2"
                                        d="M391.244 87.3852C391.563 88.8946 392.047 90.3644 392.683 91.7699"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2746_2"
                                        d="M392.572 85.7855C392.169 85.7855 391.79 85.9643 391.533 86.2734C391.276 86.5829 391.169 86.9901 391.244 87.3853"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2748_2"
                                        d="M392.683 74.1514C392.047 75.5566 391.563 77.0269 391.244 78.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2760_2"
                                        d="M391.244 78.536C391.169 78.9311 391.276 79.3386 391.533 79.6476C391.79 79.9567 392.169 80.1357 392.572 80.1357"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2762_2"
                                        d="M391.49 82.2505C391.238 82.2505 391.001 82.3859 390.876 82.6054C390.749 82.8254 390.749 83.0958 390.876 83.3158C391.001 83.5353 391.238 83.6705 391.49 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <g id="carvaloo-logo_2" clipPath="url(#clip1_15_4484)">
                                        <path
                                            id="path9_2"
                                            d="M410.527 81.0815C410.555 80.9527 410.579 80.839 410.6 80.7388C410.6 80.7388 410.6 80.7388 410.6 80.7389L410.527 81.0813L410.527 81.0815ZM410.515 81.1367L410.46 81.3946C410.48 81.3028 410.498 81.217 410.515 81.1368L410.515 81.1367ZM413.842 83.0462L413.842 83.0462L413.842 83.0463L413.842 83.0462ZM409.984 83.7548L409.984 83.7548L409.984 83.7548ZM410.115 83.7548L410.115 83.7548L410.023 83.7548L410.023 83.7548L410.115 83.7548ZM409.997 83.7548L410.023 83.7548L409.997 83.7548ZM409.891 81.6957L409.942 81.589L409.891 81.6957Z"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <path
                            id="REAR_APRON_RIGHT"
                            d="M373.953 404.991L373.799 417.842L395.798 418.844C399.953 418.479 401.491 418.928 403.134 420.652C402.91 422.403 405.166 422.383 411.394 421.423C413.014 420.706 413.752 420.126 414.378 418.375C415.509 413.344 416.048 403.654 416.957 382.461C400.204 380.419 392.173 378.033 383.248 368.848C381.009 388.38 379.923 397.466 379.075 401.656C378.016 403.992 376.861 404.686 373.953 404.991Z"
                            fill={getColor('REAR_APRON_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_APRON_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="TRUNK_INC_REAR_WINDSCREEN"
                           onMouseEnter={() => handleMouseEnter('TRUNK_INC_REAR_WINDSCREEN')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M308.105 409.063C321.864 411.99 331.826 413.728 347.668 416.135C355.908 415.934 361.98 416.099 373.378 416.503L373.508 405.043C351.842 407.273 340.23 407.057 320.534 403.898C315.555 402.899 311.913 402.315 307.666 401.71C307.804 405.275 308.105 409.063 308.105 409.063Z"
                                fill={getColor('TRUNK_INC_REAR_WINDSCREEN')}
                            />
                            <path
                                d="M308.105 409.063C308.105 409.063 307.804 405.275 307.666 401.71M308.105 409.063C321.864 411.99 331.826 413.728 347.668 416.135C355.908 415.934 361.98 416.099 373.378 416.503L373.508 405.043C351.842 407.273 340.23 407.057 320.534 403.898C315.555 402.899 311.913 402.315 307.666 401.71M308.105 409.063L307.666 401.71"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                    </g>
                    <g id="LEFT_VIEW_SURFACES">
                        <path
                            id="SIDE_MIRROR_LEFT"
                            d="M98.8299 123.621C101.975 125.286 103.549 127.146 106.244 131.014C106.678 133.364 106.539 134.912 105.749 137.999C104.924 139.594 104.074 140.267 101.896 141.038C95.3547 141.962 93.0688 141.936 92.9245 140.342C92.4043 137.677 92.1872 135.507 91.9109 130.575C91.5887 127.325 91.5037 125.627 91.6786 123.025C95.0935 121.49 96.6286 121.021 96.6981 122.92C97.9 123.239 98.4496 123.403 98.8299 123.621Z"
                            fill={getColor('SIDE_MIRROR_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('SIDE_MIRROR_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="XXX_RIM_LEFT_REAR"
                            d="M99.9057 292.565L102.227 405.883C89.9322 406.385 82.9544 406.056 70.383 404.584C66.5047 404.552 65.2595 403.12 64.5299 398.48L61.0487 368.36C67.011 358.262 68.3584 351.564 66.6807 337.494C63.6426 323.68 59.0234 318.087 45.1797 312.252L44.6094 291.237C73.8924 294.227 89.1333 295.525 99.9057 292.565Z"
                            fill={getColor('XXX_RIM_LEFT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('XXX_RIM_LEFT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_LEFT_REAR"
                            d="M95.8941 200.942C97.9483 235.921 98.7552 255.966 99.8956 292.095C90.1997 295.021 76.1085 294.238 44.5892 290.777L42.1145 201.113C77.8142 200.157 92.8591 201.096 95.8941 200.942Z"
                            fill={getColor('DOOR_LEFT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_LEFT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_LEFT_FRONT"
                            d="M90.2185 120.368C92.915 150.867 94.1084 168.486 95.8755 200.501C89.6229 200.332 77.7904 200.375 42.098 200.665L39.9127 121.559C68.7888 118.672 79.8107 118.437 90.2185 120.368Z"
                            fill={getColor('DOOR_LEFT_FRONT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_LEFT_FRONT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="XXX_RIM_LEFT_FRONT"
                            d="M90.1786 119.903C75.8157 118.183 64.027 118.766 39.9023 121.09L39.7508 115.666C53.9553 111.701 59.448 107.252 64.6642 95.2022C67.6967 81.7838 67.2139 74.5138 61.0835 62.1577C63.0117 59.0962 65.1477 56.8969 70.9721 52.052C73.6744 56.084 76.9893 58.5533 82.8858 62.9557C85.9244 69.3545 90.7492 89.0314 89.8978 95.5652C89.2529 95.9475 88.866 96.9186 88.552 98.492L90.1786 119.903Z"
                            fill={getColor('XXX_RIM_LEFT_FRONT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('XXX_RIM_LEFT_FRONT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="REAR_PILLAR_LEFT"
                            d="M138.265 378.343L136.314 401.249C122.957 403.745 115.484 404.905 102.229 405.91L102.042 396.956C102.46 398.46 103.11 398.796 104.754 398.804C113.378 397.735 117.914 396.014 125.652 391.613C133.788 386.006 136.961 383.046 138.265 378.343Z"
                            fill={getColor('REAR_PILLAR_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_PILLAR_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="DOOR_WINDOW_LEFT_REAR"
                            d="M141.173 199.785C142.25 234.758 142.324 254.201 141.835 288.685L100.341 292.008C99.2597 256.253 98.3868 236.315 96.3415 200.956L141.173 199.785Z"
                            fill={getColor('DOOR_WINDOW_LEFT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('DOOR_WINDOW_LEFT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FIXED_WINDOW_LEFT_REAR"
                            d="M141.839 289.126C141.354 313.482 140.947 327.16 140.02 351.587L137.914 377.187C137.782 380.216 136.486 382.194 132.427 386.075C124.745 392.339 119.871 395.165 109.153 397.759C103.531 398.942 102.401 398.369 102.456 396.082L100.35 292.454L141.839 289.126Z"
                            fill={getColor('FIXED_WINDOW_LEFT_REAR')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FIXED_WINDOW_LEFT_REAR')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="DOOR_WINDOW_LEFT_FRONT" onMouseEnter={() => handleMouseEnter('DOOR_WINDOW_LEFT_FRONT')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M133.631 151.243C139.046 160.941 140.564 171.97 141.18 199.328L96.33 200.502L92.1938 138.868C92.4627 140.756 92.7676 141.412 93.6533 141.691C104.847 142.536 107.618 140.093 106.673 130.822C106.379 130.413 106.096 130.024 105.821 129.655C102.179 124.754 100.184 123.325 97.1684 122.914C96.9118 121.977 96.7352 121.56 96.2787 121.288C95.3099 121.107 94.2072 121.422 91.2696 122.711C91.1579 123.944 91.1572 124.632 91.1484 125.929L90.6456 119.903L88.8858 100.309C89.2133 95.4747 89.9582 94.3413 94.0122 97.5731C102.609 107.264 109.044 115.083 115.078 123.299C116.973 125.878 118.827 128.497 120.697 131.225C124.753 137.143 128.879 143.576 133.631 151.243Z"
                                fill={getColor('DOOR_WINDOW_LEFT_FRONT')}
                            />
                            <path
                                d="M90.6456 119.903L91.1484 125.929C91.1572 124.632 91.1579 123.944 91.2696 122.711C94.2072 121.422 95.3099 121.107 96.2787 121.288C96.7352 121.56 96.9118 121.977 97.1684 122.914C100.184 123.325 102.179 124.754 105.821 129.655M90.6456 119.903L88.8858 100.309C89.2133 95.4747 89.9582 94.3413 94.0122 97.5731C102.609 107.264 109.044 115.083 115.078 123.299M90.6456 119.903L115.078 123.299M115.078 123.299C116.973 125.878 118.827 128.497 120.697 131.225M120.697 131.225C124.753 137.143 128.879 143.576 133.631 151.243C139.046 160.941 140.564 171.97 141.18 199.328L96.33 200.502L92.1938 138.868C92.4627 140.756 92.7676 141.412 93.6533 141.691C104.847 142.536 107.618 140.093 106.673 130.822C106.379 130.413 106.096 130.024 105.821 129.655M120.697 131.225L105.821 129.655"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                        <path
                            id="REAR_SILL_LEFT"
                            d="M41.6649 201.108L44.7305 312.115C39.8678 310.723 36.4055 310.114 28.8825 309.898C28.3842 270.438 27.2999 244.687 26.0384 201.368L41.6649 201.108Z"
                            fill={getColor('REAR_SILL_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_SILL_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_SILL_LEFT"
                            d="M39.2961 115.802L41.6426 200.655L26.0291 200.901L23.4982 117.966C30.025 117.656 33.4579 117.144 39.2961 115.802Z"
                            fill={getColor('FRONT_SILL_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_SILL_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="FRONT_HEADLIGHT_LEFT" onMouseEnter={() => handleMouseEnter('FRONT_HEADLIGHT_LEFT')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M70.7737 51.6163L60.8421 61.7557C50.5728 50.4243 41.7349 48.1823 22.3809 48.9761C21.6933 26.7488 21.7867 16.4939 25.65 15.2368L40.5764 14.5386L40.3871 12.7949C44.6919 13.5914 46.9702 13.6307 50.7988 12.9388C52.114 14.1023 52.8375 14.8815 54.0963 16.5725L57.1874 23.7494C63.1842 33.2032 66.1014 39.425 70.7737 51.6163Z"
                                fill={getColor('FRONT_HEADLIGHT_LEFT')}
                            />
                            <path
                                d="M75.5046 30.3563C79.3479 40.1047 80.752 47.6331 82.7958 62.3261C74.7105 56.2395 72.0798 53.7956 71.1348 51.3475C66.6551 40.0501 64.0027 33.9381 58.2679 24.6187C56.2322 21.6648 56.7703 21.3625 59.005 22.3731C70.5072 26.1357 74.3726 27.9155 75.5046 30.3563Z"
                                fill={getColor('FRONT_HEADLIGHT_LEFT')}
                            />
                            <path
                                d="M72.722 19.8236C72.722 19.8236 74.0501 26.9893 74.8742 28.8242C73.5615 27.2387 67.3472 25.0416 58.1694 21.5782C57.0109 21.286 56.6293 21.43 56.6442 22.4872L54.0809 16.5385L51.2151 12.7502C51.0246 12.4041 51.7302 12.9258 53.8191 14.5861C54.3166 14.3312 54.906 14.4685 56.2952 15.0194C56.6524 14.299 56.867 13.9203 57.4017 13.5128C63.1357 13.8253 66.1062 14.5423 71.18 16.8799C71.9479 17.8006 72.3402 18.5381 72.722 19.8236Z"
                                fill={getColor('FRONT_HEADLIGHT_LEFT')}
                            />
                            <path
                                d="M70.7737 51.6163L60.8421 61.7557C50.5728 50.4243 41.7349 48.1823 22.3809 48.9761C21.6933 26.7488 21.7867 16.4939 25.65 15.2368L40.5764 14.5386L40.3871 12.7949C44.6919 13.5914 46.9702 13.6307 50.7988 12.9388C52.114 14.1023 52.8375 14.8815 54.0963 16.5725L57.1874 23.7494C63.1842 33.2032 66.1014 39.425 70.7737 51.6163Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                            <path
                                d="M75.5046 30.3563C79.3479 40.1047 80.752 47.6331 82.7958 62.3261C74.7105 56.2395 72.0798 53.7956 71.1348 51.3475C66.6551 40.0501 64.0027 33.9381 58.2679 24.6187C56.2322 21.6648 56.7703 21.3625 59.005 22.3731C70.5072 26.1357 74.3726 27.9155 75.5046 30.3563Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                            <path
                                d="M72.722 19.8236C72.722 19.8236 74.0501 26.9893 74.8742 28.8242C73.5615 27.2387 67.3472 25.0416 58.1694 21.5782C57.0109 21.286 56.6293 21.43 56.6442 22.4872L54.0809 16.5385L51.2151 12.7502C51.0246 12.4041 51.7302 12.9258 53.8191 14.5861C54.3166 14.3312 54.906 14.4685 56.2952 15.0194C56.6524 14.299 56.867 13.9203 57.4017 13.5128C63.1357 13.8253 66.1062 14.5423 71.18 16.8799C71.9479 17.8006 72.3402 18.5381 72.722 19.8236Z"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                        <g id="LEFT WHEELS">
                            <g id="REAR">
                                <g id="REAR_LEFT_WHEEL_SURFACES">
                                    <path
                                        id="TYRE_LEFT_REAR"
                                        d="M31.8954 316.905L31.8953 316.905C15.9378 316.906 3.00012 329.837 3.00012 345.792C3.00012 361.746 15.9378 374.677 31.8954 374.677C47.853 374.677 60.7907 361.745 60.7907 345.792C60.7907 329.837 47.851 316.905 31.8954 316.905Z"
                                        stroke={getColor('TYRE_LEFT_REAR')}
                                        strokeWidth="6"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                        onMouseEnter={() => handleMouseEnter('TYRE_LEFT_REAR')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <path
                                        id="RIM_LEFT_REAR"
                                        d="M31.8953 320.596C45.8138 320.596 57.1 331.876 57.1 345.792C57.1 359.706 45.8157 370.987 31.8953 370.987C17.9749 370.987 6.69055 359.706 6.69055 345.792C6.69055 331.876 17.9749 320.596 31.8953 320.596Z"
                                        fill={getColor('RIM_LEFT_REAR')}
                                        onMouseEnter={() => handleMouseEnter('RIM_LEFT_REAR')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </g>
                                <g id="REAR_LEFT_RIM_STROKES"
                                   onMouseEnter={() => handleMouseEnter('REAR_LEFT_RIM_STROKES')}
                                   onMouseLeave={handleMouseLeave}>
                                    <path
                                        id="path2190_3"
                                        d="M32.0201 334.271C31.8632 334.351 31.6768 334.351 31.5177 334.271"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2192_3"
                                        d="M31.5177 357.058C31.6768 356.978 31.8632 356.978 32.0201 357.058"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2196_3"
                                        d="M31.5928 352.274C31.2381 351.146 31.8678 349.945 32.9976 349.593C34.1252 349.24 35.3277 349.869 35.6801 350.997"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2198_3"
                                        d="M33.6365 350.124C34.4708 350.124 35.1483 350.801 35.1483 351.636C35.1483 352.471 34.4708 353.148 33.6365 353.148C32.8 353.148 32.1226 352.471 32.1226 351.636C32.1226 350.801 32.8 350.124 33.6365 350.124Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2202_3"
                                        d="M35.6799 340.331C35.4503 341.061 34.8502 341.614 34.1046 341.781C33.3589 341.949 32.5792 341.706 32.0609 341.143C31.5426 340.58 31.363 339.784 31.5926 339.054"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2204_3"
                                        d="M33.6365 338.181C34.4708 338.181 35.1483 338.858 35.1483 339.693C35.1483 340.528 34.4708 341.204 33.6365 341.204C32.8 341.204 32.1226 340.528 32.1226 339.693C32.1226 338.858 32.8 338.181 33.6365 338.181Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2208_3"
                                        d="M25.4596 347.645C26.4121 346.945 27.751 347.15 28.4535 348.102C29.1536 349.055 28.949 350.395 27.9943 351.095"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2210_3"
                                        d="M26.7258 347.858C27.5624 347.858 28.2398 348.535 28.2398 349.37C28.2398 350.205 27.5624 350.882 26.7258 350.882C25.8915 350.882 25.2141 350.205 25.2141 349.37C25.2141 348.535 25.8915 347.858 26.7258 347.858Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2214_3"
                                        d="M38.0283 347.805C36.8439 347.805 35.8868 346.847 35.8868 345.665C35.8868 344.482 36.8439 343.524 38.0283 343.524"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2216_3"
                                        d="M38.0284 344.153C38.8627 344.153 39.5401 344.829 39.5401 345.665C39.5401 346.499 38.8627 347.176 38.0284 347.176C37.1918 347.176 36.5144 346.499 36.5144 345.665C36.5144 344.829 37.1918 344.153 38.0284 344.153Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2220_3"
                                        d="M27.9943 340.234C28.6126 340.687 28.94 341.434 28.8558 342.195C28.7717 342.954 28.2875 343.612 27.5874 343.919C26.8872 344.226 26.0756 344.136 25.4596 343.683"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2222_3"
                                        d="M26.7258 340.447C27.5624 340.447 28.2398 341.123 28.2398 341.958C28.2398 342.793 27.5624 343.47 26.7258 343.47C25.8915 343.47 25.2141 342.794 25.2141 341.958C25.2141 341.123 25.8915 340.447 26.7258 340.447Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2224_3"
                                        d="M31.7703 324.311C32.4386 324.311 33.1092 324.343 33.7775 324.405"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2226_3"
                                        d="M33.7774 366.923C33.1091 366.986 32.4385 367.018 31.7701 367.018"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2228_3"
                                        d="M32.0203 357.058C32.2362 357.166 32.4022 357.353 32.484 357.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2230_3"
                                        d="M34.9072 365.178C35.0276 365.561 34.9708 365.978 34.7526 366.316C34.5343 366.653 34.1752 366.876 33.7773 366.923"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2232_3"
                                        d="M32.484 357.58C33.3478 360.095 34.1571 362.628 34.9073 365.178"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2234_3"
                                        d="M42.2271 327.045C43.5728 327.8 44.8322 328.699 45.9847 329.725"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2236_3"
                                        d="M32.484 333.748C32.4022 333.976 32.2362 334.162 32.0203 334.271"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2238_3"
                                        d="M33.7775 324.405C34.1753 324.453 34.5345 324.676 34.7527 325.013C34.9709 325.35 35.0278 325.767 34.9073 326.151"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2240_3"
                                        d="M34.9073 326.151C34.1571 328.701 33.3478 331.234 32.484 333.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2242_3"
                                        d="M38.6057 365.156C38.6807 365.398 38.6216 365.663 38.4511 365.849C38.2784 366.036 38.0192 366.116 37.7714 366.061C37.5259 366.005 37.3259 365.822 37.2509 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2244_3"
                                        d="M34.8982 358.057C34.7822 357.683 34.9891 357.284 35.3642 357.167C35.7393 357.05 36.1371 357.259 36.2553 357.633"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2246_3"
                                        d="M34.8981 358.057L37.2509 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2248_3"
                                        d="M36.2552 357.633L38.6058 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2270_3"
                                        d="M37.9669 355.868C37.8668 355.488 38.0123 355.086 38.3329 354.857C38.6511 354.629 39.0807 354.623 39.4058 354.841"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2272_3"
                                        d="M45.8619 359.527C46.1847 359.766 46.3848 360.135 46.4098 360.536C46.4325 360.938 46.278 361.329 45.9847 361.603"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2274_3"
                                        d="M39.406 354.841C41.5929 356.355 43.7434 357.918 45.862 359.527"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2276_3"
                                        d="M42.2272 364.284C41.8726 364.472 41.4498 364.492 41.0792 364.339C40.7064 364.187 40.4222 363.876 40.3018 363.493"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2278_3"
                                        d="M40.3016 363.493C39.4673 360.969 38.6898 358.427 37.9669 355.868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2280_3"
                                        d="M42.8091 350.215C42.5045 349.968 42.3817 349.558 42.5045 349.185C42.6272 348.811 42.9682 348.553 43.3615 348.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2282_3"
                                        d="M49.2083 354.975C47.0419 353.436 44.9073 351.849 42.8091 350.215"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2284_3"
                                        d="M48.8286 357.32C49.0332 357.47 49.14 357.719 49.1128 357.971C49.0855 358.223 48.9241 358.441 48.6922 358.543C48.4603 358.644 48.1921 358.615 47.9852 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2286_3"
                                        d="M38.5579 351.535C38.242 351.302 38.1738 350.858 38.4056 350.542C38.6375 350.226 39.0831 350.158 39.399 350.39"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2288_3"
                                        d="M38.558 351.535L47.9852 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2290_3"
                                        d="M39.3993 350.39L48.8287 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2298_3"
                                        d="M43.3615 348.535C46.0189 348.461 48.6786 348.446 51.336 348.489"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2300_3"
                                        d="M37.2509 325.749C37.3668 325.375 37.7646 325.166 38.1397 325.283C38.5148 325.4 38.7217 325.798 38.6057 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2302_3"
                                        d="M36.2553 333.696C36.178 333.937 35.9802 334.121 35.7324 334.177C35.4846 334.232 35.2255 334.151 35.055 333.965C34.8822 333.778 34.8231 333.514 34.8981 333.272"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2304_3"
                                        d="M34.8981 333.272L37.2509 325.749"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2306_3"
                                        d="M36.2552 333.696L38.6058 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2328_3"
                                        d="M45.9847 361.603C44.8322 362.63 43.5728 363.528 42.2271 364.284"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2330_3"
                                        d="M39.4058 336.487C39.0807 336.706 38.6511 336.7 38.3329 336.471C38.0123 336.243 37.8668 335.841 37.9669 335.461"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2332_3"
                                        d="M45.9847 329.725C46.278 330 46.4325 330.391 46.4098 330.792C46.3848 331.193 46.1847 331.563 45.8619 331.801"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2334_3"
                                        d="M45.862 331.801C43.7434 333.411 41.5929 334.973 39.406 336.487"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2336_3"
                                        d="M40.3018 327.836C40.4222 327.452 40.7064 327.142 41.0792 326.989C41.4498 326.836 41.8726 326.856 42.2272 327.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2338_3"
                                        d="M37.9669 335.461C38.6898 332.902 39.4673 330.36 40.3016 327.836"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2340_3"
                                        d="M43.3615 342.793C42.9682 342.776 42.6272 342.517 42.5045 342.144C42.3817 341.771 42.5045 341.36 42.8091 341.114"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2342_3"
                                        d="M42.8091 341.114C44.9073 339.48 47.0419 337.893 49.2083 336.353"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2344_3"
                                        d="M47.9852 332.864C48.3012 332.632 48.7468 332.7 48.9786 333.016C49.2105 333.332 49.1446 333.776 48.8286 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2346_3"
                                        d="M39.399 340.938C39.1944 341.088 38.9262 341.118 38.6943 341.016C38.4601 340.915 38.301 340.696 38.2715 340.444C38.2442 340.192 38.3533 339.944 38.5579 339.794"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2348_3"
                                        d="M38.558 339.794L47.9852 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2350_3"
                                        d="M39.3993 340.938L48.8287 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2358_3"
                                        d="M51.3383 342.839C48.6786 342.883 46.0189 342.868 43.3615 342.793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2360_3"
                                        d="M44.5346 346.374C44.1436 346.374 43.8253 346.057 43.8253 345.665C43.8253 345.272 44.1436 344.954 44.5346 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2362_3"
                                        d="M44.5345 346.374L52.4205 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2364_3"
                                        d="M44.5345 344.954L52.4205 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2366_3"
                                        d="M29.7629 324.405C30.429 324.343 31.0996 324.311 31.7702 324.311"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2368_3"
                                        d="M31.7702 367.018C31.0996 367.018 30.429 366.986 29.7629 366.923"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2370_3"
                                        d="M31.054 357.58C31.1358 357.353 31.3017 357.166 31.5177 357.058"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2372_3"
                                        d="M29.7629 366.923C29.3628 366.876 29.0059 366.653 28.7854 366.316C28.5671 365.978 28.5103 365.561 28.6308 365.178"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2374_3"
                                        d="M28.6307 365.178C29.3809 362.628 30.1902 360.095 31.054 357.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2376_3"
                                        d="M17.5531 329.725C18.7056 328.699 19.9673 327.8 21.3131 327.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2378_3"
                                        d="M31.5177 334.271C31.3017 334.162 31.1358 333.976 31.054 333.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2380_3"
                                        d="M28.6308 326.151C28.5103 325.767 28.5671 325.35 28.7854 325.013C29.0059 324.676 29.3628 324.453 29.7629 324.405"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2382_3"
                                        d="M31.054 333.748C30.1902 331.234 29.3809 328.701 28.6307 326.151"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2384_3"
                                        d="M26.2893 365.58C26.212 365.822 26.0143 366.005 25.7665 366.061C25.5187 366.116 25.2595 366.036 25.089 365.849C24.9163 365.663 24.8572 365.398 24.9322 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2386_3"
                                        d="M28.4238 353.99C28.5398 353.616 28.9376 353.407 29.3127 353.524C29.6877 353.641 29.8946 354.039 29.7787 354.413"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2388_3"
                                        d="M29.7789 354.413L26.2894 365.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2390_3"
                                        d="M28.4238 353.99L24.9321 365.156"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2412_3"
                                        d="M12.3109 354.474C11.6744 353.068 11.1925 351.598 10.8719 350.089"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2414_3"
                                        d="M24.132 354.841C24.4593 354.623 24.8867 354.629 25.2072 354.857C25.5255 355.086 25.6709 355.488 25.5709 355.868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2416_3"
                                        d="M17.5533 361.603C17.26 361.329 17.1054 360.938 17.1282 360.536C17.1532 360.135 17.3532 359.766 17.6783 359.527"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2418_3"
                                        d="M17.6781 359.527C19.7945 357.918 21.945 356.355 24.1319 354.841"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2420_3"
                                        d="M23.2363 363.493C23.1158 363.876 22.8316 364.187 22.4588 364.339C22.0883 364.492 21.6677 364.472 21.3131 364.284"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2422_3"
                                        d="M25.571 355.868C24.8481 358.427 24.0706 360.969 23.2363 363.493"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2424_3"
                                        d="M20.1766 348.536C20.5699 348.553 20.9109 348.811 21.0337 349.185C21.1564 349.558 21.0359 349.968 20.729 350.215"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2426_3"
                                        d="M14.3296 354.975C14.0045 355.213 13.593 355.295 13.202 355.198C12.811 355.101 12.486 354.836 12.3109 354.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2428_3"
                                        d="M20.7289 350.215C18.6307 351.849 16.4984 353.436 14.3297 354.975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2430_3"
                                        d="M15.5528 358.464C15.3482 358.615 15.0777 358.644 14.8458 358.543C14.614 358.441 14.4526 358.223 14.4253 357.971C14.398 357.719 14.5071 357.47 14.7117 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2432_3"
                                        d="M21.0632 352.652C21.3792 352.419 21.8248 352.487 22.0566 352.803C22.2885 353.119 22.2203 353.564 21.9043 353.796"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2434_3"
                                        d="M21.9042 353.796L15.5527 358.464"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2436_3"
                                        d="M21.0632 352.652L14.7117 357.32"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2458_3"
                                        d="M10.8719 350.089C10.7969 349.694 10.9037 349.287 11.1606 348.977C11.4174 348.668 11.7994 348.489 12.2017 348.489"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2460_3"
                                        d="M12.2017 348.489C14.8591 348.446 17.5188 348.461 20.1762 348.535"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2462_3"
                                        d="M24.9321 326.172C24.8162 325.798 25.023 325.4 25.3981 325.283C25.7732 325.166 26.171 325.375 26.2892 325.749"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2464_3"
                                        d="M29.7787 336.915C29.8537 337.157 29.7946 337.421 29.6241 337.608C29.4513 337.794 29.1922 337.875 28.9444 337.82C28.6966 337.764 28.4988 337.581 28.4238 337.339"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2466_3"
                                        d="M29.7789 336.915L26.2894 325.748"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2468_3"
                                        d="M28.4238 337.339L24.9321 326.172"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2490_3"
                                        d="M21.3131 364.284C19.9673 363.529 18.7056 362.63 17.5531 361.603"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2492_3"
                                        d="M10.8719 341.24C11.1925 339.731 11.6744 338.26 12.3109 336.855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2494_3"
                                        d="M25.5709 335.461C25.6709 335.841 25.5255 336.243 25.2072 336.471C24.8867 336.7 24.4593 336.706 24.132 336.487"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2496_3"
                                        d="M17.6783 331.801C17.3532 331.563 17.1532 331.193 17.1282 330.792C17.1054 330.391 17.26 330 17.5533 329.725"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2498_3"
                                        d="M24.1319 336.487C21.945 334.973 19.7945 333.411 17.6781 331.801"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2500_3"
                                        d="M21.3131 327.045C21.6677 326.856 22.0883 326.836 22.4588 326.989C22.8316 327.142 23.1158 327.452 23.2363 327.836"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2502_3"
                                        d="M23.2363 327.836C24.0706 330.36 24.8481 332.902 25.571 335.461"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2654_3"
                                        d="M20.729 341.114C21.0359 341.36 21.1564 341.77 21.0337 342.144C20.9109 342.517 20.5699 342.776 20.1766 342.793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2656_3"
                                        d="M12.311 336.855C12.4861 336.493 12.8112 336.228 13.2022 336.13C13.5932 336.033 14.0046 336.115 14.3297 336.353"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2658_3"
                                        d="M14.3297 336.353C16.4984 337.893 18.6307 339.48 20.7289 341.114"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2660_3"
                                        d="M14.7115 334.008C14.3955 333.776 14.3273 333.332 14.5592 333.016C14.7911 332.7 15.2366 332.632 15.5526 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2662_3"
                                        d="M21.9043 337.533C22.1089 337.683 22.218 337.931 22.1908 338.183C22.1612 338.435 22.0021 338.653 21.7702 338.755C21.5361 338.857 21.2678 338.827 21.0632 338.677"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2664_3"
                                        d="M21.9043 337.533L15.5529 332.864"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2666_3"
                                        d="M21.0632 338.677L14.7117 334.008"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2688_3"
                                        d="M12.2019 342.839C11.7995 342.839 11.4176 342.66 11.1607 342.351C10.9038 342.042 10.797 341.635 10.872 341.24"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2690_3"
                                        d="M20.1764 342.793C17.5189 342.868 14.8592 342.883 12.2018 342.839"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2692_3"
                                        d="M11.1176 346.374C10.7266 346.374 10.4083 346.057 10.4083 345.665C10.4083 345.272 10.7266 344.954 11.1176 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2694_3"
                                        d="M22.8225 344.954C23.0748 344.954 23.309 345.09 23.4363 345.309C23.5636 345.529 23.5636 345.8 23.4363 346.019C23.309 346.239 23.0748 346.374 22.8225 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2696_3"
                                        d="M22.8225 346.374L11.1176 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2698_3"
                                        d="M22.8225 344.954L11.1176 344.954"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2722_3"
                                        d="M51.2268 354.474C51.054 354.836 50.7267 355.101 50.3357 355.198C49.947 355.295 49.5332 355.213 49.2081 354.975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2728_3"
                                        d="M49.2081 336.354C49.5332 336.115 49.947 336.034 50.3357 336.13C50.7267 336.228 51.054 336.493 51.2268 336.855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2734_3"
                                        d="M52.6658 350.089C52.3475 351.598 51.8633 353.068 51.2268 354.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2746_3"
                                        d="M51.3383 348.489C51.7406 348.489 52.1203 348.668 52.3771 348.977C52.634 349.287 52.7409 349.694 52.6658 350.089"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2748_3"
                                        d="M51.2268 336.855C51.8633 338.26 52.3475 339.731 52.6658 341.24"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2760_3"
                                        d="M52.6658 341.24C52.7409 341.635 52.634 342.042 52.3771 342.351C52.1203 342.66 51.7406 342.839 51.3383 342.839"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2762_3"
                                        d="M52.42 344.954C52.6724 344.954 52.9088 345.09 53.0338 345.309C53.1611 345.529 53.1611 345.8 53.0338 346.02C52.9088 346.239 52.6724 346.374 52.42 346.374"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <g id="carvaloo-logo_3" clipPath="url(#clip2_15_4484)">
                                        <path
                                            id="path9_3"
                                            d="M33.3831 343.785C33.3556 343.656 33.3313 343.543 33.3099 343.442C33.3099 343.443 33.3098 343.443 33.3098 343.443L33.3831 343.785L33.3831 343.785ZM33.3949 343.84L33.4501 344.098C33.4304 344.006 33.4121 343.921 33.3949 343.84L33.3949 343.84ZM30.0683 345.75L30.0683 345.75L30.0683 345.75L30.0683 345.75ZM33.9265 346.458L33.9265 346.458L33.9265 346.458ZM33.795 346.458L33.795 346.458L33.8871 346.458L33.8871 346.458L33.795 346.458ZM33.9135 346.458L33.8872 346.458L33.9135 346.458ZM34.0189 344.399L33.9681 344.293L34.0189 344.399Z"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g id="FRONT">
                                <g id="FRONT_LEFT_WHEEL_SURFACES">
                                    <path
                                        id="TYRE_LEFT_FRONT"
                                        d="M31.8953 54.2017L31.8952 54.2017C15.9377 54.2022 3 67.1336 3 83.0879C3 99.0419 15.9377 111.973 31.8953 111.973C47.8529 111.973 60.7905 99.0417 60.7905 83.0879C60.7905 67.1331 47.8508 54.2017 31.8953 54.2017Z"
                                        stroke={getColor('TYRE_LEFT_FRONT')}
                                        strokeWidth="6"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                        onMouseEnter={() => handleMouseEnter('TYRE_LEFT_FRONT')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <path
                                        id="RIM_LEFT_FRONT"
                                        d="M31.8952 57.892C45.8137 57.892 57.0999 69.1724 57.0999 83.0878C57.0999 97.0025 45.8155 108.283 31.8952 108.283C17.9748 108.283 6.69043 97.0027 6.69043 83.0878C6.69043 69.1727 17.9748 57.8924 31.8952 57.892Z"
                                        fill={getColor('RIM_LEFT_FRONT')}
                                        onMouseEnter={() => handleMouseEnter('RIM_LEFT_FRONT')}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                </g>
                                <g id="FRONT_LEFT_RIM_STROKES"
                                   onMouseEnter={() => handleMouseEnter('FRONT_LEFT_RIM_STROKES')}
                                   onMouseLeave={handleMouseLeave}>
                                    <path
                                        id="path2190_4"
                                        d="M32.0201 71.5671C31.8632 71.6469 31.6768 71.6469 31.5177 71.5671"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2192_4"
                                        d="M31.5177 94.3541C31.6768 94.2748 31.8632 94.2748 32.0201 94.3541"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2196_4"
                                        d="M31.5928 89.5707C31.2381 88.4422 31.8678 87.2414 32.9976 86.889C34.1252 86.5363 35.3277 87.1651 35.6801 88.2936"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2198_4"
                                        d="M33.6365 87.4205C34.4708 87.4205 35.1483 88.0972 35.1483 88.9321C35.1483 89.767 34.4708 90.444 33.6365 90.444C32.8 90.444 32.1226 89.767 32.1226 88.9321C32.1226 88.0972 32.8 87.4205 33.6365 87.4205Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2202_4"
                                        d="M35.6799 77.6277C35.4503 78.3574 34.8502 78.9103 34.1046 79.0778C33.3589 79.2455 32.5792 79.0019 32.0609 78.4395C31.5426 77.8766 31.363 77.0808 31.5926 76.3506"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2204_4"
                                        d="M33.6365 75.4773C34.4708 75.4773 35.1483 76.1542 35.1483 76.9891C35.1483 77.824 34.4708 78.5007 33.6365 78.5007C32.8 78.5007 32.1226 77.824 32.1226 76.9891C32.1226 76.1542 32.8 75.4773 33.6365 75.4773Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2208_4"
                                        d="M25.4595 84.9417C26.412 84.2414 27.7509 84.4459 28.4534 85.3987C29.1535 86.3511 28.9489 87.6909 27.9942 88.3911"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2210_4"
                                        d="M26.7257 85.1544C27.5623 85.1544 28.2397 85.8318 28.2397 86.6667C28.2397 87.5012 27.5623 88.1783 26.7257 88.1783C25.8914 88.1783 25.214 87.5012 25.214 86.6667C25.214 85.8318 25.8914 85.1544 26.7257 85.1544Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2214_4"
                                        d="M38.0281 85.1013C36.8438 85.1013 35.8867 84.1433 35.8867 82.9609C35.8867 81.7786 36.8438 80.8201 38.0281 80.8201"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2216_4"
                                        d="M38.0283 81.449C38.8625 81.449 39.54 82.1257 39.54 82.9609C39.54 83.7955 38.8625 84.4725 38.0283 84.4725C37.1917 84.4725 36.5143 83.7955 36.5143 82.9609C36.5143 82.1257 37.1917 81.449 38.0283 81.449Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2220_4"
                                        d="M27.9942 77.53C28.6125 77.9829 28.9398 78.7303 28.8557 79.4909C28.7716 80.2508 28.2874 80.9079 27.5873 81.2152C26.8871 81.5222 26.0755 81.4324 25.4595 80.9793"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2222_4"
                                        d="M26.7257 77.7432C27.5623 77.7432 28.2397 78.4199 28.2397 79.2546C28.2397 80.0895 27.5623 80.7662 26.7257 80.7662C25.8914 80.7662 25.214 80.0899 25.214 79.2546C25.214 78.4199 25.8914 77.7432 26.7257 77.7432Z"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2224_4"
                                        d="M31.7701 61.6073C32.4385 61.6073 33.1091 61.6392 33.7774 61.7017"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2226_4"
                                        d="M33.7774 104.219C33.1091 104.283 32.4385 104.314 31.7701 104.314"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2228_4"
                                        d="M32.02 94.3542C32.236 94.4625 32.4019 94.6496 32.4838 94.8766"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2230_4"
                                        d="M34.907 102.474C35.0275 102.857 34.9707 103.275 34.7525 103.612C34.5342 103.949 34.175 104.172 33.7772 104.219"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2232_4"
                                        d="M32.4839 94.8766C33.3477 97.391 34.157 99.9241 34.9072 102.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2234_4"
                                        d="M42.2269 64.3411C43.5727 65.0965 44.8321 65.9952 45.9846 67.0217"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2236_4"
                                        d="M32.4838 71.0445C32.4019 71.272 32.236 71.4587 32.02 71.5671"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2238_4"
                                        d="M33.7773 61.7017C34.1752 61.7494 34.5343 61.9721 34.7526 62.3091C34.9708 62.6466 35.0276 63.0636 34.9072 63.4471"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2240_4"
                                        d="M34.9072 63.4471C34.157 65.9972 33.3477 68.5301 32.4839 71.0445"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2242_4"
                                        d="M38.6056 102.453C38.6806 102.695 38.6215 102.959 38.451 103.146C38.2782 103.332 38.0191 103.413 37.7713 103.357C37.5258 103.301 37.3257 103.118 37.2507 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2244_4"
                                        d="M34.898 95.3531C34.7821 94.979 34.989 94.5807 35.364 94.4637C35.7391 94.3464 36.137 94.5552 36.2552 94.9297"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2246_4"
                                        d="M34.8981 95.3531L37.2509 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2248_4"
                                        d="M36.2551 94.9297L38.6057 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2270_4"
                                        d="M37.9667 93.1641C37.8667 92.7846 38.0122 92.3819 38.3327 92.1535C38.651 91.9254 39.0806 91.919 39.4057 92.1376"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2272_4"
                                        d="M45.8619 96.8238C46.1847 97.0619 46.3848 97.4317 46.4098 97.8328C46.4325 98.2338 46.278 98.6251 45.9847 98.8997"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2274_4"
                                        d="M39.4059 92.1376C41.5928 93.6517 43.7433 95.2145 45.8619 96.8238"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2276_4"
                                        d="M42.2271 101.58C41.8725 101.769 41.4496 101.789 41.0791 101.636C40.7063 101.483 40.4221 101.173 40.3016 100.789"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2278_4"
                                        d="M40.3016 100.789C39.4673 98.265 38.6898 95.7228 37.9669 93.164"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2280_4"
                                        d="M42.809 87.5111C42.5043 87.2646 42.3816 86.8546 42.5044 86.481C42.6271 86.1077 42.9681 85.8488 43.3614 85.8318"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2282_4"
                                        d="M49.2083 92.2714C47.0419 90.7321 44.9073 89.1452 42.8091 87.5111"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2284_4"
                                        d="M48.8286 94.6166C49.0332 94.7668 49.14 95.015 49.1128 95.2672C49.0855 95.5192 48.9241 95.7372 48.6922 95.8392C48.4603 95.9408 48.1921 95.9112 47.9852 95.7606"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2286_4"
                                        d="M38.5578 88.831C38.2418 88.5988 38.1736 88.154 38.4055 87.8386C38.6374 87.5223 39.0829 87.4546 39.3989 87.6868"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2288_4"
                                        d="M38.558 88.831L47.9852 95.7605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2290_4"
                                        d="M39.3992 87.6868L48.8286 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2298_4"
                                        d="M43.3613 85.8318C46.0188 85.7575 48.6785 85.7421 51.3359 85.7855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2300_4"
                                        d="M37.2507 63.045C37.3667 62.6709 37.7645 62.4621 38.1396 62.5793C38.5147 62.6964 38.7215 63.0943 38.6056 63.4687"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2302_4"
                                        d="M36.2552 70.992C36.1779 71.2338 35.9801 71.417 35.7323 71.4729C35.4845 71.5281 35.2254 71.4477 35.0549 71.2609C34.8821 71.0743 34.823 70.8105 34.898 70.5682"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2304_4"
                                        d="M34.8981 70.5682L37.2509 63.0449"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2306_4"
                                        d="M36.2551 70.992L38.6057 63.4687"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2328_4"
                                        d="M45.9846 98.8996C44.8321 99.9261 43.5727 100.825 42.2269 101.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2330_4"
                                        d="M39.4057 73.7834C39.0806 74.0022 38.651 73.9959 38.3327 73.7675C38.0122 73.5393 37.8667 73.1369 37.9667 72.7569"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2332_4"
                                        d="M45.9847 67.0216C46.278 67.2961 46.4325 67.6872 46.4098 68.0885C46.3848 68.4896 46.1847 68.8591 45.8619 69.0975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2334_4"
                                        d="M45.8619 69.0975C43.7433 70.707 41.5928 72.2696 39.4059 73.7834"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2336_4"
                                        d="M40.3016 65.1323C40.4221 64.7485 40.7063 64.4383 41.0791 64.2854C41.4496 64.1325 41.8725 64.1527 42.2271 64.3411"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2338_4"
                                        d="M37.9669 72.757C38.6898 70.1985 39.4673 67.6561 40.3016 65.1323"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2340_4"
                                        d="M43.3614 80.0895C42.9681 80.072 42.6271 79.8138 42.5044 79.4402C42.3816 79.0669 42.5043 78.6567 42.809 78.4102"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2342_4"
                                        d="M42.809 78.4102C44.9072 76.7765 47.0418 75.189 49.2082 73.6499"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2344_4"
                                        d="M47.9852 70.1605C48.3012 69.9285 48.7468 69.996 48.9786 70.3121C49.2105 70.628 49.1446 71.072 48.8286 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2346_4"
                                        d="M39.399 78.2345C39.1944 78.3847 38.9262 78.4144 38.6943 78.3124C38.4601 78.211 38.301 77.9927 38.2715 77.7404C38.2442 77.4882 38.3533 77.2405 38.5579 77.0903"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2348_4"
                                        d="M38.558 77.0903L47.9852 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2350_4"
                                        d="M39.3992 78.2345L48.8286 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2358_4"
                                        d="M51.3382 80.1358C48.6785 80.1793 46.0188 80.164 43.3613 80.0895"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2360_4"
                                        d="M44.5345 83.6705C44.1435 83.6705 43.8252 83.3528 43.8252 82.9609C43.8252 82.5684 44.1435 82.2505 44.5345 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2362_4"
                                        d="M44.5345 83.6705L52.4205 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2364_4"
                                        d="M44.5345 82.2505L52.4205 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2366_4"
                                        d="M29.7628 61.7017C30.4289 61.6392 31.0995 61.6073 31.7701 61.6073"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2368_4"
                                        d="M31.7701 104.314C31.0995 104.314 30.4289 104.283 29.7628 104.219"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2370_4"
                                        d="M31.0538 94.8766C31.1357 94.6496 31.3016 94.4625 31.5176 94.3542"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2372_4"
                                        d="M29.7627 104.219C29.3626 104.172 29.0057 103.949 28.7852 103.612C28.567 103.275 28.5102 102.857 28.6306 102.474"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2374_4"
                                        d="M28.6306 102.474C29.3808 99.9242 30.1901 97.3911 31.0539 94.8767"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2376_4"
                                        d="M17.5531 67.0217C18.7056 65.9952 19.9673 65.0965 21.3131 64.3411"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2378_4"
                                        d="M31.5176 71.5671C31.3016 71.4587 31.1357 71.272 31.0538 71.0445"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2380_4"
                                        d="M28.6306 63.4471C28.5102 63.0636 28.567 62.6466 28.7852 62.3091C29.0057 61.9721 29.3626 61.7494 29.7627 61.7017"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2382_4"
                                        d="M31.0539 71.0445C30.1901 68.5301 29.3808 65.9972 28.6306 63.4471"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2384_4"
                                        d="M26.2892 102.876C26.2119 103.118 26.0141 103.301 25.7663 103.357C25.5186 103.413 25.2594 103.332 25.0889 103.146C24.9162 102.959 24.8571 102.695 24.9321 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2386_4"
                                        d="M28.4237 91.2864C28.5396 90.9123 28.9375 90.7035 29.3125 90.8203C29.6876 90.9373 29.8945 91.3357 29.7786 91.7097"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2388_4"
                                        d="M29.7788 91.7097L26.2893 102.876"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2390_4"
                                        d="M28.4238 91.2863L24.9321 102.453"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2412_4"
                                        d="M12.3109 91.7699C11.6744 90.3644 11.1925 88.8946 10.8719 87.3852"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2414_4"
                                        d="M24.1317 92.1376C24.4591 91.919 24.8864 91.9254 25.207 92.1535C25.5252 92.3819 25.6707 92.7846 25.5707 93.1641"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2416_4"
                                        d="M17.553 98.8997C17.2598 98.6251 17.1052 98.2338 17.1279 97.8328C17.1529 97.4317 17.353 97.0619 17.6781 96.8238"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2418_4"
                                        d="M17.678 96.8238C19.7944 95.2145 21.9449 93.6517 24.1318 92.1376"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2420_4"
                                        d="M23.2362 100.789C23.1157 101.173 22.8315 101.483 22.4587 101.636C22.0882 101.789 21.6676 101.769 21.313 101.58"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2422_4"
                                        d="M25.5708 93.164C24.8479 95.7228 24.0705 98.265 23.2362 100.789"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2424_4"
                                        d="M20.1765 85.8318C20.5698 85.8488 20.9108 86.1077 21.0335 86.481C21.1563 86.8546 21.0358 87.2646 20.7289 87.5111"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2426_4"
                                        d="M14.3296 92.2715C14.0045 92.5098 13.593 92.5914 13.202 92.4944C12.811 92.3973 12.486 92.1322 12.3109 91.7699"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2428_4"
                                        d="M20.7288 87.5111C18.6306 89.1452 16.4983 90.7321 14.3296 92.2714"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2430_4"
                                        d="M15.5527 95.7606C15.3481 95.9112 15.0776 95.9408 14.8457 95.8392C14.6138 95.7372 14.4524 95.5192 14.4252 95.2672C14.3979 95.015 14.507 94.7668 14.7116 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2432_4"
                                        d="M21.0631 89.9479C21.3791 89.7157 21.8247 89.7834 22.0565 90.0993C22.2884 90.4151 22.2202 90.8599 21.9042 91.0921"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2434_4"
                                        d="M21.9041 91.092L15.5526 95.7605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2436_4"
                                        d="M21.063 89.9479L14.7115 94.6166"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2458_4"
                                        d="M10.8719 87.3853C10.7969 86.9901 10.9037 86.5829 11.1606 86.2734C11.4174 85.9643 11.7994 85.7855 12.2017 85.7855"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2460_4"
                                        d="M12.2015 85.7855C14.859 85.7421 17.5187 85.7575 20.1761 85.8318"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2462_4"
                                        d="M24.932 63.4687C24.816 63.0943 25.0229 62.6964 25.398 62.5793C25.7731 62.4621 26.1709 62.6709 26.2891 63.045"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2464_4"
                                        d="M29.7786 74.2116C29.8536 74.4536 29.7945 74.7179 29.624 74.9042C29.4512 75.0905 29.1921 75.1714 28.9443 75.116C28.6965 75.0603 28.4987 74.8769 28.4237 74.6349"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2466_4"
                                        d="M29.7788 74.2115L26.2893 63.0449"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2468_4"
                                        d="M28.4238 74.6349L24.9321 63.4686"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2490_4"
                                        d="M21.313 101.58C19.9672 100.825 18.7055 99.9261 17.553 98.8996"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2492_4"
                                        d="M10.8719 78.536C11.1925 77.0269 11.6744 75.5566 12.3109 74.1514"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2494_4"
                                        d="M25.5707 72.7569C25.6707 73.1369 25.5252 73.5393 25.207 73.7675C24.8864 73.9959 24.4591 74.0022 24.1317 73.7834"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2496_4"
                                        d="M17.6783 69.0975C17.3532 68.8591 17.1532 68.4896 17.1282 68.0885C17.1054 67.6872 17.26 67.2961 17.5533 67.0216"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2498_4"
                                        d="M24.1318 73.7834C21.9449 72.2696 19.7944 70.707 17.678 69.0975"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2500_4"
                                        d="M21.313 64.3411C21.6676 64.1527 22.0882 64.1325 22.4587 64.2854C22.8315 64.4383 23.1157 64.7485 23.2362 65.1323"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2502_4"
                                        d="M23.2362 65.1323C24.0705 67.6561 24.8479 70.1985 25.5708 72.757"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2654_4"
                                        d="M20.7289 78.4102C21.0358 78.6567 21.1563 79.0669 21.0335 79.4402C20.9108 79.8138 20.5698 80.072 20.1765 80.0895"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2656_4"
                                        d="M12.311 74.1514C12.4861 73.7896 12.8112 73.5239 13.2022 73.4267C13.5932 73.3299 14.0046 73.4115 14.3297 73.6498"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2658_4"
                                        d="M14.3296 73.6499C16.4983 75.189 18.6306 76.7765 20.7288 78.4102"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2660_4"
                                        d="M14.7114 71.3046C14.3954 71.0719 14.3272 70.6279 14.5591 70.312C14.7909 69.9959 15.2365 69.9285 15.5525 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2662_4"
                                        d="M21.9042 74.8292C22.1088 74.9794 22.2179 75.2273 22.1906 75.4798C22.1611 75.7316 22.002 75.9497 21.7701 76.0515C21.5359 76.1533 21.2677 76.1236 21.0631 75.9733"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2664_4"
                                        d="M21.9042 74.8292L15.5527 70.1605"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2666_4"
                                        d="M21.063 75.9734L14.7115 71.3047"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2688_4"
                                        d="M12.2017 80.1357C11.7994 80.1357 11.4174 79.9567 11.1606 79.6476C10.9037 79.3386 10.7969 78.9311 10.8719 78.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2690_4"
                                        d="M20.1762 80.0894C17.5188 80.164 14.8591 80.1792 12.2017 80.1358"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2692_4"
                                        d="M11.1175 83.6705C10.7265 83.6705 10.4082 83.3528 10.4082 82.9609C10.4082 82.5684 10.7265 82.2505 11.1175 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2694_4"
                                        d="M22.8225 82.2505C23.0748 82.2505 23.309 82.3859 23.4363 82.6054C23.5636 82.8254 23.5636 83.0958 23.4363 83.3158C23.309 83.5353 23.0748 83.6705 22.8225 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2696_4"
                                        d="M22.8224 83.6705L11.1174 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2698_4"
                                        d="M22.8224 82.2505L11.1174 82.2505"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2722_4"
                                        d="M51.2267 91.7699C51.0539 92.1322 50.7266 92.3973 50.3356 92.4944C49.9468 92.5914 49.5331 92.5098 49.208 92.2715"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2728_4"
                                        d="M49.208 73.6498C49.5331 73.4115 49.9468 73.3299 50.3356 73.4267C50.7266 73.5239 51.0539 73.7891 51.2267 74.1514"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2734_4"
                                        d="M52.6657 87.3852C52.3474 88.8946 51.8632 90.3644 51.2267 91.7699"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2746_4"
                                        d="M51.3383 85.7855C51.7406 85.7855 52.1203 85.9643 52.3771 86.2734C52.634 86.5829 52.7409 86.9901 52.6658 87.3853"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2748_4"
                                        d="M51.2267 74.1514C51.8632 75.5566 52.3474 77.0269 52.6657 78.536"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2760_4"
                                        d="M52.6658 78.536C52.7409 78.9311 52.634 79.3386 52.3771 79.6476C52.1203 79.9567 51.7406 80.1357 51.3383 80.1357"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <path
                                        id="path2762_4"
                                        d="M52.4199 82.2505C52.6723 82.2505 52.9087 82.3859 53.0337 82.6054C53.161 82.8254 53.161 83.0958 53.0337 83.3158C52.9087 83.5353 52.6723 83.6705 52.4199 83.6705"
                                        stroke="white"
                                        strokeMiterlimit="2.61313"
                                        strokeLinejoin="bevel"
                                    />
                                    <g id="carvaloo-logo_4" clipPath="url(#clip3_15_4484)">
                                        <path
                                            id="path9_4"
                                            d="M33.383 81.0815C33.3554 80.9527 33.3312 80.839 33.3098 80.7388C33.3097 80.7388 33.3097 80.7388 33.3097 80.7389L33.3829 81.0813L33.383 81.0815ZM33.3948 81.1367L33.4499 81.3946C33.4303 81.3028 33.412 81.217 33.3948 81.1368L33.3948 81.1367ZM30.0682 83.0462L30.0682 83.0462L30.0682 83.0463L30.0682 83.0462ZM33.9263 83.7548L33.9263 83.7548L33.9263 83.7548ZM33.7948 83.7548L33.7948 83.7548L33.887 83.7548L33.887 83.7548L33.7948 83.7548ZM33.9133 83.7548L33.8871 83.7548L33.9133 83.7548ZM34.0187 81.6957L33.968 81.589L34.0187 81.6957Z"
                                            stroke="white"
                                            strokeWidth="3"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                        <path
                            id="REAR_APRON_LEFT"
                            d="M69.9568 404.991L70.1112 417.842L48.1118 418.844C43.9573 418.479 42.4186 418.928 40.7762 420.652C41 422.403 38.7441 422.383 32.5163 421.423C30.8958 420.706 30.1583 420.126 29.5316 418.375C28.401 413.344 27.8617 403.654 26.953 382.461C43.7059 380.419 51.7373 378.033 60.6621 368.848C62.9004 388.38 63.9865 397.466 64.8353 401.656C65.894 403.992 67.0486 404.686 69.9568 404.991Z"
                            fill={getColor('REAR_APRON_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_APRON_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <g id="TRUNK_INC_REAR_WINDSCREEN"
                           onMouseEnter={() => handleMouseEnter('TRUNK_INC_REAR_WINDSCREEN')}
                           onMouseLeave={handleMouseLeave}>
                            <path
                                d="M135.805 409.063C122.046 411.99 112.084 413.728 96.2421 416.135C88.0025 415.934 81.9305 416.099 70.5325 416.503L70.4019 405.043C92.0677 407.273 103.681 407.057 123.376 403.898C128.355 402.899 131.997 402.315 136.244 401.71C136.106 405.275 135.805 409.063 135.805 409.063Z"
                                fill={getColor('TRUNK_INC_REAR_WINDSCREEN')}
                            />
                            <path
                                d="M135.805 409.063C135.805 409.063 136.106 405.275 136.244 401.71M135.805 409.063C122.046 411.99 112.084 413.728 96.2421 416.135C88.0025 415.934 81.9305 416.099 70.5325 416.503L70.4019 405.043C92.0677 407.273 103.681 407.057 123.376 403.898C128.355 402.899 131.997 402.315 136.244 401.71M135.805 409.063L136.244 401.71"
                                stroke="white"
                                strokeWidth="0.7"
                            />
                        </g>
                    </g>
                    <g id="TOP_VIEW_SURFACES">
                        <path
                            id="WINDSCREEN"
                            d="M282.297 89.9352C280.931 112.023 279.788 124.682 277.035 147.783C233.802 141.789 209.417 141.878 165.679 147.782C162.672 123.095 161.45 110.245 160.507 89.9657C181.062 80.2271 194.178 76.4109 221.001 73.5367C247.357 75.7884 260.62 79.7688 282.297 89.9352Z"
                            fill={getColor('WINDSCREEN')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('WINDSCREEN')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_PILLAR_LEFT"
                            d="M155.331 105.243C158.244 128.475 159.397 138.471 160.496 149.382C162.352 148.902 163.394 148.656 165.284 148.228C162.437 123.024 161.171 109.869 159.965 89.928L156.581 87.6948C155.556 94.3545 155.28 98.1626 155.331 105.243Z"
                            fill={getColor('FRONT_PILLAR_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_PILLAR_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_PILLAR_RIGHT"
                            d="M287.388 105.273C284.475 128.505 283.33 138.209 282.231 149.12L277.449 148.125C280.296 122.921 281.561 109.852 282.766 89.9109L286.151 87.6777C287.176 94.3374 287.439 98.1928 287.388 105.273Z"
                            fill={getColor('FRONT_PILLAR_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_PILLAR_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="ENGINE_HOOD"
                            d="M286.065 87.2124L282.519 89.5312C259.397 79.1148 245.937 75.5728 221.074 73.2134C195.365 75.8764 182.043 79.4583 160.205 89.5262L156.665 87.203C156.662 86.1918 155.999 84.5539 153.941 80.2189C153.251 79.6329 153.606 73.6268 154.215 63.0901C155.44 55.5429 156.271 51.685 158.199 45.9484C162.059 38.6991 165.847 34.7488 175.443 27.9024L180.937 24.9978C195.129 21.2931 203.769 20.1775 220.445 19.9475C236.955 19.786 245.835 20.7317 260.818 24.7806C263.339 25.3665 264.674 25.8889 266.926 27.1371C274.942 33.6569 278.387 36.847 281.702 41.2801C285.352 46.5385 286.842 51.355 288.459 63.4361L289.122 77.8979C289.164 79.0972 288.951 79.8667 288.285 81.2909C287.022 83.7453 286.503 85.0132 286.065 87.2124Z"
                            fill={getColor('ENGINE_HOOD')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('ENGINE_HOOD')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="TRUNK_INC_REAR_WINDSCREEN_3"
                            d="M275.936 388.693C280.802 388.884 282.817 401.41 285.106 403.565C286.852 404.892 287.898 405.515 289.902 406.382C290.086 409.009 289.549 410.525 287.396 413.311C286.086 414.636 284.289 415.217 279.888 416.072C257.006 416.602 244.177 416.461 221.296 416.357C198.722 416.462 186.118 416.565 162.831 416.057C158.165 415.147 156.459 414.556 155.361 413.324C153.333 410.883 152.637 409.386 152.831 406.39C155.305 405.237 156.502 404.484 158.258 402.937C159.813 401.347 161.515 389.095 166.574 388.704L275.936 388.693Z"
                            fill={getColor('TRUNK_INC_REAR_WINDSCREEN')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('TRUNK_INC_REAR_WINDSCREEN_3')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="REAR_APRON_LEFT_2"
                            d="M190.032 416.809L190.032 421.915C176.245 421.986 177.904 421.896 165.47 421.135C157.812 420.322 155.019 418.873 153.306 414.127C152.138 411.649 151.655 409.796 150.968 406.027C151.461 406.412 151.765 406.504 152.34 406.483C153.365 413.891 155.905 415.695 162.826 416.057L168.401 416.168C176.613 416.818 175.666 416.813 190.032 416.809Z"
                            fill={getColor('REAR_APRON_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_APRON_LEFT_2')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="REAR_APRON_RIGHT_2"
                            d="M252.091 416.804L252.078 421.915C271.71 421.798 274.209 421.496 277.918 421.051C285.577 420.238 287.711 418.858 289.423 414.112C290.592 411.634 291.077 409.797 291.765 406.028C291.271 406.413 290.944 406.511 290.369 406.491C289.495 413.803 286.945 415.617 279.875 416.076L275.001 416.158C266.79 416.809 266.457 416.807 252.091 416.804Z"
                            fill={getColor('REAR_APRON_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_APRON_RIGHT_2')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_HEADLIGHT_LEFT"
                            d="M148.993 50.2799L151.985 52.0109C151.61 56.6096 152.551 58.9551 153.973 62.2896C155.941 45.9077 160.46 38.584 175.435 27.8997L178.632 26.2226L178.497 15.9274C174.144 16.3336 170.882 19.2247 164.76 25.5533C159.961 27.5604 157.772 29.8355 155.059 34.3202C152.199 39.0866 150.938 42.4858 148.993 50.2799Z"
                            fill={getColor('FRONT_HEADLIGHT_LEFT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_HEADLIGHT_LEFT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_HEADLIGHT_RIGHT"
                            d="M293.802 50.4566L290.277 52.1839C290.652 56.7826 290.194 58.89 288.772 62.2246C286.803 45.8426 282.702 38.415 267.728 27.7307C267.048 27.148 266.183 26.6929 264.146 25.7493L264.272 15.9072C268.567 16.3757 271.9 19.3609 278.065 25.6442C282.864 27.6513 284.973 29.7704 287.686 34.2551C290.546 39.0216 291.857 42.6626 293.802 50.4566Z"
                            fill={getColor('FRONT_HEADLIGHT_RIGHT')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_HEADLIGHT_RIGHT')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="FRONT_APRON"
                            d="M264.282 15.9323L264.156 25.7805C250.907 20.429 240.697 19.5956 221.152 19.1494C199.28 19.506 189.953 21.0413 178.623 26.2048L178.507 15.9172C211.951 11.5531 230.716 11.5603 264.282 15.9323Z"
                            fill={getColor('FRONT_APRON')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('FRONT_APRON')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="REAR_APRON_MID"
                            d="M252.091 416.809L252.081 421.915L190.032 421.915L190.037 416.804L252.091 416.809Z"
                            fill={getColor('REAR_APRON_MID')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('REAR_APRON_MID')}
                            onMouseLeave={handleMouseLeave}
                        />
                        <path
                            id="ROOF"
                            d="M282.233 149.116C281.443 157.583 281.489 172.808 281.529 199.394C282.18 234.069 282.405 253.6 282.225 288.812L282.247 370.886C280.666 382.511 279.96 383.677 276.444 388.226L167.737 388.218C164.206 381.777 161.955 380.291 160.46 370.704L160.483 289.03C160.242 255.038 160.492 235.191 161.195 199.267C161.275 170.988 161.253 156.785 160.483 149.384C173.787 145.822 192.478 144.034 221.457 143.834C247.984 143.934 266.093 145.544 282.233 149.116Z"
                            fill={getColor('ROOF')}
                            stroke="white"
                            strokeWidth="0.7"
                            onMouseEnter={() => handleMouseEnter('ROOF')}
                            onMouseLeave={handleMouseLeave}
                        />
                    </g>
                </g>

            </g>
            <defs>
                <clipPath id="clip0_15_4484">
                    <rect
                        width="5.11296"
                        height="5.11482"
                        fill="white"
                        transform="translate(409.555 348.248) rotate(-90)"
                    />
                </clipPath>
                <clipPath id="clip1_15_4484">
                    <rect
                        width="5.11296"
                        height="5.11482"
                        fill="white"
                        transform="translate(409.555 85.5439) rotate(-90)"
                    />
                </clipPath>
                <clipPath id="clip2_15_4484">
                    <rect
                        width="5.11296"
                        height="5.11482"
                        fill="white"
                        transform="matrix(-4.37593e-08 -1 -1 4.36635e-08 34.3549 348.248)"
                    />
                </clipPath>
                <clipPath id="clip3_15_4484">
                    <rect
                        width="5.11296"
                        height="5.11482"
                        fill="white"
                        transform="matrix(-4.37752e-08 -1 -1 4.36476e-08 34.3547 85.5439)"
                    />
                </clipPath>
                <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="20" height="20">
                    <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
                          style={{stroke: "#7D7D7D", strokeWidth: "0.2", backgroundColor: 'red'}}/>
                </pattern>
            </defs>
        </>

    )

}

export default VanVector
