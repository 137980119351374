import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash';
import {Modal, Input, Descriptions, Select, Button, Alert, notification, Row, Col, Divider} from 'antd';
import axiosInstance from "../../services/axios";
import _ from 'lodash';
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import TagValues from "../TagValues/TagValues";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import {carPartsNumbers, carPartsNames, carPartsGerman} from "../CarPartRenderer/CarParts";

const {Option} = Select;

const InspectionModal = (props) => {

        const [eventId, setEventId] = useState(null);
        const [eventData, setEventData] = useState(null);
        const [commentValue, setCommentValue] = useState(null);
        const [carPartIds, setCarPartIds] = useState(null);

        useEffect(() => {
            setEventId(null)
            setCommentValue(null)
            setEventData(null)
        }, [props.count]);

        const handleCancel = () => {
            props.hideModal && props.hideModal();
        };

        const handleEventIdChange = (e) => {
            setEventId(e.target.value);
        };

        const debouncedPostRequest = useCallback(
            _.debounce((payload) => {
                axiosInstance.post('/api/anomaly_tool/inspection/details', payload)
                    .then(res => {
                        // console.log(res.data.data)
                        setEventData(res.data.data)
                        if (res.data.data) {
                            setCommentValue(res.data.data.Comment)
                            // setCarPartIds(res.data.data.car_part_ids)
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        notification.error({
                            message: 'Error',
                            description: `${error.message} - ${error.response?.data?.message}`,
                        });
                    });
            }, 1000),
            []
        );

        useEffect(() => {
            const payload = {
                uaid: props.id,
                inspection_id: eventId,
                customer: props.customer
            };
            if (eventId) {
                debouncedPostRequest(payload);
            }
        }, [eventId, debouncedPostRequest]);

        function handleButtonClick() {
            const payload = {
                customer: props.data.customer,
                uaid: props.id,
                event_id: eventId,
                comment: commentValue,
                car_part_id: carPartIds,
            }
            notification['info']({message: 'Match inspection request submitted ...'});
            axiosInstance.post('/api/anomaly_tool/match/inspection', payload)
                .then(res => {
                    notification['success']({message: 'Match inspection request processed successfully'});
                    props.setReload(props.reload + 1)
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error',
                        description: error.message + ' - ' + error.response.data.message
                    });
                })
        }

        const handleCommentChange = (e) => {
            setCommentValue(e.target.value);
        };

        const handleEditSave = (key, editedData) => {
            setCarPartIds(editedData)
        };

        return (
            <>
                <Modal title="Match proactive Anomaly to Inspection"
                       open={props.open}
                       onCancel={handleCancel}
                       width={600}
                       height={"90vh"}
                       footer={null}
                >
                    <Alert
                        // message="This is inte"
                        description="Use this to match a proactive anomaly to an inspection which does not yet have a reactive event created in the webapp"
                        type="warning"
                        showIcon
                    />
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Anomaly'}>
                            <Input
                                value={props.id}
                                disabled={true}
                                // suffix={'test'}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Inspection'}>
                            <Input
                                value={eventId}
                                placeholder="please input Inspection ID"
                                onChange={handleEventIdChange}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    {props.id !== props.data?.event_id &&
                        <Alert
                            message="Anomaly has already been matched to another inspection"
                            description="This action will overwrite the current matching"
                            type="error"
                            showIcon
                        />
                    }
                    {eventData &&
                        <>
                            <div style={{marginTop: 16}}>
                                Details:
                            </div>
                            <Input.TextArea
                                rows={5}
                                value={commentValue}
                                onChange={handleCommentChange}
                            />
                        </>
                    }
                    {/*{eventData &&*/}
                    {/*    <>*/}
                    {/*        <Row>*/}
                    {/*            <Col span={14}>*/}
                    {/*                <CarPartRenderer value={carPartIds}/>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <Row>*/}
                    {/*            <TagRenderer*/}
                    {/*                columnValues={carPartsNumbers}*/}
                    {/*                value={carPartIds} // Pass the current tag as a prop*/}
                    {/*                onSave={handleEditSave}*/}
                    {/*                onSaveActive={true}*/}
                    {/*                field={'situation_tag'}*/}
                    {/*                UAID={eventId}*/}
                    {/*                labelMapper={carPartsGerman}*/}
                    {/*                width={150}*/}
                    {/*            />*/}
                    {/*        </Row>*/}
                    {/*    </>*/}
                    {/*}*/}
                    <Divider></Divider>
                    <Row>
                        <Button style={{marginTop: 16}} disabled={eventData === null}
                                onClick={() => handleButtonClick()}>
                            Submit
                        </Button>
                    </Row>
                </Modal>
            </>
        )
            ;
    }
;

export default InspectionModal;