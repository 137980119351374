export const CP_TO_TYPOLOGY = {
        'front__windscreen': {'break_absence': 0.75, 'crack': 0.25},
        'left_front__window': {'break_absence': 0.90, 'crack': 0.10},
        'left_rear__window': {'break_absence': 0.90, 'crack': 0.10},
        'rear__screen': {'break_absence': 0.95, 'crack': 0.05},
        'left__mirror': {'break_absence': 0.50, 'crack': 0.05, 'detached': 0.10, 'scratch': 0.35},
        'front__bumper': {'break_absence': 0.21, 'crack': 0.02, 'cutting_tear': 0.07, 'deformation': 0.15,
                          'detached': 0.09, 'scratch': 0.46},
        'front_left__bumper': {'break_absence': 0.21, 'crack': 0.02, 'cutting_tear': 0.07, 'deformation': 0.08,
                               'detached': 0.16, 'scratch': 0.46},
        'left_front__door': {'break_absence': 0.02, 'cutting_tear': 0.02, 'deformation': 0.49, 'detached':0.02, 'scratch': 0.45},
        'left_front__sill_panel': {'break_absence':0.02, 'cutting_tear': 0.03, 'deformation': 0.58, 'detached': 0.01, 'scratch': 0.36},
        'left_front__wing': {'break_absence': 0.10, 'cutting_tear': 0.02, 'deformation': 0.46, 'detached': 0.05, 'scratch': 0.37},
        'left_front__pillar': {'break_absence': 0.05,  'deformation': 0.85, 'scratch': 0.10},
        'left_front__rim': {'break_absence': 0.04, 'cutting_tear': 0.02, 'deformation': 0.10, 'scratch': 0.84},
        'left_front__tyre': {'break_absence': 0.04, 'puncture': 0.88, 'cutting_tear': 0.08, 'blowout': 0.08},
        'left_rear__door': {'break_absence': 0.03, 'cutting_tear': 0.02, 'deformation': 0.48, 'detached': 0.02, 'scratch': 0.45},
        'left_rear__sill_panel': {'break_absence': 0.02, 'cutting_tear': 0.03, 'deformation': 0.50, 'detached': 0.01, 'scratch': 0.44},
        'left_rear__wing': {'break_absence': 0.05, 'cutting_tear': 0.01, 'deformation': 0.33, 'detached': 0.02, 'scratch': 0.59},
        'left_rear__pillar': {'break_absence': 0.05,  'deformation': 0.85, 'scratch': 0.10},
        'left_rear__rim': {'break_absence': 0.03, 'cutting_tear': 0.01, 'deformation': 0.06, 'scratch': 0.90},
        'left_rear__tyre': {'break_absence': 0.04, 'puncture': 0.88, 'cutting_tear': 0.08, 'blowout': 0.08},
        'rear__bumper': {'break_absence': 0.08, 'crack': 0.01, 'cutting_tear': 0.06, 'deformation': 0.20,'detached': 0.06, 'scratch': 0.59},
        'rear_left__bumper': {'break_absence': 0.25, 'crack': 0.02, 'cutting_tear': 0.05, 'deformation': 0.12, 'detached': 0.10, 'scratch': 0.46},
        'rear__trunk': {'break_absence': 0.03, 'cutting_tear': 0.03, 'deformation': 0.69, 'detached':0.02, 'scratch': 0.23},
        'top__antenna': {'break_absence': 0.90, 'deformation': 0.10},
        'top__bonnet': {'break_absence': 0.015, 'cutting_tear': 0.015, 'deformation': 0.75, 'detached': 0.05, 'scratch': 0.17},
        'top_front_left__roof': {'break_absence': 0.05,  'deformation': 0.80, 'scratch': 0.15},
        'top_center_left__roof': {'break_absence': 0.05,  'deformation': 0.80, 'scratch': 0.15},
        'top_rear_left__roof': {'break_absence': 0.05,  'deformation': 0.80, 'scratch': 0.15},
        'underbody__front_left': {'break_absence': 0.4, 'cutting_tear': 0.1, 'deformation': 0.1, 'detached': 0.2, 'scratch': 0.2},
        'underbody__left': {'break_absence': 0.4, 'cutting_tear': 0.1, 'deformation': 0.1, 'detached': 0.2, 'scratch': 0.2},
        'underbody__rear_left': {'break_absence': 0.4, 'cutting_tear': 0.1, 'deformation': 0.1, 'detached': 0.2, 'scratch': 0.2},
    }