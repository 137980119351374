import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider, Spin,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Collapse, Tag
} from 'antd';
import axiosInstance from "../../services/axios";

import {FaCarCrash, FaFile, FaInfo, FaSearch, FaStickyNote} from "react-icons/fa";
import {
    CarOutlined,
    SearchOutlined,
    ToolOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined
} from "@ant-design/icons";
import MultiImageRenderer from "./MultiImageRenderer";
import DamageReportTable from "./DamageReportTable";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const VehicleImages = (props) => {

    const [inspectionPredictionData, setInspectionPredictionData] = useState({});
    const [loadingStates, setLoadingStates] = useState({});

    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    const customer = props.customer
    const customerId = props.customerId
    const inspectionData = props.inspectionData

    const handlePanelChange = (key) => {
        const index = parseInt(key) - 1;

        // Avoid fetching if data already exists for the item
        if (customer && !inspectionPredictionData[index] && inspectionData[index]) {
            // Set loading state for the specific item
            setLoadingStates((prev) => ({...prev, [index]: true}));

            axiosInstance.post(
                '/api/image_tool/image_ai_predictions',
                {
                    customer: customer,
                    search_value: inspectionData[index].details,
                    type: inspectionData[index].type,
                    show_all: false
                })
                .then((res) => {
                    setInspectionPredictionData((prev) => ({
                        ...prev,
                        [index]: res.data.damages
                    }));
                    setLoadingStates((prev) => ({...prev, [index]: false}));
                })
                .catch((error) => {
                    console.log(error);
                    setLoadingStates((prev) => ({...prev, [index]: false}));
                    notification['error']({
                        message: 'Error in get AnomalyAIPredictions',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                });
        }
    };

    const formatString = (str) => {
        let maxLength = 30;
        const suffixes = ['_AFTER', '_BEFORE'];
        const matchedSuffix = suffixes.find((suffix) => str.endsWith(suffix));

        if (matchedSuffix) {
            maxLength = 22
            const base = str.slice(0, maxLength);
            return `${base}...${matchedSuffix}`;
        }
        return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
    };

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                minHeight: 802,
                maxHeight: 802,
                overflowY: 'auto' // Enable vertical scrolling
            }}
            styles={{
                body: {
                    padding: '4px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
            title={'Vehicle Images'}
        >
            {inspectionData?.length > 0 && (
                <Timeline
                    style={{marginTop: 20, marginLeft: 20}}
                    items={inspectionData.map((item, i) => ({
                        key: i + 1,
                        dot: item.type === 'event' ? <CarOutlined style={{fontSize: 20, marginTop: 16}}/>
                            : item.type === 'reactive' ? <SearchOutlined style={{fontSize: 20, marginTop: 16}}/>
                                : <ToolOutlined style={{fontSize: 20, marginTop: 16}}/>,
                        children: (
                            <Collapse
                                collapsible={(item.details === props.searchValue) || (item?.locked) ? 'disabled' : null}
                                style={{
                                    marginRight: 8,
                                    backgroundColor: (item.details === props.searchValue) || (item?.locked) ? '#E6F4FF' : null
                                }}
                                onChange={(key) => handlePanelChange(key)}
                                items={[
                                    {
                                        key: i + 1,
                                        label: (
                                            <>
                                                <Row>
                                                    <Tooltip
                                                        title={item.date.replace('T', ' - ').slice(0, 21)}
                                                        key={item.details}>
                                                        <Text style={{fontWeight: 500}}>
                                                            {item.date?.slice(0, 10)}
                                                        </Text>
                                                    </Tooltip>
                                                    <Text style={{fontWeight: 500, marginLeft: 32}}>
                                                        <Link
                                                            href={urlBase + `/image_tool/${encodeURIComponent(item.details)}?customer=${customer}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {formatString(item.details)}
                                                        </Link>
                                                    </Text>
                                                    {
                                                        props.statusData &&
                                                        <>
                                                            {item.new_damages > 0 && !(item.details in props.statusData) &&
                                                                <Tag color="red" style={{marginLeft: 16}}>
                                                                    <ExclamationCircleOutlined/> {item.new_damages}
                                                                </Tag>
                                                            }
                                                            {item.details in props.statusData && (
                                                                props.statusData[item.details].status === 'done' ?
                                                                    <Tag color="green" style={{marginLeft: 16}}>
                                                                        <CheckCircleOutlined/>
                                                                        {props.statusData[item.details].new_damages_labeled > 0 &&
                                                                            ` ${props.statusData[item.details].new_damages_labeled}`}
                                                                    </Tag> :
                                                                    <Tag color="yellow" style={{marginLeft: 16}}>
                                                                        <CheckCircleOutlined/>
                                                                        {props.statusData[item.details].new_damages_labeled > 0 &&
                                                                            ` ${props.statusData[item.details].new_damages_labeled}`}
                                                                    </Tag>
                                                            )}
                                                        </>
                                                    }
                                                </Row>
                                            </>
                                        ),
                                        children: (
                                            <>
                                                <Row style={{marginTop: 8}}>
                                                    <MultiImageRenderer
                                                        data={item.images?.map(image => image.uri_sas)}
                                                        secondaryData={item.images?.map(image => image.ai_image)}
                                                        maxImages={4}
                                                        height={'100%'}
                                                        columnWidth={68}
                                                        maxImageHeight={90}
                                                        minHeight={90}
                                                        tooltip={item.images}
                                                        containerRef={props.containerRef}
                                                    />
                                                </Row>
                                                <Row style={{marginTop: 8}}>
                                                    <Popover
                                                        title={'Comment'}
                                                        trigger="click"
                                                        placement="bottom"
                                                        style={{maxWidth: 300}}
                                                        content={item?.comment}
                                                    >
                                                        <Button
                                                            size={'medium'}
                                                            icon={<FaFile/>}
                                                            disabled={!item?.comment}
                                                        />
                                                    </Popover>
                                                    <Popover
                                                        title={'Note'}
                                                        trigger="click"
                                                        placement="bottom"
                                                        content={
                                                            <div style={{maxWidth: 300, wordWrap: 'break-word'}}>
                                                                {item?.note}
                                                            </div>
                                                        }
                                                    >
                                                        <Button
                                                            size={'medium'}
                                                            icon={<FaStickyNote/>}
                                                            disabled={!item?.note}
                                                        />
                                                    </Popover>
                                                </Row>
                                                <Row style={{marginTop: 8, height: '100%'}}>
                                                    {loadingStates[i] ?
                                                        <Spin/> :
                                                        <DamageReportTable
                                                            selectedColumns={['state', 'carPart_id', 'typology', 'new_damage']}
                                                            data={inspectionPredictionData[i]}
                                                            imageData={item.images?.map(image => image.uri_sas)}
                                                            imageDataAI={item.images?.map(image => image.ai_image)}
                                                            labels={item.images?.map(image => image.id)}
                                                            containerMax={490}
                                                        />
                                                    }
                                                </Row>
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        ),
                    }))}
                />
            )}
        </Card>
    );
};

export default VehicleImages;